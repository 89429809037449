import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const GenerateReportEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.GenerateReport>>(null);
    const [initialValue, setInitialValue] = useState<APIData.GenerateReport>({ ...InitialData.GenerateReport, email_id: [''] });
    const [formDataSet, setFormDataSet] = useState(false);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const exportTypeOptions: FormDataTypes.SelectOption[] = ([{ label: "ETVBRL Report", value: "1" }, { label: "Daily Activity Report", value: "2" }])
    useEffect(() => {
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'showroom_name', 'data', 'list');
        setFormDataSet(true)
    }, [])

    const yupSchema = Yup.object().shape({
        from_date: Yup.string().required(ProjectStrings.ValidationRequired),
        to_date: Yup.string().required(ProjectStrings.ValidationRequired),
        // month: Yup.string().required(ProjectStrings.ValidationRequired),
        export_type: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        showroom_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        email_id: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    addProcessingRequests();
                    ServerAPI.generateReport(submitValues).then(response => {
                        if (response && response['message']) {
                            toast.success(response['message']);
                            reloadData();
                            closeDialog();
                        } else if (response && response['message']) {
                            toast.error(response['message']);
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        reduceProcessingRequests();
                    });
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails" style={{ overflowY: "auto", height: "89vh" }}>
                                <h4 className="title-text text-center">Generate Report</h4>
                                <div className="custom-border-grey my-3"></div>
                                <Form>
                                    <div className="form-field-container">
                                        <>
                                            <div className="row px-2 py-2">
                                                <div className="col-lg-12 col-md-12 col-xl-12  text-field-empty-custom">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Export Type", name: "export_type", placeholder: "Select Export Type", required: true, options: exportTypeOptions }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-2">
                                                <div className="col-lg-12 col-md-12 col-xl-12  text-field-empty-custom">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "From Date", name: "from_date", placeholder: "Select From Date", required: true, allowFutureDate: true }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-2">
                                                <div className="col-lg-12 col-md-12 col-xl-12  text-field-empty-custom">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "To Date", name: "to_date", placeholder: "Select To Date", required: true, allowFutureDate: true }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-2">
                                                <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Showroom Name", name: "showroom_uuid", placeholder: "Select Showroom Name", options: showroomOptions, required: true }} />
                                                </div>
                                            </div>
                                        </>
                                        <FieldArray name="email_id">
                                            {({ insert, remove, push }) => (
                                                <>
                                                    <button type="button" className="mb-3 mr-2 btn" style={{ backgroundColor: "#14408b", color: "#FFF", height: "35px" }} onClick={() => push('')}>Add Email Id</button>
                                                    <div className='individual_sub_content' style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', height: 'auto' }}>

                                                        <>
                                                            {formikProps.values.email_id.map((data, index) => {
                                                                const uniqueKey = `${index}`;
                                                                return (

                                                                    <div style={{ width: "100%", marginBottom: "10px", position: "relative" }}>
                                                                        <div key={uniqueKey} className="overall mb-4 d-flex  align-items-center" >
                                                                            <div className="text-field-empty-custom-user-1 w-100">
                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email id", name: `email_id.${index}`, placeholder: "Enter Email Id", required: true }} />
                                                                                <button type="button" className={`btn btn-sm`} disabled={formikProps.values.email_id.length === 1} style={{ backgroundColor: "#f14b4b", height: "45px", position: "absolute", right: "1px", top: "3px" }} onClick={() => remove(index)}><i className="text-white fas fa-trash pr-1 pl-1"></i></button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    </div>
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                            formikProps.submitForm();
                                            if (!formikProps.isValid) {
                                                toast.error("Please ensure all fields are Filled and Valid!");
                                            }
                                        }}>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik >
            )}
        </Drawer >
    )
}
export default GenerateReportEntryDialog


import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, Endpoints, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const PlanningNextTaskDialog: React.FC<{ showDialog: boolean, closeDialog: any, updateLead: any, leadQualify: boolean, resetState: () => void, updateSubmitValues: APIData.UpdateLeadFollowUp | undefined, resetUpdateSubmitvalues: () => void, updateCloseDialog: any, locationUUID: string, id?: string, dialogHeading?: string, pageTitle: string, leadTaskUUID?: string }> = ({ showDialog, closeDialog, updateSubmitValues, updateCloseDialog, pageTitle, locationUUID, resetState, leadQualify, id, dialogHeading, leadTaskUUID, resetUpdateSubmitvalues, updateLead }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    console.log(pageTitle, "plan heading")
    const formikRef = useRef<FormikProps<APIData.LeadPlanNextTask>>(null);
    const [initialValue, setInitialValue] = useState<APIData.LeadPlanNextTask>(InitialData.LeadPlanNextTask);
    const [assignedToOptions, setAssignedToOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [demoCarOptions, setDemoCarOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomId, setShowroomId] = useState("");
    const [remarks, setRemarks] = useState('');
    const [formDataSet, setFormDataSet] = useState(false);
    const planTaskRef = useRef<HTMLDivElement | null>(null);

    const handleRemarksChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTransmissionType(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["showroom_uuid"] = showroomId;
        addProcessingRequests();
        ServerAPI.executeAPI(Endpoints.UserDemoCarList, ServerAPI.APIMethod.GET, true, null, params).then(response => {
            if (response && response.data && response.data.demo_car) {
                const demoCarList = response.data.demo_car.map((car: any) => ({
                    label: car.model_name,
                    value: car.uuid,
                }));
                setDemoCarOptions(demoCarList);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [showroomId]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        if (showroomId) params["showroom_uuid"] = showroomId;
        if (leadQualify) params["qualify_status"] = leadQualify ? 1 : 0
        if (leadQualify && showroomId) {
            getDataFromAPI(API.EndPoint.LeadAssignToList, setAssignedToOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name', 'data');
        }
    }, [showroomId, leadQualify]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.executeAPI(Endpoints.ShowroomList, ServerAPI.APIMethod.GET, true, null).then(response => {
            if (response && response.data && response.data.list) {
                const showroomList = response.data.list.map((showroom: any) => ({
                    label: showroom.showroom_name,
                    value: showroom.uuid,
                }));

                setShowroomOptions(showroomList);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, []);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (planTaskRef.current && !planTaskRef.current.contains(event.target as Node)) {
                setRemarks("");
                // closeDialog();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (showroomOptions) {
            if (showroomOptions.length === 1) {
                formikRef.current?.setFieldValue("showroom_uuid", showroomOptions[0].value);
            }
        }
    }, [showroomOptions])

    const yupSchema = Yup.object().shape({
        // lead_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        // lead_task_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        date_time: Yup.string().required(ProjectStrings.ValidationRequired),
        // testdrive_location_type_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        // lead_followup_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        // lead_status_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        // remarks: Yup.string().required(ProjectStrings.ValidationRequired),
        // lead_qualify: Yup.number().required(ProjectStrings.ValidationRequired),
        assign_to_user_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),

    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min" style={{ marginBottom: "50px" }} >
            {formDataSet && (
                <Formik enableReinitialize={true} validateOnChange={false} initialValues={initialValue} validationSchema={yupSchema} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    submitValues['remarks'] = remarks;

                    if (!submitValues['remarks']) {
                        toast.error("Remarks are required");
                        setSubmitting(false);
                        return;
                    }

                    if (leadQualify === true && (dialogHeading === "Test Drive Home" || dialogHeading === "Test Drive Showroom")) {
                        if (!submitValues['showroom_uuid']) {
                            toast.error("Showroom Id Required")
                            setSubmitting(false);
                            return
                        }
                    }
                    if (dialogHeading === "Test Drive Home" || dialogHeading === "Test Drive Showroom") {
                        if (!submitValues['demo_car_uuid']) {
                            toast.error("Demo car Id Required")
                            setSubmitting(false);
                            return
                        }
                    }
                    if (pageTitle === "Updating Follow-up Call") {
                        addProcessingRequests();
                        ServerAPI.LeadUpdateFollowUp(updateSubmitValues)
                            .then(response => {
                                if (response && response['success'] && response['message']) {
                                    toast.success(response['message']);
                                    ServerAPI.LeadPlanTaskV2({ ...submitValues, lead_status_uuid: updateSubmitValues?.lead_status_uuid, lead_followup_uuid: response.data?.lead_followup_uuid, lead_uuid: response.data?.lead_uuid, lead_task_uuid: leadTaskUUID, lead_qualify: leadQualify === true ? 1 : 0, testdrive_location_type_uuid: locationUUID })
                                        .then(response => {
                                            if (response && response['success'] && response['message']) {
                                                toast.success(response['message']);
                                                resetState();
                                                updateLead()
                                                updateCloseDialog();
                                                resetUpdateSubmitvalues();
                                                closeDialog();
                                            } else if (response && response['message']) {
                                                toast.error(response['message']);
                                            }
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            reduceProcessingRequests();
                                        });
                                } else if (response && response['message']) {
                                    toast.error(response['message']);
                                } else {
                                    toast.error('Lead Update API Should Call First');
                                }
                            })
                            .finally(() => {
                                reduceProcessingRequests();
                            });
                    } else if (pageTitle === "Updating a Visit") {
                        addProcessingRequests();
                        ServerAPI.LeadUpdateVisit(updateSubmitValues)
                            .then(response => {
                                if (response && response['success'] && response['message']) {
                                    toast.success(response['message']);
                                    ServerAPI.LeadPlanTaskV2({ ...submitValues, lead_status_uuid: updateSubmitValues?.lead_status_uuid, lead_followup_uuid: response.data?.lead_followup_uuid, lead_uuid: response.data?.lead_uuid, lead_task_uuid: leadTaskUUID, lead_qualify: leadQualify === true ? 1 : 0, testdrive_location_type_uuid: locationUUID })
                                        .then(response => {
                                            if (response && response['success'] && response['message']) {
                                                toast.success(response['message']);
                                                resetState();
                                                updateLead()
                                                updateCloseDialog();
                                                resetUpdateSubmitvalues();
                                                closeDialog();
                                            } else if (response && response['message']) {
                                                toast.error(response['message']);
                                            }
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            reduceProcessingRequests();
                                        });
                                } else if (response && response['message']) {
                                    toast.error(response['message']);
                                } else {
                                    toast.error('Lead Update API Should Call First');
                                }
                            })
                            .finally(() => {
                                reduceProcessingRequests();
                            });
                    }
                }}>
                    {(formikProps) => {
                        if (formikProps.values.showroom_uuid !== showroomId) {
                            setShowroomId(formikProps.values.showroom_uuid);
                        }
                        return (
                            <div className="tabdetails plan-task" ref={planTaskRef}>
                                <h4 className="title-text text-center">Planning a {dialogHeading}</h4>
                                <div className="custom-border-grey my-2"></div>
                                <Form>
                                    <div className="form-field-container" style={{ paddingTop: "15px" }}>
                                        {showroomOptions && showroomOptions.length > 1 && <div className="update-popup-container ">
                                            <div className="reschedule-container">
                                                <div className="update-popup-yes-or-no">
                                                    <div className="text-field-empty-custom custom-label">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Showroom Id", name: "showroom_uuid", placeholder: " Select Showroom Id", options: showroomOptions }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {(dialogHeading === "Test Drive Home" || dialogHeading === "Test Drive Showroom") && <>
                                            <div className="update-popup-container ">
                                                <div className="reschedule-container">
                                                    <div className="update-popup-yes-or-no">
                                                        <div className="text-field-empty-custom custom-label">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Demo Car", name: "demo_car_uuid", required: true, placeholder: " Select Demo Car", options: demoCarOptions }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <div className="update-popup-container ">
                                            <div className="reschedule-container">
                                                <div className="update-popup-yes-or-no">
                                                    <div className="text-field-empty-custom custom-label">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Assign to", name: "assign_to_user_uuid", required: true, placeholder: " Select Employee", options: assignedToOptions }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="update-popup-container ">
                                            <div className="reschedule-container">
                                                <div className="update-popup-yes-or-no">
                                                    <div className="text-field-empty-custom-user custom-label">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Choose Date and Time ", name: "date_time", placeholder: "Choose Date & Time", required: true, }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="update-popup-container ">
                                            <div className="update-popup-yes-or-no">
                                                <div><label className="update-form-label"> Remarks <span className="text-danger me-4">*</span> </label></div>
                                            </div>
                                            <div className="update-popup-yes-or-no">
                                                <div><label className="update-form-label" ></label></div>
                                                <textarea name="" id="update-remark" placeholder="Type your remarks here" value={remarks} onChange={handleRemarksChange}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row update-followup">
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" onClick={() => {
                                            if (formikProps.isValid) {
                                                formikProps.submitForm();
                                                console.log("clicked")
                                            }

                                        }}>Plan Task</button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik >
            )
            }
        </Drawer >
    )
}

export default PlanningNextTaskDialog;
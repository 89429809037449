import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { frameSelectOptions, getDataFromAPI } from "../common/Utilities";

const LeadsourceMappingEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.LeadsourceMapping>>(null);
    const [initialValue, setInitialValue] = useState<APIData.LeadsourceMapping>(InitialData.LeadsourceMapping);
    const [formDataSet, setFormDataSet] = useState(false);
    const [leadSourceOptions, setLeadSourceOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadCategoryOptions, setLeadCategoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadCategoryUUID, setLeadCategoryUUID] = useState("")
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getLeadsourceMappingDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["lead_source_cat_uuid"] = leadCategoryUUID;
        addProcessingRequests();
        ServerAPI.executeAPI(API.EndPoint.LeadSourceList, ServerAPI.APIMethod.GET, true, null, params).then(response => {
            if (response && response.data && response.data.list) {
                const leadSource = response.data.list.map((src: any) => ({
                    label: src.lead_source_name,
                    value: src.uuid
                }))
                setLeadSourceOptions(leadSource);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [leadCategoryUUID]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.LeadSourceCategoryList, setLeadCategoryOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'lead_source_category_name', 'data');
    }, []);


    const yupSchema = Yup.object().shape({
        lead_source_category_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        lead_source_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        lead_source_name: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    delete submitValues.state_name;
                    delete submitValues.city_name;
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.leadsourceMappingUpdate(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.leadsourceMappingAdd(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.lead_source_category_uuid !== leadCategoryUUID) {
                            setLeadCategoryUUID(formikProps.values.lead_source_category_uuid);
                        }
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Lead Source Mapping</h4>
                                <div className="custom-border-grey my-2"></div>
                                <Form>
                                    <div className="px-2 form-field-container">
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                {/* <label className="label-custom-user"><p>State</p></label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Lead Source Category", name: "lead_source_category_uuid", placeholder: "Select Lead Source Category", options: leadCategoryOptions, required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                {/* <label className="label-custom-user"><p>State</p></label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Lead Source", name: "lead_source_uuid", placeholder: "Select Lead Source", options: leadSourceOptions, required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lead Source Name", name: "lead_source_name", placeholder: "Enter Lead Source Name", required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status" }} />
                                        </div>
                                    </div>
                                    <div className="row btn-form-submit">
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                            formikProps.submitForm();
                                            if (!formikProps.isValid) {
                                                toast.error("Please ensure all fields are Filled and Valid!");
                                            }
                                        }}>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Drawer>
    )
}

export default LeadsourceMappingEntryDialog;
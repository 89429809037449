import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { ProjectStrings } from "../common/Constants";
import { getFormattedLocalDateTime } from "../common/Utilities";

const UserOEMCredentialsEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.OEMAdd>>(null);
    const [initialValue, setInitialValue] = useState<APIData.OEMAdd>(InitialData.OEMAdd);
    const [dateTime, setDateTime] = useState("");
    const [ExpiryStatus, setExpiryStatus] = useState<number>();
    const [formDataSet, setFormDataSet] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getOEMDetails(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setDateTime(response.data.details.updated_date);
                    setExpiryStatus(response.data.details.expire_status);
                    setFormDataSet(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        username: Yup.string().required(ProjectStrings.ValidationRequired),
        password: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };

                    addProcessingRequests();
                    ServerAPI.userOEMAdd(submitValues).then(response => {
                        if (response && response['success'] && response['message']) {
                            toast.success(response['message']);
                            reloadData();
                            closeDialog();
                        } else if (response && response['message']) {
                            toast.error(response['message']);
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        reduceProcessingRequests();
                    });

                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">Login Credentials</h4>
                                <div className="custom-border-grey my-2"></div>
                                <Form>
                                    <div className="px-2 form-field-container">
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Username", name: "username", placeholder: "EnterUsername", required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Password", name: "password", placeholder: "Enter Password", required: true }} />
                                            </div>
                                            <div className="d-flex justify-content-end w-100 px-3"><p className="text-danger f-14" >{`Last Updated : ${getFormattedLocalDateTime(dateTime)}`}</p></div>
                                        </div>
                                        {ExpiryStatus === 1 && <div className='custom-tablescroll-1'>
                                            <div className="table-container">
                                                <div className="table-scroll">
                                                    <div className="table-responsive">
                                                        <div className="cancel-notice-container">
                                                            <div className="cancel-notice">
                                                                <p className="cancel-notice-message m-0">Password Expired. Please update your password.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="row  btn-form-submit">
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                            formikProps.submitForm();
                                            if (!formikProps.isValid) {
                                                toast.error("Please ensure all fields are Filled and Valid!");
                                            }
                                        }}>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Drawer>
    )
}

export default UserOEMCredentialsEntryDialog;
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { APIData, UIDataTypes } from '../../common/DataTypes';
import { ServerAPI } from '../../common/ServerAPI';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLinks } from '../../common/Constants';
import { Tab, Tabs } from "@mui/material";

const TabsUserTestDrive: UIDataTypes.TabItem[] = [
    {
        tabLabel: "Test Drive",
        tabLink: PageLinks.USER_TESTDRIVE_DASHBOARD,
    },
    {
        tabLabel: "Showroom Transfer",
        tabLink: PageLinks.USER_TESTDRIVE_DASHBOARD,
    },
    {
        tabLabel: "Fuel Refill",
        tabLink: PageLinks.USER_TESTDRIVE_DASHBOARD,
    },
    {
        tabLabel: "Others",
        tabLink: PageLinks.USER_TESTDRIVE_DASHBOARD,
    },
]

const UserTestdriveDashboard: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userDashboardList, setUserDashboardList] = useState<APIData.TestDriveDashboardData>();
    const [selectedTab, setSelectedTab] = useState(0);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const fetchTestDriveDashboard = () => {
        addProcessingRequests();
        ServerAPI.getTestdriveDashboard()
            .then((response: any) => {
                if (response['success'] === true) {
                    setUserDashboardList(response.data);
                } else {
                    toast.error('Something Went Wrong');
                }
            })
            .finally(() => {
                reduceProcessingRequests();
            });
    };

    useEffect(() => {
        setSelectedTab(0);
    }, [])

    const handelTabs = (e: any, val: React.SetStateAction<number>) => {
        setSelectedTab(val)
    }

    useEffect(() => {
        fetchTestDriveDashboard();
    }, []);

    const handleCountClick = () => {
        switch (selectedTab) {
            case 0:
                return navigate(PageLinks.USER_TESTDRIVE);
            case 1:
                return navigate(PageLinks.USER_TESTDRIVE_TRANSFER);
            case 2:
                return navigate(PageLinks.USER_TESTDRIVE_REFILL);
            case 3:
                return navigate(PageLinks.USER_TESTDRIVE_OTHERS);
            default:
                return "";
        }
    };

    return (
        <>
            <Tabs value={selectedTab}
                onChange={handelTabs}
                sx={{ backgroundColor: "white", "& button": { width: "25%" }, marginTop: "10px" }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#052160"
                    }
                }}
            >
                {TabsUserTestDrive.map((tab) => {
                    return <Tab label={tab.tabLabel} className="tab-headers" onClick={() => navigate(tab.tabLink)} />
                })}
            </Tabs>

            {userDashboardList &&
                <div className="shifts-report-container mt-4" onClick={handleCountClick} style={{ cursor: "pointer" }}>
                    <div className="shifts-report">
                        <div className="shifts-report-heading">Today's Pending</div>
                        <div className="shifts-report-count">{selectedTab === 0 ? userDashboardList?.test_drive.today_pending : selectedTab === 1 ? userDashboardList?.transfer.today_pending : selectedTab === 2 ? userDashboardList?.fuel_refill.today_pending : selectedTab === 3 ? userDashboardList?.others.today_pending : ""}</div>
                    </div>
                    <div className="shifts-report">
                        <div className="shifts-report-heading">Todays's Completed</div>
                        <div className="shifts-report-count">{selectedTab === 0 ? userDashboardList?.test_drive.today_completed : selectedTab === 1 ? userDashboardList?.transfer.today_completed : selectedTab === 2 ? userDashboardList?.fuel_refill.today_completed : selectedTab === 3 ? userDashboardList?.others.today_completed : ""}</div>
                    </div>
                    <div className="shifts-report">
                        <div className="shifts-report-heading">Overall Pending</div>
                        <div className="shifts-report-count">{selectedTab === 0 ? userDashboardList?.test_drive.overall_pending : selectedTab === 1 ? userDashboardList?.transfer.overall_pending : selectedTab === 2 ? userDashboardList?.fuel_refill.overall_pending : selectedTab === 3 ? userDashboardList?.others.overall_pending : ""}</div>
                    </div>
                    <div className="shifts-report">
                        <div className="shifts-report-heading">Overall Completed</div>
                        <div className="shifts-report-count">{selectedTab === 0 ? userDashboardList?.test_drive.overall_completed : selectedTab === 1 ? userDashboardList?.transfer.overall_completed : selectedTab === 2 ? userDashboardList?.fuel_refill.overall_completed : selectedTab === 3 ? userDashboardList?.others.overall_completed : ""}</div>
                    </div>
                </div>
            }
        </>
    );
};

export default UserTestdriveDashboard;
import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { ProjectStrings } from "../common/Constants";

const UpdateDeliveryScheduleEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, invoiceUUID: string, deliveryDate?: string }> = ({ showDialog, closeDialog, reloadData, id, invoiceUUID }) => {
    console.log(":delivery v2")
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.UpdateDeliverySchedule>>(null);
    const [initialValue, setInitialValue] = useState<APIData.UpdateDeliverySchedule>(InitialData.UpdateDeliverySchedule);
    const updateDateRef = useRef<HTMLDivElement | null>(null);
    const chooseOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];

    const yupSchema = Yup.object().shape({
        actual_delivery_date_time: Yup.string().required(ProjectStrings.ValidationRequired),
        did_you_actual_deliver_vehicle: Yup.number().required(ProjectStrings.ValidationRequired),
    });

    // useEffect(() => {
    //     if (deliveryDate) {
    //         setInitialValue({
    //             actual_delivery_date_time: deliveryDate,
    //             invoice_uuid: invoiceUUID,
    //             lead_uuid: id ? id : ""
    //         })
    //     }
    // }, [deliveryDate]);

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                let submitValues: any = { ...values };
                if (!!invoiceUUID && !!id) {
                    addProcessingRequests();
                    submitValues['invoice_uuid'] = invoiceUUID;
                    submitValues['lead_uuid'] = id;
                    console.log(":delivery v2")
                    ServerAPI.updateDeliverySchedule(submitValues).then(response => {
                        if (response && response['success'] && response['message']) {
                            toast.success(response['message']);
                            reloadData();
                            closeDialog();
                        } else if (response && response['message']) {
                            toast.error(response['message']);
                        } else {
                            toast.error('Unknown Error Occurred');
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        reduceProcessingRequests();
                    });
                } else {
                    toast.error('Please provide Invoice UUID and Lead UUID!');
                    setSubmitting(false);
                }
            }} >
                {(formikProps) => {
                    return (
                        <div className="tabdetails" ref={updateDateRef}>
                            <h4 className="title-text text-center">Update Actually Delivery Date</h4>
                            <div className="custom-border-grey my-2"></div>
                            <Form>
                                <div className="px-2 form-field-container">
                                    <div className="row px-2 py-3">
                                        <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did you delivered the vehicle", name: "did_you_actual_deliver_vehicle", placeholder: "Did you delivered the vehicle", options: chooseOptions, required: true }} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Delivery Date", name: "actual_delivery_date_time", placeholder: "Delivery Date", required: true }} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row btn-form-submit">
                                    <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                    <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                        formikProps.submitForm();
                                        if (!formikProps.isValid) {
                                            toast.error("Please ensure all fields are Filled and Valid!");
                                        }
                                    }}>Submit</button>
                                </div> */}
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </Drawer>
    )
}

export default UpdateDeliveryScheduleEntryDialog;
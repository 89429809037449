import React, { useEffect, useRef, useState } from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageMappings } from "../common/PageMappings";
import { ServerAPI } from "../common/ServerAPI";
import { TableViewMappings } from "../common/TableViewMappings";
import TableMain from "../components/TableMain";
import { ExportExcel, getLoginUserType } from "../common/Utilities";
import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TuneIcon from '@mui/icons-material/Tune';
import { DateFilterType, EndUser, InventorySortValues, PageLinks, ProjectConfig, ProjectStrings, StatusValues } from "../common/Constants";
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { DatePicker } from "antd";
import { RiCalendarLine } from "react-icons/ri";
import Select from 'react-select';
import { toast } from "react-hot-toast";
import { APIData, FormDataTypes } from "../common/DataTypes";
import UserTesDriveFilterModal from "../components/ui_components/UserTestdriveFilterModel";
import UserLeadsSearchModal from "../components/ui_components/UserLeadsSearchModal";
import UserInventoryFilter from "../components/ui_components/UserInventoryFilter";
// import UserInventoryFilterModal from "../components/ui_components/UserInventoryFilterModal.bck";


const { RangePicker } = DatePicker;

const CommonListing: React.FC<{ page: string }> = ({ page }) => {

    const navigate = useNavigate();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [pageMappingDetail, setPageMappingDetail] = useState<PageMappings.PageDetail>();
    const [tableRows, setTableRows] = useState<any[]>();
    const [tableDetail, setTableDetail] = useState<TableViewMappings.TableDetail>();
    const [totalPages, setTotalPages] = useState(0);
    const [totalCounts, setTotalCounts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [selectedAdditionalFilter, setSelectedAdditionalFilter] = useState('');
    const [dateRangeSet, setDateRangeSet] = useState(false);
    const [dateRangeFrom, setDateRangeFrom] = useState('');
    const [dateRangeTo, setDateRangeTo] = useState('');
    const [dayJSRange, setDayJSRange] = useState<RangeValue<Dayjs>>();
    const [dateMonthSet, setDateMonthSet] = useState(false);
    const [dateMonth, setDateMonth] = useState('');
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [showFormDialog, setShowFormDialog] = useState(false);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [dataID, setDataID] = useState('');
    const [copyID, setCopyID] = useState('');
    const [status, setStatus] = useState('1');
    const [sort, setSort] = useState('');
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [pageInitialized, setPageInitialized] = useState(false);
    const [showAdditionalFormDialogs, setShowAdditionalFormDialogs] = useState<number[]>([]);
    const [filterParams, setFilterParams] = useState<APIData.FilterTestdrive | undefined>();
    const [inventoryFilterParams, setInventoryFilterParams] = useState<APIData.FilterInventory | undefined>();
    const [searchParams, setSearchParams] = useState<APIData.SearchLeads | undefined>();
    const [filterApplied, setFilterApplied] = useState(false);
    const [inventoryFilterApplied, setInventoryFilterApplied] = useState(false);
    const [searchApplied, setSearchApplied] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [showInventoryFilterPopup, setShowInventoryFilterPopup] = useState(false);
    const [showSearchPopup, setShowSearchPopup] = useState(false);
    const customTableViewRef = useRef<HTMLDivElement | null>(null);
    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) };
    const statusOptions: FormDataTypes.SelectOption[] = [{ value: StatusValues.ACTIVE + "", label: "Active" }, { value: StatusValues.INACTIVE + "", label: "In Active" }, { value: StatusValues.DELETED + "", label: "Deleted" }];
    const sortOptions: FormDataTypes.SelectOption[] = [{ value: InventorySortValues.AGE + "", label: "Age" }, { value: InventorySortValues.YEAR + "", label: "Year" }, { value: InventorySortValues.MODEL + "", label: "Model" }, { value: InventorySortValues.WHSCODE + "", label: "WHS Code" }];

    const handleStatusChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setStatus(selectedOption?.value || "");
    };

    const handleSortChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setSort(selectedOption?.value || "");
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
                setShowInventoryFilterPopup(false);
                setShowFilterPopup(false);
                setShowSearchPopup(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const clearFilter = () => {
        setFilterParams(undefined);
        setFilterApplied(false);
    };


    const clearInventoryFilter = () => {
        setInventoryFilterParams(undefined);
        setInventoryFilterApplied(false);
    };

    const clearSearch = () => {
        setSearchParams(undefined);
        setSearchApplied(false);
    };

    const SearchLeads = (filters: APIData.SearchLeads) => {
        setSearchParams(filters);
        setSearchApplied(true);
    };

    const filterLeads = (filters: APIData.FilterTestdrive) => {
        setFilterParams(filters);
        setFilterApplied(true);
    };


    const frameAPIParams = (pagination?: boolean) => {
        let params: { [k: string]: any } = {};
        if (pageMappingDetail) {
            if (!pageMappingDetail.noPagination) {
                params['pagenumber'] = pagination ? currentPage : 1;
                params['limit'] = pagination ? ProjectConfig.EntriesPerPage : 100000;
            }
            if (getLoginUserType() == EndUser.Dealer && status !== "") params['status'] = status ? status : "";

            if (sort !== "") {
                params["sort"] = sort;
            }
            //params['status'] = '1';
            if (dateRangeSet) {
                params['from_date'] = dateRangeFrom;
                params['to_date'] = dateRangeTo;
            }
            if (dateMonthSet) {
                params['month'] = dateMonth;
            }
            if (searchString) {
                params["search_text"] = searchString;
            }
            if (searchParams) {
                params["lead_id"] = searchParams?.leadID;
                params["mobile_number"] = searchParams?.mobileNumber;
                params["customer_name"] = searchParams?.customerName;
            }
            if (filterParams) {
                params["status"] = filterParams?.status;
                params["testdrive_type"] = filterParams?.testdrive_type;
                params["testdrive_location_type"] = filterParams?.testdrive_location_type;
            }
            if (inventoryFilterParams) {
                if (inventoryFilterParams?.models_uuid) params["models_uuid"] = inventoryFilterParams?.models_uuid;
                if (inventoryFilterParams?.variant_uuid) params["variant_uuid"] = inventoryFilterParams?.variant_uuid;
                if (inventoryFilterParams?.vehicle_status_uuid) params["vehicle_status_uuid"] = inventoryFilterParams?.vehicle_status_uuid;
                if (inventoryFilterParams?.WHS_code) params["WHS_code"] = inventoryFilterParams?.WHS_code;
                if (inventoryFilterParams?.model_year) params["model_year"] = inventoryFilterParams?.model_year;
                if (inventoryFilterParams?.fuel_type_uuid) params["mf_year"] = inventoryFilterParams?.mf_year;
                if (inventoryFilterParams?.fuel_type_uuid) params["fuel_type_uuid"] = inventoryFilterParams?.fuel_type_uuid;
                if (inventoryFilterParams?.interior_uuid) params["interior_uuid"] = inventoryFilterParams?.interior_uuid;
                if (inventoryFilterParams?.exterior_uuid) params["exterior_uuid"] = inventoryFilterParams?.exterior_uuid;
                if (inventoryFilterParams?.state_uuid) params["state_uuid"] = inventoryFilterParams?.state_uuid;
                if (inventoryFilterParams?.showroom_uuid) params["showroom_uuid"] = inventoryFilterParams?.showroom_uuid;
            }
            if (pageMappingDetail.listAddlParams !== undefined) {
                pageMappingDetail.listAddlParams.forEach(addlParam => params[addlParam.param_name] = addlParam.param_value)
            }
        }
        return params;
    }

    var searchTimeout: any = null;

    // const fetchTestDriveDashboard = () => {
    //     addProcessingRequests();
    //     ServerAPI.getTestdriveDashboard()
    //         .then((response: any) => {
    //             if (response['success'] === true) {
    //                 setUserDashboardList(response.data);
    //             } else {
    //                 toast.error('Something Went Wrong');
    //             }
    //         })
    //         .finally(() => {
    //             reduceProcessingRequests();
    //         });
    // };

    const loadTableData = () => {
        if (pageMappingDetail) {
            setPageInitialized(true);
            const params = frameAPIParams(true);
            if (pageMappingDetail.additionalFilter !== undefined && selectedAdditionalFilter) {
                params[pageMappingDetail.additionalFilter.paramName] = selectedAdditionalFilter;
            }
            addProcessingRequests();
            ServerAPI.executeAPI(pageMappingDetail.listEndpoint, ServerAPI.APIMethod.GET, true, null, params).then(response => {
                if (response !== undefined) {
                    if (pageMappingDetail.listResponseParam !== undefined && response['data'] !== undefined && response['data'][pageMappingDetail.listResponseParam] !== undefined) {
                        setTableRows(response['data'][pageMappingDetail.listResponseParam]);
                    } else if (response['data'] !== undefined) {
                        setTableRows(response['data']);
                    } else if (response['status'] === false && response['message'] !== undefined) {
                        setTableRows([]);
                        toast.error(response['message']);
                    } else {
                        setTableRows([]);
                        toast.error("Something Went Wrong");
                    }
                    if (response['totalpage'] !== undefined) {
                        setTotalPages(response['totalpage']);
                    }
                    if (response['totalcount'] !== undefined) {
                        setTotalCounts(response['totalcount']);
                    }
                } else {
                    setTableRows([]);
                    toast.error("Something Went Wrong");
                }
            }).finally(() => {
                reduceProcessingRequests();
                setReloadData(false);
            });
        } else {
            setTableRows([]);
        }
    }

    const deleteEntry = (params: { [k: string]: any }) => {
        if (pageMappingDetail && pageMappingDetail.deleteEndpoint) {
            //let params: { [k: string]: any } = {};
            addProcessingRequests();
            ServerAPI.executeAPI(pageMappingDetail.deleteEndpoint, ServerAPI.APIMethod.POST, true, null, params).then(response => {
                if (response !== undefined && response.success) {
                    toast.success(response.message);
                    setReloadData(true);
                } else {
                    alert("Something Went Wrong");
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    useEffect(() => {
        const pageDetail = PageMappings.getPageDetail(page);
        setReloadData(false);
        setShowInventoryFilterPopup(false);
        if (pageDetail) {
            setPageInitialized(false);
            setTableDetail(TableViewMappings.getPageTableDetail(pageDetail.pageLink));
            setPageMappingDetail(pageDetail);
            setSearchString('');
            setStatus("1");
            setSort("");
            setCurrentPage(1);

            if (pageDetail.tabs && pageDetail.tabs.length > 0) {
                const tabLinks = pageDetail.tabs.map(tab => tab.tabLink);
                if (!tabLinks.includes(page)) {
                    setSelectedTab(0);
                }
            } else {
                setSelectedTab(0);
            }

            // if (pageDetail != undefined && pageDetail.dateFilterRequired) {
            //     if (!dateRangeFrom || !dateRangeTo) {
            //         setDateRangeFrom(moment().startOf('month').format('YYYY-MM-DD'));
            //         setDateRangeTo(moment().format('YYYY-MM-DD'));
            //         setDateRangeSet(true);
            //     }
            // } else {
            //     setDateRangeFrom('');
            //     setDateRangeTo('');
            //     setDateRangeSet(false);
            // }
            // if (page === PageLinks.USER_TESTDRIVE) {
            //     fetchTestDriveDashboard();
            // }
            setTableRows([]);
            setTotalPages(1);
            setTimeout(() => setReloadData(true), 100);
            console.log("page", page)
        }
    }, [page]);

    useEffect(() => {
        if (searchTimeout) clearTimeout(searchTimeout);
        if (pageInitialized) {
            searchTimeout = setTimeout(() => {
                setCurrentPage(1);
                setReloadData(true);
            }, 500);
        }
    }, [searchString]);

    useEffect(() => {
        if (pageMappingDetail !== undefined && pageMappingDetail?.additionalFilter !== undefined) {
            setCurrentPage(1);
            setReloadData(true);
        }
    }, [selectedAdditionalFilter]);

    useEffect(() => {
        if (reloadData) {
            loadTableData();
        }
        const loadTimer = setTimeout(() => setReloadData(false), 100);
        return () => clearTimeout(loadTimer);
    }, [reloadData]);

    useEffect(() => {
        setReloadData(true);
    }, [searchParams, filterParams, inventoryFilterParams, status, sort]);

    useEffect(() => {
        setReloadData(true);
    }, [currentPage]);

    useEffect(() => {
        if (dateRangeFrom && dateRangeTo) {
            setDayJSRange([dayjs(dateRangeFrom), dayjs(dateRangeTo)])
        }
    }, [dateRangeFrom, dateRangeTo]);

    useEffect(() => {
        if (page === PageLinks.USER_TESTDRIVE) {
            setSelectedTab(0)
        } else if (page === PageLinks.USER_TESTDRIVE_TRANSFER) {
            setSelectedTab(1)
        } else if (page === PageLinks.USER_TESTDRIVE_REFILL) {
            setSelectedTab(2)
        } else if (page === PageLinks.USER_TESTDRIVE_OTHERS) {
            setSelectedTab(3)
        }
    }, [page]);

    const handelTabs = (e: any, val: React.SetStateAction<number>) => {
        setSelectedTab(val)
    }

    const downloadData = () => {
        if (pageMappingDetail && tableDetail) {
            // const params = frameAPIParams();
            let params: { [k: string]: any } = {};
            params['pagenumber'] = 1;
            params['limit'] = totalCounts;
            addProcessingRequests();
            ServerAPI.executeAPI(pageMappingDetail.listEndpoint, ServerAPI.APIMethod.GET, true, null, params).then(response => {
                let exportData: any[] = [];
                if (pageMappingDetail.listResponseParam !== undefined && response['data'] !== undefined && response['data'][pageMappingDetail.listResponseParam] !== undefined) {
                    exportData = response['data'][pageMappingDetail.listResponseParam];
                } else if (response['data'] !== undefined) {
                    exportData = response['data'];
                } else if (response['status'] === false && response['message'] !== undefined) {
                    toast.error(response['message']);
                } else {
                    toast.error("Something Went Wrong");
                }

                if (exportData !== undefined && exportData.length > 0) {
                    let exportColumns: TableViewMappings.TableColumn[] = pageMappingDetail.exportColumns !== undefined ? [...pageMappingDetail.exportColumns] : [...tableDetail.columns];
                    if (pageMappingDetail.pageLink == PageLinks.DEALER_REPORT_TESTDRIVE) {
                        const exportDataMod = exportData.map((row: any) => row !== undefined ? {
                            ...row,
                            vehicle_data: row.demo_car_model + ' ' + row.demo_car_variant + ' ' + row.demo_car_fule_type + ' ' + row.demo_car_vin_number + ' ' + row.demo_car_tc_number,
                        } : row);
                        exportData = exportDataMod;
                    }
                    ExportExcel(exportData, pageMappingDetail.title, exportColumns);
                } else {
                    alert(ProjectStrings.ErrorAPIFailed);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const handleRangeChange = (value: any) => {
        if (value) {
            setDateRangeFrom(dayjs(value[0]).format('YYYY-MM-DD'));
            setDateRangeTo(dayjs(value[1]).format('YYYY-MM-DD'));
            setDateRangeSet(true);
            setReloadData(true);
        } else {
            setDateRangeSet(false);
            setReloadData(true);
        }
    }

    const handleMonthChange = (value: any) => {
        if (value) {
            setDateMonth(dayjs(value).format('MM-YY'));
            setDateMonthSet(true);
            setReloadData(true);
        } else {
            setDateMonth('');
            setDateMonthSet(false);
            setReloadData(true);
        }
    }

    const displayAdditionalFormDialogs = (index: number) => {
        if (!showAdditionalFormDialogs.includes(index)) {
            setShowAdditionalFormDialogs(idxs => [...idxs, index]);
        }
    }

    const hideAdditionalFormDialogs = (index: number) => {
        if (showAdditionalFormDialogs.includes(index)) {
            setShowAdditionalFormDialogs(showAdditionalFormDialogs.filter(formIndex => formIndex !== index));
        }
    }

    if (pageMappingDetail != undefined) {
        return (
            <>
                <div className="card-body-inner pr-0 d-flex mb-2">
                    <div className="card-tabs-width">
                        <div className="d-flex align-items-center justify-content-start" style={{ gap: "15px", marginTop: "-3px" }}>
                            <h4 className="header-title mt-0 mb-0">{pageMappingDetail.title}</h4>
                            <ul className="list-unstyled topbar-nav mb-0 d-flex align-items-center flex-row justify-content-end" style={{ height: "37px" }}>
                                {pageMappingDetail.dateFilterType &&
                                    <li className="card-body text-right p-0 mr-2 mt-2" style={{ width: "220px" }}>
                                        {pageMappingDetail.dateFilterType === DateFilterType.RangePicker && (
                                            <RangePicker
                                                value={dateRangeSet ? dayJSRange : undefined}
                                                onChange={handleRangeChange}
                                                allowClear={pageMappingDetail.dateFilterRequired ? false : true}
                                                suffixIcon={<RiCalendarLine className="remix-icon hp-text-color-black-100" />}
                                                defaultValue={pageMappingDetail.dateFilterRequired ? [dayjs().startOf('month'), dayjs()] : undefined}
                                            />
                                        )}
                                        {pageMappingDetail.dateFilterType === DateFilterType.MonthPicker && (
                                            <DatePicker
                                                value={dateMonthSet ? dayjs(dateMonth, 'MM-YY') : undefined}
                                                onChange={handleMonthChange}
                                                allowClear={pageMappingDetail.dateFilterRequired ? false : true}
                                                defaultValue={pageMappingDetail.dateFilterRequired ? dayjs(dayjs().format('YYYY/MM'), 'YYYY/MM') : undefined}
                                                format={'YYYY/MM'}
                                                picker="month" />
                                        )}
                                    </li>
                                }
                                {pageMappingDetail.filterPopup && <li className='d-flex align-items-center flex-row mt-2'>
                                    <div className=' mr-2' onClick={() => { filterApplied ? clearFilter() : setShowFilterPopup(true) }} >
                                        {filterApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                            <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i> Filter
                                        </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                            {/* <i className="fas fa-filter fs-15" aria-hidden="true" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Filter */}
                                            <TuneIcon style={{ fontSize: '14px', fontWeight: "500" }} />&nbsp;Filter
                                        </button>}
                                    </div>
                                </li>}
                                {pageMappingDetail.searchPopup && <li className='d-flex align-items-center flex-row mt-2'>
                                    <div className=' mr-2' onClick={() => { searchApplied ? clearSearch() : setShowSearchPopup(true) }} >
                                        {searchApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                            <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Clear Search Filter
                                        </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                            <i className="fas fa-search fs-15" aria-hidden="true" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Search
                                        </button>}
                                    </div >
                                </li>}
                                {pageMappingDetail.exportAvailable !== undefined && (
                                    <li className="hide-phone app-search pt-0 ml-0">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <div className="d-flex-justify-end mt-1">
                                                <button type="button" className={`btn download-excel mr-0`} onClick={downloadData}>
                                                    <img src={window.location.origin + "/assets/images/microsoft-excel-download-icon.png"} style={{ width: "20px" }} />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                            {pageMappingDetail.additionalFilter !== undefined && (
                                <div className="additional-filter pt-0" style={{ height: "37px", width: "220px" }}>
                                    <select value={selectedAdditionalFilter} className="form-control" onChange={e => setSelectedAdditionalFilter(e.target.value)} style={{ height: "37px" }}>
                                        {pageMappingDetail.additionalFilter.optional === true && (
                                            <option value="">Select {pageMappingDetail.additionalFilter.label}</option>
                                        )}
                                        {pageMappingDetail.additionalFilter.options.map((option, index) => (<option value={option.value} key={index}>{option.label}</option>))}
                                    </select>
                                </div>
                            )}
                        </div>
                        {pageMappingDetail.tabs !== undefined && (
                            <Tabs value={selectedTab}
                                onChange={handelTabs}
                                sx={{ backgroundColor: "white", "& button": { width: "25%" }, justifyContent: "space-between", marginTop: "20px" }}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#052160"
                                    }
                                }}
                            >
                                {pageMappingDetail.tabs.map((tab, tabIdx) => {
                                    return <Tab label={tab.tabLabel} className="tab-headers" onClick={() => navigate(tab.tabLink)} key={tabIdx} />
                                })}
                            </Tabs>
                        )}
                    </div>
                    {/* {pageMappingDetail.dateFilterType &&
                        <div className="card-body text-right pt-0 pr-0">
                            {pageMappingDetail.dateFilterType === DateFilterType.RangePicker && (
                                <RangePicker
                                    value={dateRangeSet ? dayJSRange : undefined}
                                    onChange={handleRangeChange}
                                    allowClear={pageMappingDetail.dateFilterRequired ? false : true}
                                    suffixIcon={<RiCalendarLine className="remix-icon hp-text-color-black-100" />}
                                    defaultValue={pageMappingDetail.dateFilterRequired ? [dayjs().startOf('month'), dayjs()] : undefined}
                                />
                            )}
                            {pageMappingDetail.dateFilterType === DateFilterType.MonthPicker && (
                                <DatePicker
                                    value={dateMonthSet ? dayjs(dateMonth, 'MM-YY') : undefined}
                                    onChange={handleMonthChange}
                                    allowClear={pageMappingDetail.dateFilterRequired ? false : true}
                                    defaultValue={pageMappingDetail.dateFilterRequired ? dayjs(dayjs().format('YYYY/MM'), 'YYYY/MM') : undefined}
                                    format={'YYYY/MM'}
                                    picker="month" />
                            )}
                        </div>
                    } */}
                    <div className="card-body text-right pb-0 pt-0 pr-0">
                        {(pageMappingDetail.tableFilter || pageMappingDetail.additionalListButtons || pageMappingDetail.searchAvailable || pageMappingDetail.addAvailable || pageMappingDetail.statusFilter || pageMappingDetail.sortFilter) &&
                            <div className="addnew d-flex align-items-center justify-content-end w-100 mr-2">
                                {pageMappingDetail.tableFilter &&
                                    <div className='d-flex align-items-center flex-row mt-2  mr-2'>
                                        <div className=' mr-2' onClick={() => {
                                            if (inventoryFilterApplied) {
                                                setShowInventoryFilterPopup(false);
                                                clearInventoryFilter();
                                            } else {
                                                setShowInventoryFilterPopup(true);
                                            }
                                        }}>
                                            {inventoryFilterApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px", marginTop: "-2px" }}>
                                                <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i> Filter
                                            </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px", marginTop: "-4px" }}>
                                                {/* <i className="fas fa-filter fs-15" aria-hidden="true" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Filter */}
                                                <TuneIcon style={{ fontSize: '14px', fontWeight: "500" }} />&nbsp;Filter
                                            </button>}
                                        </div>
                                    </div>
                                }
                                {pageMappingDetail.sortFilter !== undefined && (
                                    <div className="hide-phone app-search pt-0 ml-0 mt-1 mr-3" style={{ height: "37px" }}>
                                        <div className="form-group text-left mb-0 d-flex align-items-center flex-row">
                                            <div className="ew-section-2">
                                                <div className="text-field-empty-custom-user status-select">
                                                    <Select
                                                        styles={customStyles}
                                                        onChange={handleSortChange}
                                                        value={sortOptions.find((option) => option.value === sort) || null}
                                                        options={sortOptions}
                                                        placeholder={"Sort"}
                                                    />
                                                </div>
                                            </div>
                                            {pageMappingDetail.sortFilter !== undefined && sort !== "" && <div className="ml-2 mt-1" style={{ cursor: "pointer" }} onClick={() => { setSort(""); setReloadData(true) }}><i className="fa fa-times" style={{ color: "#000", marginRight: "5px", fontSize: "20px" }}></i></div>}
                                        </div>
                                    </div>
                                )}
                                {!!pageMappingDetail.additionalListButtons && pageMappingDetail.additionalListButtons.map((addlBtn, btnIdx) => {
                                    if (addlBtn.type === 'form-control') {
                                        return (
                                            <div className="d-flex- align-items-center mt-1" key={btnIdx}>
                                                <button className='toggle-button h-100' style={{ minHeight: "37px", width: "auto", fontSize: "14px" }} onClick={() => displayAdditionalFormDialogs(btnIdx)}>
                                                    {!!addlBtn.iconStart && <><img src={addlBtn.iconStart} alt="" style={{ marginTop: '-3px' }} />&nbsp;</>}
                                                    {addlBtn.title}
                                                    {!!addlBtn.iconEnd && <>&nbsp;{addlBtn.iconEnd}</>}
                                                </button>
                                                <i className="mdi mdi-tray-arrow-up"></i>
                                            </div>
                                        )
                                    }
                                })}
                                {pageMappingDetail.statusFilter !== undefined && (
                                    <div className="hide-phone app-search pt-0 ml-0 mt-1" style={{ height: "37px" }}>
                                        <div className="form-group text-left mb-0 d-flex align-items-center flex-row">
                                            <div className="ew-section-2">
                                                <div className="text-field-empty-custom-user status-select">
                                                    <Select
                                                        styles={customStyles}
                                                        onChange={handleStatusChange}
                                                        value={statusOptions.find((option) => option.value === status) || null}
                                                        options={statusOptions}
                                                        placeholder={"Select Status"}
                                                    />
                                                </div>
                                            </div>
                                            {pageMappingDetail.statusFilter !== undefined && status !== "1" && <div className="ml-2 mt-1" style={{ cursor: "pointer" }} onClick={() => { setStatus("1"); setReloadData(true) }}><i className="fa fa-times" style={{ color: "#000", marginRight: "5px", fontSize: "20px" }}></i></div>}
                                        </div>
                                    </div>
                                )}
                                {pageMappingDetail.searchAvailable === true && (
                                    <div className="hide-phone app-search pt-0 ml-3">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <form role="search" className="position-relative">
                                                <div className="d-flex-justify-end mt-1">
                                                    <input type="search" id="AllCompo" placeholder={`Search by ${pageMappingDetail.titleSingular} name`} className="form-control top-search-bar" value={searchString} onChange={(e: any) => {
                                                        if (searchTimeout) clearTimeout(searchTimeout);
                                                        setSearchString(e.target.value)
                                                    }} />
                                                </div>
                                                {pageMappingDetail.searchAvailable === true && (<div style={{ position: "absolute", top: "14px", right: "15px" }}><i className="fas fa-search" style={{ fontWeight: "600", fontSize: "16px" }} aria-hidden="true"></i></div>)}
                                            </form>
                                        </div>
                                    </div>
                                )}
                                {pageMappingDetail.addAvailable && (
                                    <button type="button" className="btn btn-sm btn-add-custom" onClick={() => {
                                        setDataID('');
                                        setCopyID('');
                                        setShowFormDialog(true);
                                    }}><i className="fa-solid fa-plus mr-2"></i>Add New</button>
                                )}
                            </div>
                        }
                        {/* <ul className="list-unstyled topbar-nav mb-0 d-flex align-items-center flex-row justify-content-end mt-3">
                            {pageMappingDetail.dateFilterType &&
                                <li className="card-body text-right p-0 mr-2 mt-2">
                                    {pageMappingDetail.dateFilterType === DateFilterType.RangePicker && (
                                        <RangePicker
                                            value={dateRangeSet ? dayJSRange : undefined}
                                            onChange={handleRangeChange}
                                            allowClear={pageMappingDetail.dateFilterRequired ? false : true}
                                            suffixIcon={<RiCalendarLine className="remix-icon hp-text-color-black-100" />}
                                            defaultValue={pageMappingDetail.dateFilterRequired ? [dayjs().startOf('month'), dayjs()] : undefined}
                                        />
                                    )}
                                    {pageMappingDetail.dateFilterType === DateFilterType.MonthPicker && (
                                        <DatePicker
                                            value={dateMonthSet ? dayjs(dateMonth, 'MM-YY') : undefined}
                                            onChange={handleMonthChange}
                                            allowClear={pageMappingDetail.dateFilterRequired ? false : true}
                                            defaultValue={pageMappingDetail.dateFilterRequired ? dayjs(dayjs().format('YYYY/MM'), 'YYYY/MM') : undefined}
                                            format={'YYYY/MM'}
                                            picker="month" />
                                    )}
                                </li>
                            }
                            {pageMappingDetail.filterPopup && <li className='d-flex align-items-center flex-row mt-2'>
                                <div className=' mr-2' onClick={() => { filterApplied ? clearFilter() : setShowFilterPopup(true) }} >
                                    {filterApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                        <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i> Filter
                                    </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                     
                                        <TuneIcon style={{ fontSize: '14px', fontWeight: "500" }} />&nbsp;Filter
                                    </button>}
                                </div>
                            </li>}
                            {pageMappingDetail.searchPopup && <li className='d-flex align-items-center flex-row mt-2'>
                                <div className=' mr-2' onClick={() => { searchApplied ? clearSearch() : setShowSearchPopup(true) }} >
                                    {searchApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                        <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Clear Search Filter
                                    </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                                        <i className="fas fa-search fs-15" aria-hidden="true" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Search
                                    </button>}
                                </div >
                            </li>}
                            {pageMappingDetail.exportAvailable !== undefined && (
                                <li className="hide-phone app-search pt-0 ml-0">
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <div className="d-flex-justify-end mt-3">
                                            <button type="button" className={`btn download-excel mr-0`} onClick={downloadData}>
                                                <img src={window.location.origin + "/assets/images/microsoft-excel-download-icon.png"} style={{ width: "20px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul> */}
                    </div>
                </div >

                {tableDetail !== undefined && (
                    <TableMain
                        tableDetail={tableDetail}
                        tableRows={tableRows ? tableRows : []}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageMappingDetail={pageMappingDetail}
                        setDataID={setDataID}
                        setCopyID={setCopyID}
                        setShowForm={setShowFormDialog}
                        setShowDetail={setShowDetailDialog}
                        deleteTableEntry={deleteEntry}
                        reloadData={() => setReloadData(true)}
                    />
                )
                }

                {showFilterPopup && pageMappingDetail.filterPopup && (<div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview' style={{ padding: "10px 15px 0rem 25px" }}>
                    <div className='custom-tableview-scroll'><UserTesDriveFilterModal showModal={showFilterPopup} closeModal={() => setShowFilterPopup(false)} filterLeads={filterLeads} /></div></div></div>)}
                {/* {showInventoryFilterPopup && page === PageLinks.USER_INVENTORY && (<UserInventoryFilterModal showModal={showInventoryFilterPopup} closeModal={() => setShowInventoryFilterPopup(false)} filterLeads={filterInventory} />)} */}
                {showSearchPopup && pageMappingDetail.searchPopup && (<div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview' style={{ padding: "10px 15px 0rem 25px" }}>
                    <div className='custom-tableview-scroll'><UserLeadsSearchModal showModal={showSearchPopup} closeModal={() => setShowSearchPopup(false)} filterLeads={SearchLeads} /></div></div></div>)}
                {showInventoryFilterPopup && <div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview' style={{ padding: "10px 15px 0rem 25px" }}>
                    <div className='custom-tableview-scroll'>
                        <UserInventoryFilter inventoryFilterApplied={setInventoryFilterApplied} showInventoryFilter={true} closeDialog={setShowInventoryFilterPopup} setInventoryParams={setInventoryFilterParams} />
                    </div></div></div>}
                {
                    showAdditionalFormDialogs.map(formIdx => {
                        if (pageMappingDetail.additionalListButtons && pageMappingDetail.additionalListButtons[formIdx].linkedForm) {
                            return (
                                React.createElement(
                                    pageMappingDetail.additionalListButtons[formIdx].linkedForm,
                                    {
                                        showDialog: showAdditionalFormDialogs.includes(formIdx),
                                        closeDialog: () => { hideAdditionalFormDialogs(formIdx) },
                                        reloadData: () => setReloadData(true),
                                        id: dataID,
                                        copyID: copyID
                                    }
                                )
                            )
                        }
                    })
                }
                {
                    showFormDialog === true && pageMappingDetail.form !== undefined && (
                        React.createElement(
                            pageMappingDetail.form,
                            {
                                showDialog: showFormDialog,
                                closeDialog: () => { setShowFormDialog(false) },
                                reloadData: () => setReloadData(true),
                                id: dataID,
                                copyID: copyID
                            }
                        )
                    )
                }
                {
                    showDetailDialog === true && pageMappingDetail.detailView !== undefined && !pageMappingDetail.detailViewIsForm && (
                        React.createElement(
                            pageMappingDetail.detailView,
                            {
                                showDialog: showDetailDialog,
                                closeDialog: () => { setShowDetailDialog(false) },
                                reloadData: () => setReloadData(true),
                                id: dataID,
                            }
                        )
                    )
                }
                {
                    showDetailDialog === true && pageMappingDetail.form !== undefined && pageMappingDetail.detailViewIsForm && (
                        React.createElement(
                            pageMappingDetail.form,
                            {
                                showDialog: showDetailDialog,
                                closeDialog: () => { setShowDetailDialog(false) },
                                reloadData: loadTableData,
                                id: dataID,
                                detailView: true,
                            }
                        )
                    )
                }
            </>
        )
    } else {
        return (
            <div />
        )
    }


}

export default CommonListing;
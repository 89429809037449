import { Drawer, Tabs, Tab, Button } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { API, Endpoints, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";

const BookaCarEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, resetState: () => void, updateSubmitValues?: APIData.UpdateLeadFollowUp | undefined, resetUpdateSubmitvalues?: () => void, resetInterestedState?: () => void, updateCloseDialog?: any, id?: string, detailView?: boolean, interestedUUID?: string, loadDetail?: boolean, reloadData: any, pageTitle?: string, stateID?: string, bookingUUID?: string, customerDetails?: any }> = ({ showDialog, closeDialog, id, updateSubmitValues, resetUpdateSubmitvalues, updateCloseDialog, resetState, interestedUUID, resetInterestedState, loadDetail, reloadData, detailView, pageTitle, stateID, bookingUUID, customerDetails }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [initialValue, setInitialValue] = useState<APIData.BookACar>(InitialData.BookACar);
    console.log(initialValue, "initial")
    const formikRef = useRef<FormikProps<APIData.BookACar>>(null);
    const [formDataSet, setFormDataSet] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerCityOptions, setCustomerCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerPaymentOptions, setCustomerPaymentOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedStateID, setSelectedStateID] = useState('');
    const [selectedCityID, setSelectedCityID] = useState('');
    const [selectedPincode, setSelectedPincode] = useState<number | undefined>();
    const [landmark, setlandmark] = useState<FormDataTypes.SelectOption[] | undefined>([]);
    const currentYear = new Date().getFullYear();
    const last3Years = Array.from({ length: 4 }, (_, index) => currentYear - index);


    const modelYearOptions = last3Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));

    const [formReRenderKey, setFormReRenderKey] = useState(Math.random());
    useEffect(() => {
        setFormReRenderKey(Math.random());
    }, [initialValue]);

    const yupSchema = Yup.object().shape({
        next_followup_date_time: Yup.string().required(ProjectStrings.ValidationRequired),
        car_details: Yup.object().shape({
            model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            exterior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            interior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            booking_amount: Yup.string().required(ProjectStrings.ValidationRequired),
            booking_amount_payment_mode_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        }),
        customer_details: Yup.object().shape({
            booking_name: Yup.string().required(ProjectStrings.ValidationRequired),
            mobile_number: Yup.string().matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            email_id: Yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: "Please enter valid Email.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            customer_type_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
            date_of_birth: Yup.string().required(ProjectStrings.ValidationRequired),
            linked: Yup.string().required("Linked is required"),
            pan_number: Yup.string().required(ProjectStrings.ValidationRequired),
            aadhaar_gst_number: Yup.string().required(ProjectStrings.ValidationRequired),
            address: Yup.string().required(ProjectStrings.ValidationRequired),
            pincode: Yup.string().required(ProjectStrings.ValidationRequired),
            landmark: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            city_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        }),
        price_breakup: Yup.object().shape({
            ex_showroom_price: Yup.string().required(ProjectStrings.ValidationRequired),
            insurance: Yup.string().required(ProjectStrings.ValidationRequired),
            registration_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            accessories_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            tcs_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            vas_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            logistics_handling_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            extended_warranty: Yup.string().required(ProjectStrings.ValidationRequired),
            csvp_service_plan: Yup.string().required(ProjectStrings.ValidationRequired),
            others: Yup.string().required("Others is required"),
        }),
        discount: Yup.object().shape({
            exchange_loyalty_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
            corporate: Yup.string().required(ProjectStrings.ValidationRequired),
            dealer_discount: Yup.string().required(ProjectStrings.ValidationRequired),
            referral_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
            oem_scheme: Yup.string().required(ProjectStrings.ValidationRequired),
            others: Yup.string().required("Others is required"),
        }),
        exchange_finance_ew: Yup.object().shape({
            want_to_change_vehicle: Yup.string().required(ProjectStrings.ValidationRequired),
            did_customer_opt_for_finance: Yup.string().required(ProjectStrings.ValidationRequired),
            financier_name: Yup.string().when('did_customer_opt_for_finance', ([did_customer_opt_for_finance], schema) => {
                if (parseInt(did_customer_opt_for_finance) === 1 || parseInt(did_customer_opt_for_finance) === 2)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            loan_tenure: Yup.string().when('did_customer_opt_for_finance', ([did_customer_opt_for_finance], schema) => {
                if (parseInt(did_customer_opt_for_finance) === 1 || parseInt(did_customer_opt_for_finance) === 2)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            loan_amount: Yup.string().when('did_customer_opt_for_finance', ([did_customer_opt_for_finance], schema) => {
                if (parseInt(did_customer_opt_for_finance) === 1 || parseInt(did_customer_opt_for_finance) === 2)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            has_customer_bought_extended_warranty: Yup.string().required(ProjectStrings.ValidationRequired),
            extended_warranty_end_date: Yup.string().when('has_customer_bought_extended_warranty', ([has_customer_bought_extended_warranty], schema) => {
                if (parseInt(has_customer_bought_extended_warranty) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
        }),
        final_booking_details: Yup.object().shape({
            final_on_road_price: Yup.string().required(ProjectStrings.ValidationRequired),
            booking_amount: Yup.string().required(ProjectStrings.ValidationRequired),
            pending_total_invoice_amount: Yup.string().required(ProjectStrings.ValidationRequired),
            payment_mode_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        })
    });


    const exchangeOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const warrantyOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const financeOptions: FormDataTypes.SelectOption[] = [{ label: 'In House Finance', value: "1" }, { label: "Out House Finance", value: "2" }, { label: "No Finance", value: "3" }];

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelVariantList, setVariantOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'variant_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InteriorColorList, setInteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'interior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ExteriorColorList, setExteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'exterior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.FuelTypeList, setFuelTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'fuel_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CustomerTypeList, setCustomerTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'customer_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.PaymentModeList, setCustomerPaymentOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'payment_mode_name', 'data', 'list');
        setFormDataSet(true);
        setCurrentTab(0);
    }, []);

    useEffect(() => {
        if (showDialog === false) {
            if (resetInterestedState && interestedUUID !== "") {
                resetInterestedState();
            }
            setSelectedPincode(undefined);
            setlandmark(undefined);
            setSelectedStateID("");
            setSelectedCityID("");
            setCurrentTab(0);
            setInitialValue(InitialData.BookACar);
        }

    }, [showDialog]);



    useEffect(() => {
        if (id && loadDetail) {
            if (updateSubmitValues === undefined) {
                addProcessingRequests();
                ServerAPI.getLeadBookingDetail(id, bookingUUID).then(response => {
                    if (response && response.data && response.data.details && response.data.details.length > 0) {
                        const bookingDetail = response.data.details[0];
                        bookingDetail.car_details.vin_number = (bookingDetail.car_details.vin_number !== null) ? bookingDetail.car_details.vin_number : '';
                        setInitialValue({
                            lead_uuid: bookingDetail.lead_uuid,
                            interested_car_uuid: bookingDetail.interested_car_uuid,
                            next_followup_date_time: bookingDetail.next_followup_date_time,
                            car_details: {
                                model_uuid: bookingDetail.car_details.model_uuid,
                                variant_uuid: bookingDetail.car_details.variant_uuid,
                                exterior_uuid: bookingDetail.car_details.exterior_color_uuid,
                                interior_uuid: bookingDetail.car_details.interior_color_uuid,
                                vin_number: bookingDetail.car_details.vin_number,
                                booking_amount: bookingDetail.car_details.booking_amount,
                                booking_amount_payment_mode_uuid: bookingDetail.car_details.payment_mode_uuid,
                                model_year: bookingDetail.car_details.model_year + "",
                            },
                            customer_details: { ...bookingDetail.customer_details, linked: 1 },
                            price_breakup: bookingDetail.price_breakup,
                            discount: bookingDetail.discount,
                            exchange_finance_ew: bookingDetail.exchange_finance_ew,
                            final_booking_details: {
                                final_on_road_price: bookingDetail.lead_booking_final_details.final_on_road_price,
                                booking_amount: bookingDetail.lead_booking_final_details.booking_amount,
                                pending_total_invoice_amount: bookingDetail.lead_booking_final_details.pending_total_invoice_amount,
                                payment_mode_uuid: bookingDetail.lead_booking_final_details.payment_mode_uuid,
                            }
                        });
                        setFormDataSet(true);
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
            else {
                setInitialValue(InitialData.BookACar)
            }
        }
    }, [id, loadDetail]);

    useEffect(() => {
        if (id !== undefined && id !== '' && interestedUUID !== undefined && interestedUUID !== "" && loadDetail === false && stateID !== undefined && stateID !== '' && !!customerDetails) {
            addProcessingRequests();
            ServerAPI.getLeadInterestedVehicle(interestedUUID, id).then((response: any) => {
                if (response && response.data.details) {
                    const interestedVehicle = response.data.details;
                    formikRef.current?.setFieldValue("car_details.model_uuid", interestedVehicle.lead_model_uuid);
                    formikRef.current?.setFieldValue("car_details.variant_uuid", interestedVehicle.lead_variant_uuid);
                    formikRef.current?.setFieldValue("car_details.interior_uuid", interestedVehicle.lead_interior_uuid);
                    formikRef.current?.setFieldValue("car_details.exterior_uuid", interestedVehicle.lead_exterior_uuid);
                    formikRef.current?.setFieldValue("car_details.model_year", interestedVehicle.model_year + "");

                    formikRef.current?.setFieldValue("customer_details.booking_name", customerDetails.lead_cust_first_name + "");
                    formikRef.current?.setFieldValue("customer_details.mobile_number", customerDetails.lead_cust_mobile_number + "");
                    formikRef.current?.setFieldValue("customer_details.email_id", customerDetails.lead_cust_email + "");
                    formikRef.current?.setFieldValue("customer_details.customer_type_uuid", customerDetails.customer_type_uuid + "");
                    formikRef.current?.setFieldValue("customer_details.date_of_birth", customerDetails.lead_cust_dob + "");
                    formikRef.current?.setFieldValue("customer_details.address", customerDetails.lead_cust_address + "");
                    formikRef.current?.setFieldValue("customer_details.pincode", customerDetails.lead_cust_pincode + "");
                    formikRef.current?.setFieldValue("customer_details.landmark", customerDetails.lead_cust_landmark + "");
                    formikRef.current?.setFieldValue("customer_details.state_uuid", customerDetails.state_uuid + "");
                    formikRef.current?.setFieldValue("customer_details.city_uuid", customerDetails.city_uuid + "");

                    addProcessingRequests();
                    ServerAPI.getInventoryDetailsV2(interestedVehicle.lead_model_uuid, interestedVehicle.lead_variant_uuid, interestedVehicle.lead_exterior_uuid, interestedVehicle.lead_interior_uuid, interestedVehicle.lead_fuel_uuid, stateID, interestedVehicle.model_year).then(response => {
                        if (response && response.data.details) {
                            const inventoryPrice = response.data.details;
                            formikRef.current?.setFieldValue("price_breakup.ex_showroom_price", inventoryPrice.ex_showroom_price);
                            formikRef.current?.setFieldValue("price_breakup.insurance", inventoryPrice.insurance);
                            formikRef.current?.setFieldValue("price_breakup.tcs_charges", inventoryPrice.tcs);
                            formikRef.current?.setFieldValue("price_breakup.registration_charges", inventoryPrice.registration_charges);
                            formikRef.current?.setFieldValue("price_breakup.logistics_handling_charges", inventoryPrice.logistic_handing);
                            formikRef.current?.setFieldValue("price_breakup.vas_charges", inventoryPrice.vas_charge);
                            formikRef.current?.setFieldValue("price_breakup.extended_warranty", inventoryPrice.extend_warrenty);
                            formikRef.current?.setFieldValue("price_breakup.csvp_service_plan", inventoryPrice.service_plan);
                            formikRef.current?.setFieldValue("price_breakup.others", inventoryPrice.others);
                            formikRef.current?.setFieldValue("discount.exchange_loyalty_bonus", inventoryPrice.exchange_loyalty_bonus);
                            formikRef.current?.setFieldValue("discount.corporate", inventoryPrice.corporate_discount);
                            formikRef.current?.setFieldValue("discount.dealer_discount", inventoryPrice.delear_discount);
                            formikRef.current?.setFieldValue("discount.referral_bonus", inventoryPrice.referral_bonus);
                            formikRef.current?.setFieldValue("discount.oem_scheme", inventoryPrice.oem_scheme);
                            formikRef.current?.setFieldValue("discount.others", inventoryPrice.discount_others);
                        }
                    }).finally(() => {
                        reduceProcessingRequests();
                    });
                    setFormReRenderKey(Math.random());
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id, interestedUUID]);


    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    useEffect(() => {
        if (selectedPincode !== undefined && selectedPincode.toString().length === 6) {
            addProcessingRequests();
            ServerAPI.getBookCarPincode(selectedPincode).then(response => {
                if (response && response.data && response.data.list.length > 0) {
                    const landmarkData = response.data.list[0].landmark.map((item: any) => ({
                        label: item,
                        value: item
                    }));
                    const newLandmark = landmarkData;
                    const newStateID = response.data.list[0].state_uuid;
                    const newCityID = response.data.list[0].city_uuid;
                    setSelectedCityID(newCityID);
                    setSelectedStateID(newStateID);
                    setlandmark(newLandmark);
                    // setFormReRenderKey(Math.random());
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedPincode]);

    useEffect(() => {
        if (selectedStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedStateID;
            getDataFromAPI(API.EndPoint.CityList, setCustomerCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
            setFormReRenderKey(Math.random());
        } else {
            setCustomerCityOptions([]);
        }
    }, [selectedStateID]);

    useEffect(() => {
        formikRef.current?.setFieldValue('customer_details.state_uuid', selectedStateID);
        formikRef.current?.setFieldValue('customer_details.city_uuid', selectedCityID);
        setFormReRenderKey(Math.random());

    }, [selectedCityID, selectedStateID]);


    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    const handleDialogClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="book-a-car-section drawer-max">
            {formDataSet && initialValue !== undefined && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    //submitvalues
                    submitValues['lead_uuid'] = id;
                    submitValues.car_details.booking_amount = submitValues.car_details.booking_amount.toString();
                    submitValues.final_booking_details.final_on_road_price = submitValues.final_booking_details.final_on_road_price.toString();
                    submitValues.final_booking_details.pending_total_invoice_amount = submitValues.final_booking_details.pending_total_invoice_amount.toString();
                    submitValues.final_booking_details.booking_amount = submitValues.final_booking_details.booking_amount.toString();
                    submitValues.price_breakup.ex_showroom_price = submitValues.price_breakup.ex_showroom_price.toString();
                    submitValues.price_breakup.insurance = submitValues.price_breakup.insurance.toString();
                    submitValues.price_breakup.registration_charges = submitValues.price_breakup.registration_charges.toString();
                    submitValues.price_breakup.accessories_charges = submitValues.price_breakup.accessories_charges.toString();
                    submitValues.price_breakup.tcs_charges = submitValues.price_breakup.tcs_charges.toString();
                    submitValues.price_breakup.logistics_handling_charges = submitValues.price_breakup.logistics_handling_charges.toString();
                    submitValues.price_breakup.vas_charges = submitValues.price_breakup.vas_charges.toString();
                    submitValues.price_breakup.extended_warranty = submitValues.price_breakup.extended_warranty.toString();
                    submitValues.price_breakup.csvp_service_plan = submitValues.price_breakup.csvp_service_plan.toString();
                    submitValues.price_breakup.others = submitValues.price_breakup.others.toString();
                    submitValues.exchange_finance_ew.loan_tenure = submitValues.exchange_finance_ew.loan_tenure.toString();
                    submitValues.exchange_finance_ew.added_exchange_vehicle_details_for_this_lead = submitValues.exchange_finance_ew.added_exchange_vehicle_details_for_this_lead.toString();
                    submitValues.exchange_finance_ew.loan_amount = submitValues.exchange_finance_ew.loan_amount.toString();
                    submitValues.discount.others = submitValues.discount.others.toString();
                    submitValues.discount.oem_scheme = submitValues.discount.oem_scheme.toString();
                    submitValues.discount.referral_bonus = submitValues.discount.referral_bonus.toString();
                    submitValues.discount.dealer_discount = submitValues.discount.dealer_discount.toString();
                    submitValues.discount.corporate = submitValues.discount.corporate.toString();
                    submitValues.discount.exchange_loyalty_bonus = submitValues.discount.exchange_loyalty_bonus.toString();
                    submitValues.customer_details.mobile_number = submitValues.customer_details.mobile_number.toString();
                    submitValues.customer_details.pincode = submitValues.customer_details.pincode.toString();
                    submitValues.customer_details.linked = submitValues.customer_details.linked.toString();
                    if (!!updateSubmitValues) {
                        if (pageTitle === "Updating Follow-up Call") {
                            addProcessingRequests();
                            ServerAPI.LeadUpdateFollowUp(updateSubmitValues)
                                .then(response => {
                                    if (response && response['success'] && response['message']) {
                                        toast.success(response['message']);
                                        ServerAPI.bookACar(submitValues).then(response => {
                                            if (response && response['success'] && response['message']) {
                                                toast.success(response['message']);
                                                if (resetUpdateSubmitvalues) {
                                                    resetUpdateSubmitvalues();
                                                }
                                                resetState();
                                                updateCloseDialog();
                                                closeDialog();
                                                setCurrentTab(0)
                                            } else if (response && response['message']) {
                                                toast.error(response['message']);
                                            }
                                        }).finally(() => {
                                            setSubmitting(false);
                                            reduceProcessingRequests();
                                        });
                                    } else if (response && response['message']) {
                                        toast.error(response['message']);
                                    } else {
                                        toast.error('Lead Update API Should Call First');
                                    }
                                })
                                .finally(() => {
                                    reduceProcessingRequests();
                                });
                        } else if (pageTitle === "Updating a Visit") {
                            addProcessingRequests();
                            ServerAPI.LeadUpdateVisit(updateSubmitValues)
                                .then(response => {
                                    if (response && response['success'] && response['message']) {
                                        toast.success(response['message']);
                                        ServerAPI.bookACar(submitValues).then(response => {
                                            if (response && response['success'] && response['message']) {
                                                toast.success(response['message']);
                                                if (resetUpdateSubmitvalues) {
                                                    resetUpdateSubmitvalues();
                                                }
                                                resetState();
                                                updateCloseDialog();
                                                closeDialog();
                                                setCurrentTab(0)
                                            } else if (response && response['message']) {
                                                toast.error(response['message']);
                                            }
                                        }).finally(() => {
                                            setSubmitting(false);
                                            reduceProcessingRequests();
                                        });
                                    } else if (response && response['message']) {
                                        toast.error(response['message']);
                                    } else {
                                        toast.error('Lead Update API Should Call First');
                                    }
                                })
                                .finally(() => {
                                    reduceProcessingRequests();
                                });
                        }
                    } else {
                        if (interestedUUID) {
                            submitValues['interested_car_uuid'] = interestedUUID
                        }
                        addProcessingRequests();
                        ServerAPI.bookACar(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                resetState();
                                reloadData();
                                closeDialog();
                                setCurrentTab(0)
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }}>
                    {(formikProps) => {
                        if (
                            formikProps.values.customer_details.pincode &&
                            formikProps.values.customer_details.pincode.toString().length === 6 &&
                            parseInt(formikProps.values.customer_details.pincode) !== selectedPincode
                        ) {
                            const newPincode = parseInt(formikProps.values.customer_details.pincode);
                            setSelectedPincode(newPincode);
                        }

                        return (
                            <Form key={formReRenderKey}>
                                <div className="tabdetails" style={{ overflowY: "auto" }}>
                                    <h4 className="title-text ml-5 text-center"> Book a Car</h4>
                                    <div className="custom-border-grey"></div>
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs" onClick={handleDialogClick}>
                                            <Tabs value={currentTab} onChange={handelTabChange}
                                                sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "auto" }, justifyContent: "space-between" }}
                                                TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: "#14408B",
                                                    },
                                                }}
                                            >
                                                <Tab label="Car Details" className="tab-headers" />
                                                <Tab label="Customer Details " className="tab-headers" />
                                                <Tab label="Price Break-up" className="tab-headers" />
                                                <Tab label="Discount" className="tab-headers" />
                                                <Tab label="Exchange / Finance / EW" className="tab-headers" />
                                                <Tab label="Final Booking Details" className="tab-headers" />

                                            </Tabs>
                                            {currentTab === 0 &&
                                                <>
                                                    <div className="row py-3 mt-2">
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "car_details.model_uuid", placeholder: "Select Model", options: modelOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "car_details.variant_uuid", placeholder: "Select Variant", options: variantOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "car_details.exterior_uuid", placeholder: "Select Exterior Color", options: exteriorColorOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "car_details.interior_uuid", placeholder: "Select Interior Color", options: interiorColorOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model Year", name: "car_details.model_year", placeholder: "Select Model Year", options: modelYearOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "VIN", name: "car_details.vin_number", placeholder: "Enter Vin Number", readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Booking Amount", name: "car_details.booking_amount", placeholder: "Enter Booking Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Booking Amount Payment Mode", name: "car_details.booking_amount_payment_mode_uuid", placeholder: "Select Payment Mode", options: customerPaymentOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(1)}>Next</button>
                                                    </div>
                                                </>
                                            }
                                            {currentTab === 1 &&
                                                <>
                                                    <div className="row py-3 mt-2">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Booking Name", name: "customer_details.booking_name", placeholder: "Enter Booking Name", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Mobile Number", name: "customer_details.mobile_number", placeholder: "Enter Mobile Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Id", name: "customer_details.email_id", placeholder: "Enter Email Id", required: true }} />
                                                        </div>

                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Date of Birth", name: "customer_details.date_of_birth", placeholder: "Enter Date of Birth *", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-2">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Customer Type", name: "customer_details.customer_type_uuid", placeholder: "Select Customer Type", options: customerTypeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 mt-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "PAN Number", name: "customer_details.pan_number", placeholder: "Enter PAN Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Aadhaar/GST Number", name: "customer_details.aadhaar_gst_number", placeholder: "Enter Aadhaar/GST Number*", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-3">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Pan is Linked to Aadhar", name: "customer_details.linked", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Pincode", name: "customer_details.pincode", placeholder: "Enter Pincode", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "customer_details.state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "customer_details.city_uuid", placeholder: "Select City", options: customerCityOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Landmark", name: "customer_details.landmark", placeholder: "Select Landmark", required: true, options: landmark, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 text-field-empty-custom-1 text-area${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Address", name: "customer_details.address", placeholder: "Enter Address", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(0)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(2)}>Next</button>
                                                    </div>
                                                </>
                                            }
                                            {currentTab === 2 &&
                                                <>
                                                    <div className="row py-3 mt-2">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Ex Showroom Price", name: "price_breakup.ex_showroom_price", placeholder: "Enter Ex Showroom Price", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Insurance", name: "price_breakup.insurance", placeholder: "Enter Insurance", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Registration Charges", name: "price_breakup.registration_charges", placeholder: "Enter Registration Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Accessories Charges", name: "price_breakup.accessories_charges", placeholder: "Enter Accessories Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "TCS Charges@__%", name: "price_breakup.tcs_charges", placeholder: "Enter TCS Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "VAS Charges", name: "price_breakup.vas_charges", placeholder: "Enter Logistics & Handling Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Logistics & Handling Charges", name: "price_breakup.logistics_handling_charges", placeholder: "Enter Logistics & Handling Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Extended Warranty", name: "price_breakup.extended_warranty", placeholder: "Enter Extended Warranty Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "CSVP/Service Plan", name: "price_breakup.csvp_service_plan", placeholder: "Enter CSVP/Service Plan Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Others", name: "price_breakup.others", placeholder: "Enter Sum Total of all Other Amounts", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-md-4 col-lg-4 col-xl-4 mt-1 text-field-empty-custom-1">
                                                            <label className="label-custom">Total Price Amount</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={
                                                                    parseInt(String(formikProps.values.price_breakup.ex_showroom_price || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.insurance || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.registration_charges || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.accessories_charges || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.tcs_charges || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.vas_charges || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.logistics_handling_charges || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.extended_warranty || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.csvp_service_plan || 0), 10) +
                                                                    parseInt(String(formikProps.values.price_breakup.others || 0), 10)
                                                                }
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(1)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(3)}>Next</button>
                                                    </div>
                                                </>
                                            }
                                            {currentTab === 3 &&
                                                <>
                                                    <div className="row mt-2 py-3">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Exchange / Loyalty Bonus", name: "discount.exchange_loyalty_bonus", placeholder: "Enter Exchange/Loyalty Bonus Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Corporate", name: "discount.corporate", placeholder: "Enter Corporate Discount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Dealer Discount", name: "discount.dealer_discount", placeholder: "Enter Dealer Discount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Referral Bonus", name: "discount.referral_bonus", placeholder: "Enter Referral Bonus Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "OEM Scheme", name: "discount.oem_scheme", placeholder: "Enter OEM Scheme Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Others", name: "discount.others", placeholder: "Enter Any Other Discount Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-md-4 col-lg-4 col-xl-4 mt-1 text-field-empty-custom-1">
                                                            <label className="label-custom">Total Discount Amount</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={
                                                                    parseInt(String(formikProps.values.discount.corporate || 0), 10) +
                                                                    parseInt(String(formikProps.values.discount.dealer_discount || 0), 10) +
                                                                    parseInt(String(formikProps.values.discount.exchange_loyalty_bonus || 0), 10) +
                                                                    parseInt(String(formikProps.values.discount.oem_scheme || 0), 10) +
                                                                    parseInt(String(formikProps.values.discount.others || 0), 10) +
                                                                    parseInt(String(formikProps.values.discount.referral_bonus || 0), 10)
                                                                }
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(2)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(4)}>Next</button>
                                                    </div>
                                                </>
                                            }
                                            {currentTab === 4 &&
                                                <>
                                                    <div className="row mt-2 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 mb-4 exchange-radio " style={{ height: "auto" }}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did customer exchange any vehicle?", name: "exchange_finance_ew.want_to_change_vehicle", options: exchangeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {formikProps.values.exchange_finance_ew.want_to_change_vehicle.toString() === "1" && (
                                                            <div className="col-lg-6 col-md-6 col-xl-6 mt-3 mb-4 exchange-checkbox">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Added \"Exchange Vehicle Details\" for this Lead", name: "exchange_finance_ew.added_exchange_vehicle_details_for_this_lead", readOnly: detailView ? true : false }} />
                                                            </div>
                                                        )}
                                                        <div className="col-lg-6 col-md-6 col-xl-6 mb-4 exchange-radio" style={{ height: "auto" }}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did customer opt for finance?", name: "exchange_finance_ew.did_customer_opt_for_finance", options: financeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {(formikProps.values.exchange_finance_ew.did_customer_opt_for_finance.toString() === "1" || formikProps.values.exchange_finance_ew.did_customer_opt_for_finance.toString() === "2") && (
                                                            <> <div className={`col-md-6 col-lg-6 col-xl-6 mb-4 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Financier Name", name: "exchange_finance_ew.financier_name", placeholder: "Enter Financier Name", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mb-4 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Loan Amount", name: "exchange_finance_ew.loan_amount", placeholder: "Enter Loan amount", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mb-4 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Loan Tenure", name: "exchange_finance_ew.loan_tenure", placeholder: "Enter Loan Tenure", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="col-lg-6 col-md-6 col-xl-6 exchange-radio" style={{ height: "auto" }}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Has customer bought extended warranty?", name: "exchange_finance_ew.has_customer_bought_extended_warranty", options: warrantyOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {formikProps.values.exchange_finance_ew.has_customer_bought_extended_warranty.toString() === "1" && (
                                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user mt-3">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Extended Warranty End Date", name: "exchange_finance_ew.extended_warranty_end_date", placeholder: "Enter EW End Date", required: true, readOnly: detailView ? true : false, allowFutureDate: true }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(3)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(5)}>Next</button>
                                                    </div>
                                                </>
                                            }
                                            {currentTab === 5 &&
                                                <>
                                                    <div className="row mt-2 py-3">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Final On Road Price", name: "final_booking_details.final_on_road_price", placeholder: "Enter Final On Road Price", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Booking Amount", name: "final_booking_details.booking_amount", placeholder: "Enter Booking Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Pending Total Invoice Amount", name: "final_booking_details.pending_total_invoice_amount", placeholder: "Enter Pending Total Invoice Amount*", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 mt-2 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Booking Amount Payment Mode", name: "final_booking_details.payment_mode_uuid", placeholder: "Enter Payment Mode*", required: true, options: customerPaymentOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {!detailView && <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user book-final">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Next Followup Date Time", name: "next_followup_date_time", placeholder: "Enter Next Followup Date Time*", required: true, readOnly: detailView ? true : false }} />
                                                        </div>}
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(4)} style={detailView ? { textAlign: "center" } : {}}>Previous</button>
                                                        {!detailView && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                            formikProps.submitForm();
                                                            if (!formikProps.isValid) {
                                                                toast.error("Please ensure all fields are Filled and Valid!");
                                                            }
                                                        }}>Submit</button>}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer >
    )
}

export default BookaCarEntryDialog;

import { useAppStateAPI } from '../../common/AppStateAPI';
import { Drawer } from "@mui/material";
import { APIData } from '../../common/DataTypes';
import { useEffect, useState } from 'react';
import { ServerAPI } from '../../common/ServerAPI';
import { API } from '../../common/Constants';
import CreateNewLeadEntryDialog from '../../forms/CreateNewLeadEntryDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserLeadDetailDialog from '../../detailview/UserLeadDetailDialog';

const UserLeadFilter: React.FC<{ showModal: boolean, closeModal: () => void }> = ({ showModal, closeModal }) => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [leadID, setLeadID] = useState("");
    const [showCreateNewLead, setShowCreateNewLead] = useState(false);
    const [leadFilters, setLeadFilters] = useState<APIData.LeadFilters[]>([]);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [detailLeadUUID, setDetailLeadUUID] = useState('');
    const [showLeadPopup, setShowLeadPopup] = useState(false);
    const [reloadData, setReloadData] = useState(false);

    const showLeadDetail = (leadUUID: string, access: number) => {
        if (access === 1) {
            setShowLeadPopup(true);
            setDetailLeadUUID(leadUUID);
        }
    }

    useEffect(() => {
        let params: { [k: string]: any } = {};
        if (!!customerName || !!leadID || !!mobileNumber) {
            if (customerName) params["customer_name"] = customerName;
            if (leadID) params["lead_id"] = leadID;
            if (mobileNumber) params["mobile_number"] = mobileNumber;
            addProcessingRequests();
            ServerAPI.executeAPI(API.EndPoint.LeadFilters, ServerAPI.APIMethod.GET, true, null, params).then((response: any) => {
                if (response && response.data.list) {
                    const leadFilters = response.data.list;
                    setLeadFilters(leadFilters);
                }
            }).finally(() => {
                reduceProcessingRequests();
                setReloadData(false);
            });
        }
    }, [mobileNumber, customerName, leadID, reloadData]);

    const clearFilter = () => {
        setMobileNumber("");
        setCustomerName("");
        setLeadID("");
        setLeadFilters([]);
    }

    return (
        <Drawer anchor="right" open={showModal} onClose={() => { closeModal(); clearFilter() }} className="book-a-car-section drawer-max">
            <div className='tabdetails' style={{ height: "87vh", overflowY: "auto" }}>
                <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Add / Search Lead</h4>
                <div className="custom-border-grey my-2"></div>
                <div className='row pb-2 py-3'>
                    <div className="form-group-1 col-4 text-left">
                        <div className="input-group mb-4">
                            <label className='label-custom' >Mobile Number</label>
                            <input type="text" className="form-control" id="phone" placeholder="Enter Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group-1 col-4 text-left">
                        <div className="input-group mb-4">
                            <label className='label-custom' >Lead ID</label>
                            <input type="text" className="form-control" id="phone" placeholder="Enter Lead ID" value={leadID} onChange={(e) => setLeadID(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group-1 col-4 text-left">
                        <div className="input-group">
                            <label className='label-custom' >Customer Name</label>
                            <input type="text" className="form-control" id="phone" placeholder="Enter Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                        </div>
                    </div>

                    {
                        leadFilters.length > 0 ? (
                            <>
                                <div className='user-lead-details-container mt-3 mr-1 px-2'>
                                    <div className='custom-tablescroll-2' style={{ paddingLeft: "5px", overflowY: "visible" }}>
                                        <div className='table-container'>
                                            <div className='table-scroll pb-0'>
                                                {leadFilters.map((lead, leadIdx) => (
                                                    <div className="table-responsive" key={leadIdx}>
                                                        <div className='d-flex justify-content-between p-2'>
                                                            <p className='mb-0 user-leadname'><strong>{lead.lead_cust_first_name} {lead.lead_cust_last_name}</strong> <span>(Lead ID : {lead.lead_id})</span></p>
                                                            <div className='d-flex align-items-center'>
                                                                <MoreVertIcon style={{ cursor: "pointer" }} onClick={() => showLeadDetail(lead.lead_uuid, lead.access)} />
                                                            </div>
                                                        </div>
                                                        <div className='row user-leads-second'>
                                                            <div className='col-8 user-lead-details'>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Showroom Name</p>
                                                                    <p className='mb-0 userlead-numval'>{lead.showroom_name}</p>
                                                                </div>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Lead Source</p>
                                                                    <p className='mb-0 userlead-numval'>{lead.lead_source_name}</p>
                                                                </div>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Model</p>
                                                                    <p className='mb-0 userlead-numval'>{lead.model_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-4 d-flex align-items-center justify-content-end'>
                                                                <div className='d-flex align-items-center justify-content-center w-10'><p className='user-lead-call'>{lead.current_status}</p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>) : <div className="table-responsive" style={{ margin: "1rem 1rem 1rem 0.8rem" }}>
                            <table className="table table-1 custom-border">
                                <tbody>
                                    <tr>
                                        <td className="text-align-l">No Data Available</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    }
                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                        <button type="button" className="button1" onClick={clearFilter}>Clear</button>
                        <button type="button" className="button2" onClick={() => { setShowCreateNewLead(true) }}>Create Lead</button>
                    </div>
                </div>
                <CreateNewLeadEntryDialog showDialog={showCreateNewLead} closeDialog={() => setShowCreateNewLead(false)} closeModel={() => closeModal()} mobileNumber={mobileNumber} />
                <UserLeadDetailDialog showDialog={showLeadPopup} closeDialog={() => setShowLeadPopup(false)} reloadData={() => setReloadData(true)} id={detailLeadUUID} />
            </div>
        </Drawer>
    )
}

export default UserLeadFilter;
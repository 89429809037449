import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useProSidebar } from 'react-pro-sidebar';
import { API, EndUser, PageLinks } from "../common/Constants";
import { getDealerCode, getLoginLogo, getLoginUserName, getLoginUserType, getUserTypeDashboardLink, logout, getLoginUserNumber, getCompanyCode, getSelectedDealer, getLoginUserID, persistSelectedDealer, persistLoginDetail, getLoginUserShortName, getFormattedLocalDateTime } from "../common/Utilities";
import DealerEntryDialog from "../forms/DealerEntryDialog";
import { APIData } from "../common/DataTypes";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";

import DealerSelectionModal from "./ui_components/DealerSelectionModal";
import UserEntryDialog from "../forms/UserEntryDialog";
import UserOEMCredentialsEntryDialog from "../forms/UserOEMCredentialsEntryDialog";


interface Dealer {
  name: string;
  dealer_code: string;
  image_url: string;
  mobile_image_url: string;
}

const Header: React.FC = () => {
  const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
  const { collapseSidebar } = useProSidebar();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLLIElement>(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showOEMCredentials, setShowOEMCredentials] = useState(false);
  const [loginDealer, setLoginDealer] = useState<Dealer>();
  const [dealerDetails, setDealerDetails] = useState<APIData.UserDealerSelection[]>([]);
  const [notificationLists, setNotificationLists] = useState<APIData.Notification[]>([]);
  const [selectedDealer, setSelectedDealer] = useState('');
  const [verifyOtpPopup, setVerifyOtpPopup] = useState(false);
  const [showDealerPopup, setShowDealerPopup] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const customTableViewRef = useRef<HTMLDivElement | null>(null);
  const [otp, setOtp] = useState('');
  const [oemStatus, setOemStatus] = useState<number>();
  const [endUser, setEndUser] = useState(EndUser.User);
  const [dealerOption, setDealerOption] = useState(true);

  console.log(notificationLists, "notifications")
  console.log(notificationLists.length, "notifications")

  const formatDiffDateTime = (inputDate: string) => {
    const now = new Date();
    const date = new Date(inputDate);

    // Convert both dates to timestamps (number)
    const timeDiff = Math.abs(now.getTime() - date.getTime()); // Difference in milliseconds
    const diffHours = Math.floor(timeDiff / (1000 * 60 * 60)); // Difference in hours

    const isToday = now.toDateString() === date.toDateString();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const isYesterday = yesterday.toDateString() === date.toDateString();

    if (diffHours < 1) {
      const diffMinutes = Math.floor(timeDiff / (1000 * 60));
      return `${diffMinutes} min ago`;
    } else if (diffHours <= 12) {
      return `${diffHours} hr${diffHours === 1 ? '' : 's'} ago`;
    } else if (isToday) {
      return `Today ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else if (isYesterday) {
      return `Yesterday ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return date.toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' });
    }
  }



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
        setShowDealerPopup(false);
        setVerifyOtpPopup(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDealerChange = (dealerCode: string) => {
    setSelectedDealer(dealerCode);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const toggleDropdown = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logout();
    if (getLoginUserType() == EndUser.SuperAdmin) {
      navigate(PageLinks.ADMIN_LOGIN);
    } else {
      navigate(PageLinks.LOGIN);
    }
  };

  const userDealerlist = () => {
    let params: { [k: string]: any } = {};
    params["company_code"] = getCompanyCode();
    params["phone_no"] = getLoginUserNumber();
    addProcessingRequests();
    ServerAPI.executeAPI(API.EndPoint.userDealerlist, ServerAPI.APIMethod.GET, true, null, params).then((response: any) => {
      if (response['success'] === true) {
        // toast.success(response['message']);
        if (response.data && response.data.dealer_details) {
          setDealerDetails(response.data.dealer_details);
        }
        // setStep(1);
      } else if (response['message']) {
        toast.error(response['message']);
      } else {
        toast.error('Something Went Wrong');
      }
    }).finally(() => {
      reduceProcessingRequests();
    })
  }

  const executeSendOTP = () => {
    if (!selectedDealer) {
      toast.error('Please select a Dealer');
    } else {
      addProcessingRequests();
      ServerAPI.userSendOtp(getCompanyCode(), selectedDealer, getLoginUserNumber()).then((response: any) => {
        if (response['success'] === true) {
          toast.success(response['message']);
          persistSelectedDealer(selectedDealer)
        } else if (response['message']) {
          toast.error(response['message']);
        } else {
          toast.error('Something Went Wrong');
        }
      }).finally(() => {
        reduceProcessingRequests();
      });
    }
  }

  const fetchNotification = () => {
    addProcessingRequests();
    ServerAPI.executeAPI(API.EndPoint.NotificationList, ServerAPI.APIMethod.GET, true, null).then((response: any) => {
      if (response.data.list && response['success'] === true) {
        const notificationList = response.data.list.slice(0, 5);
        setNotificationLists(notificationList);
      } else if (response['message']) {
        toast.error(response['message']);
      } else {
        toast.error('Something Went Wrong');
      }
    }).finally(() => {
      reduceProcessingRequests();
    });

  }

  const executeVerifyOtp = (otp: string) => {
    if (!otp) {
      toast.error('Enter OTP');
    } else {
      addProcessingRequests();
      ServerAPI.userVerifyOtp(getCompanyCode(), getSelectedDealer(), getLoginUserNumber(), otp).then((response: any) => {
        if (response['success'] === true) {
          toast.success(response['message']);
          const userDetail = response['data'];
          persistLoginDetail(true, userDetail['uuid'], userDetail['dealer_code'], userDetail['name'], userDetail['contact_email'], userDetail['login_token'], userDetail['profile_image'], endUser, userDetail['company_code'], userDetail['contact_phone'], userDetail['short_name']);
          navigate(PageLinks.USER_DASHBOARD_TODAY, { state: { selectedDealer } });
          if (dealerDetails) {
            const loginDealer = dealerDetails?.find(dealer => dealer.dealer_code === getSelectedDealer()) || null;
            if (loginDealer) {
              setLoginDealer(loginDealer);
            }
          }
          // setEditDealerImg(selectedDealerImg);
        } else if (response['message']) {
          toast.error(response['message']);
        } else {
          toast.error('Something Went Wrong');
        }
      }).finally(() => {
        reduceProcessingRequests();
        setShowDealerPopup(false);
        setVerifyOtpPopup(false);
        setOtp('')
      });
    }
  }


  const userprofile = () => {
    let params: { [k: string]: any } = {};
    params["phone_no"] = getLoginUserNumber();
    if (getLoginUserType() == EndUser.User && getLoginUserShortName() !== "SM") {
      addProcessingRequests();
      ServerAPI.executeAPI(API.EndPoint.UserProfile, ServerAPI.APIMethod.GET, true, null, params).then((response: any) => {
        if (response['success'] === true && response !== undefined) {
          // toast.success(response['message']);
          setOemStatus(response.user_details.oem_status)
        } else if (response['message']) {
          toast.error(response['message']);
        }
      }).finally(() => {
        reduceProcessingRequests();
      });
    }
  }


  useEffect(() => {
    if (dealerDetails) {
      const loginDealer = dealerDetails.find(dealer => dealer.dealer_code === getSelectedDealer());
      if (loginDealer) {
        setLoginDealer(loginDealer);
      }
    }
  }, [dealerDetails]);

  useEffect(() => {
    userprofile();
    fetchNotification();
    if (getCompanyCode() !== '' && getLoginUserNumber() !== '') {
      userDealerlist()
    }
  }, [])

  useEffect(() => {
    if (selectedDealer !== '') {
      executeSendOTP();
    }
  }, [selectedDealer]);



  return (
    <div>
      <div className="topbar">
        <div className="topbar-left">
          <Link to={getUserTypeDashboardLink()} className="logo">
            <span>
              <img src={window.location.origin + "/assets/images/logo512.png"} alt="logo-large" className="logo-lg logo-light" />
              <img src={window.location.origin + "/assets/images/logo512.png"} alt="logo-large" className="logo-lg" />
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav float-right mb-0">
            <li className="dropdown notification-list">
              <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown"
                role="button" aria-haspopup="false" aria-expanded="false" onClick={() => setShowNotificationPopup(!showNotificationPopup)}>
                <i className="ti-bell noti-icon"></i>
              </a>
              {showNotificationPopup && <div className="dropdown-menu dropdown-menu-right dropdown-lg pt-0 d-block">
                <h6
                  className="dropdown-item-text font-15 m-0 py-3 text-white d-flex justify-content-between align-items-center" style={{ backgroundColor: "#14408B", boxShadow: "0 -1px 4px #d6e4f1" }}>
                  Notifications
                </h6>
                {/* <span className="badge badge-light badge-pill">2</span> */}
                {notificationLists.length > 0 ? <div className="slimscroll notification-list">
                  {notificationLists.map((nots, index) => (<a className="dropdown-item py-3" key={index}>
                    <small className="float-right text-muted pl-2">{formatDiffDateTime(nots.date_time)}</small>
                    <div className="media">
                      <div className="media-body align-self-center ml-2 text-truncate">
                        <h6 className="my-0 font-weight-normal text-dark">{nots.sender_name}</h6>
                        <small className="text-muted mb-0">{nots.message}.</small>
                      </div>
                    </div>
                  </a>))}
                </div> : <div className="d-flex justify-content-center align-items-center m-3">
                  <small className="text-muted mb-0 font-15">There is No Notifications Yet</small>
                </div>}

                {notificationLists.length > 0 && <a className="dropdown-item text-center text-primary" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); navigate(PageLinks.NOTIFICATION); setShowNotificationPopup(false) }}>
                  View all <i className="fi-arrow-right"></i>
                </a>}
              </div>}
            </li>
            <li className="dropdown" ref={dropdownRef}>
              <button
                className="nav-link dropdown-toggle waves-effect waves-light nav-user"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
                onClick={toggleDropdown}
                style={{ background: "none", outline: "none", border: "none" }}
              >
                <img
                  src={getLoginLogo()}
                  // src={getLoginUserType() === 3 ? getLoginUserImage() ? getLoginUserImage() : window.location.origin + "/assets/images/blank-profile.png" : getLoginUserType() === 1 ? getLoginLogo() ? getLoginLogo() : window.location.origin + "/assets/images/blank-profile.png" : window.location.origin + "/assets/images/blank-profile.png"}
                  alt="profile-user"
                  className="rounded-circle"
                />
                <span className="ml-1 nav-user-name hidden-sm">
                  {getLoginUserName()} <i className="mdi mdi-chevron-down"></i>
                </span>
              </button>
              {showMenu && (
                <div className="dropdown-main">
                  <Link to="/" onClick={e => {
                    e.preventDefault();
                    setShowProfile(true);
                    setShowMenu(false);
                  }}>
                    <div className="dropdown-menu-items dropdown-menu-right show">
                      <div className="logout-spacer"></div>
                      <p className="nav-user-name mb-0">Profile</p>
                      <div className="logout-spacer"></div>
                    </div>
                  </Link>
                  {getLoginUserType() == EndUser.User && getLoginUserShortName() !== "SM" && oemStatus === 1 && <Link to="/" onClick={e => {
                    e.preventDefault();
                    setShowOEMCredentials(true);
                    setShowMenu(false);
                  }}>
                    <div className="dropdown-menu-items dropdown-menu-right show">
                      <div className="logout-spacer"></div>
                      <p className="nav-user-name mb-0">OEM Credentials</p>
                      <div className="logout-spacer"></div>
                    </div>
                  </Link>}
                  <Link to="/" onClick={e => {
                    e.preventDefault();
                    handleLogout(e);
                  }} >
                    <div className="dropdown-menu-items dropdown-menu-right show">
                      <div className="logout-spacer"></div>
                      <p className="nav-user-name mb-0">Logout</p>
                      <div className="logout-spacer"></div>
                    </div>
                  </Link>
                </div>

              )}
            </li>

          </ul>

          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button className="nav-link button-menu-mobile button-menu-mobile-hidden waves-effect waves-light"
                onClick={() => {
                  collapseSidebar();
                }}>
                <i className="ti-menu nav-icon"></i>
              </button>
            </li>
            <li>
              {getLoginUserType() == EndUser.User && dealerDetails !== undefined && dealerDetails.length > 1 && <>
                <div className="header-dealer">
                  <div className="header-dealer-con">
                    <img src={loginDealer !== undefined ? loginDealer.image_url : window.location.origin + 'assets/images/users/user-1.png'} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <p className="dealer-name" onClick={() => { setShowDealerPopup(true); setDealerOption(true) }}>{loginDealer !== undefined ? loginDealer.name : 'Not Selected'} <i className="mdi mdi-chevron-down"></i></p>
                </div>
              </>}
            </li>
          </ul>
        </nav>
        {showProfile && getLoginUserType() == EndUser.Dealer && (
          <DealerEntryDialog showDialog={showProfile} closeDialog={() => setShowProfile(false)} reloadData={() => { }} id={getDealerCode()} detailView={true} fromDealerLogin={true} />
        )}
        {showProfile && getLoginUserType() == EndUser.User && (
          <UserEntryDialog showDialog={showProfile} closeDialog={() => setShowProfile(false)} reloadData={() => { }} id={getLoginUserID()} detailView={true} fromUserLogin={true} />
        )}
        <UserOEMCredentialsEntryDialog showDialog={showOEMCredentials} closeDialog={() => setShowOEMCredentials(false)} id={getLoginUserID()} reloadData={() => { }} />

        {showDealerPopup && dealerOption && dealerDetails !== undefined && getLoginUserType() == EndUser.User && <DealerSelectionModal dealerDetails={dealerDetails} dealerSelection={handleDealerChange} showModal={dealerOption} closeModal={() => setDealerOption(false)} verifyOtp={verifyOtpPopup} executeOtp={(otp) => executeVerifyOtp(otp)} />}
      </div>
    </div >
  );
};

export default Header;

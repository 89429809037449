import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";


const InventoryPriceEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.InventoryPriceAdd>>(null);
    const [initialValue, setInitialValue] = useState<APIData.InventoryPriceAdd>(InitialData.InventoryPriceAdd);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedModelUUID, setSelectedModelUUID] = useState('');

    const currentYear = new Date().getFullYear();
    const last50Years = Array.from({ length: 50 }, (_, index) => currentYear - index);


    const modelYearOptions = last50Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        setFormDataSet(true);
    }, []);

    useEffect(() => {
        if (id) {
            addProcessingRequests();
            ServerAPI.getInventoryPriceDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    const inventoryPriceDetail = response.data.details;
                    setInitialValue({
                        models_uuid: inventoryPriceDetail.models_uuid,
                        variant_uuid: inventoryPriceDetail.variant_uuid,
                        mf_year: inventoryPriceDetail.mf_year,
                        model_year: inventoryPriceDetail.model_year,
                        ex_showroom_price: inventoryPriceDetail.ex_showroom_price,
                        tcs: inventoryPriceDetail.tcs,
                        insurance: inventoryPriceDetail.insurance,
                        registration_charges: inventoryPriceDetail.registration_charges,
                        state_uuid: inventoryPriceDetail.state_uuid,
                        logistic_handing: inventoryPriceDetail.logistic_handing,
                        vas_charge: inventoryPriceDetail.vas_charge,
                        extend_warrenty: inventoryPriceDetail.extend_warrenty,
                        service_plan: inventoryPriceDetail.service_plan,
                        others: inventoryPriceDetail.others,
                        exchange_loyalty_bonus: inventoryPriceDetail.exchange_loyalty_bonus,
                        corporate_discount: inventoryPriceDetail.corporate_discount,
                        delear_discount: inventoryPriceDetail.delear_discount,
                        referral_bonus: inventoryPriceDetail.referral_bonus,
                        oem_scheme: inventoryPriceDetail.oem_scheme,
                        discount_others: inventoryPriceDetail.discount_others
                    })
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        models_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        model_year: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        mf_year: Yup.string().required(ProjectStrings.ValidationRequired),
        ex_showroom_price: Yup.string().required(ProjectStrings.ValidationRequired),
        tcs: Yup.string().required(ProjectStrings.ValidationRequired),
        insurance: Yup.string().required(ProjectStrings.ValidationRequired),
        registration_charges: Yup.string().required(ProjectStrings.ValidationRequired),
        logistic_handing: Yup.string().required(ProjectStrings.ValidationRequired),
        vas_charge: Yup.string().required(ProjectStrings.ValidationRequired),
        extend_warrenty: Yup.string().required(ProjectStrings.ValidationRequired),
        service_plan: Yup.string().required(ProjectStrings.ValidationRequired),
        others: Yup.string().required(ProjectStrings.ValidationRequired),
        exchange_loyalty_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
        corporate_discount: Yup.string().required(ProjectStrings.ValidationRequired),
        delear_discount: Yup.string().required(ProjectStrings.ValidationRequired),
        oem_scheme: Yup.string().required(ProjectStrings.ValidationRequired),
        discount_others: Yup.string().required(ProjectStrings.ValidationRequired),
        referral_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getModelVariants(selectedModelUUID).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                const modelVariantOptions = response.data.list.map((item: any) => ({
                    label: item.variant_name,
                    value: item.uuid
                }))
                setVariantOptions(modelVariantOptions);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedModelUUID]);

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    // submitValues['tcs'] = submitValues['tcs'].toString();
                    // submitValues['ex_showroom_price'] = submitValues['ex_showroom_price'].toString();
                    // submitValues['registration_charges'] = submitValues['registration_charges'].toString();
                    // submitValues['logistic_handing'] = submitValues['logistic_handing'].toString();
                    // submitValues['vas_charge'] = submitValues['vas_charge'].toString();
                    // submitValues['extend_warrenty'] = submitValues['extend_warrenty'].toString();
                    // submitValues['service_plan'] = submitValues['service_plan'].toString();
                    // submitValues['others'] = submitValues['others'].toString();
                    // submitValues['exchange_loyalty_bonus'] = submitValues['exchange_loyalty_bonus'].toString();
                    // submitValues['corporate_discount'] = submitValues['corporate_discount'].toString();
                    // submitValues['delear_discount'] = submitValues['delear_discount'].toString();
                    // submitValues['referral_bonus'] = submitValues['referral_bonus'].toString();
                    // submitValues['oem_scheme'] = submitValues['oem_scheme'].toString();
                    // submitValues['discount_others'] = submitValues['discount_others'].toString();
                    const numericalFields = [
                        'tcs', 'ex_showroom_price', 'registration_charges', 'logistic_handing',
                        'vas_charge', 'extend_warrenty', 'service_plan', 'others',
                        'exchange_loyalty_bonus', 'corporate_discount', 'delear_discount',
                        'referral_bonus', 'oem_scheme', 'discount_others'
                    ];
                    numericalFields.forEach(field => {
                        if (typeof submitValues[field] === 'number') {
                            submitValues[field] = submitValues[field].toString();
                        }
                    });
                    if (id !== undefined && id !== "") {
                        addProcessingRequests();
                        submitValues["uuid"] = id;
                        ServerAPI.inventoryPriceUpdate(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.inventoryPriceAdd(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.models_uuid !== selectedModelUUID) {
                            setSelectedModelUUID(formikProps.values.models_uuid);
                        }

                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode && !detailView ? "Edit" : detailView ? "View" : "Add"} Inventory Price</h4>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section">
                                        <Form>
                                            <div className="px-2 form-field-container">
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "models_uuid", label: "Model", placeholder: "Select Model", options: modelOptions, required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "variant_uuid", label: "Variant", placeholder: "Select Variant", options: variantOptions, required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "monthyearpicker", name: "mf_year", label: "Mf Year", placeholder: "Select Mf Year", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "model_year", label: "Model Year", placeholder: "Select Model Year", required: true, options: modelYearOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "ex_showroom_price", label: "Ex Showroom Price", placeholder: " Enter Price", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "tcs", label: "Tcs", placeholder: "Enter Tcs", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " add-insurance" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "insurance", required: true, label: "Insurance", placeholder: "Enter Insurance", readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "registration_charges", required: true, label: "Registration Charges", placeholder: "Enter Registration Charges", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "state_uuid", label: "State", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "logistic_handing", label: "Logistic Handling", placeholder: "Enter Logistic Handling", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "vas_charge", label: "VAS Charges", placeholder: "Enter VAS Charges", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "extend_warrenty", label: "Extend Warranty", placeholder: "Enter Extend Warranty", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "service_plan", label: "Service Plan", placeholder: "Enter Service Plan", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "others", label: "Others", placeholder: "Enter Others", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "exchange_loyalty_bonus", label: "Exchange Loyalty Bonus", placeholder: "Enter Exchange Loyalty Bonus", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "corporate_discount", label: "Corporate Discount", placeholder: "Enter Corporate Discount", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "delear_discount", label: "Dealer Discount", placeholder: "Enter Dealer Discount", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "referral_bonus", label: "Referral Bonus", placeholder: "Enter Referral Bonus", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "oem_scheme", label: "Oem Scheme", placeholder: "Enter Oem Scheme", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "discount_others", label: "Discount Others", placeholder: "Enter Discount Others", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog} style={detailView ? { textAlign: "center" } : {}}>Cancel</button>
                                                {!detailView && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>{editMode ? "Save Changes" : "Submit"}</button>}
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default InventoryPriceEntryDialog;
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { PageLinks } from "../common/Constants";
import { FormDataTypes, UIDataTypes } from "../common/DataTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { RiCalendarLine } from "react-icons/ri";
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import UserDashboardTodayTable from "../components/custom_table/UserDashboardTodayTable";
import UserDashBoardETVBRLTable from "../components/custom_table/UserDashboardETVBRLTable"
import UserDashboardTarget from "../components/custom_table/UserDashboardTarget";
import UserDashboardET from "../components/custom_table/UserDashboardET";
import UserLeads from "../components/custom_table/UserLeads";
import Select from 'react-select';
import UserTasks from "../components/custom_table/UserTasks";
import UserTestdriveDashboard from "../components/custom_table/UserTesdriveDashboard";

const { RangePicker } = DatePicker;

const dashboardTabs: UIDataTypes.TabItem[] = [
    {
        tabLabel: "Todays Task",
        tabLink: PageLinks.USER_DASHBOARD_TODAY,
    },
    {
        tabLabel: "ETVBRL",
        tabLink: PageLinks.USER_DASHBOARD_ETVBRL,
    },
]

interface AdditionalFilter {
    label: string;
    paramName: string;
    optional: boolean;
    options: FormDataTypes.SelectOption[];
}

const AdditionalFilterUserTasks: AdditionalFilter = {
    label: 'Task Status',
    paramName: 'task_status',
    optional: false,
    options: [
        {
            label: "Today",
            value: "today"
        },
        {
            label: "Future",
            value: "future"
        },
        {
            label: "Completed",
            value: "complete"
        }
    ]
}
const AdditionalFilterUserLeads: AdditionalFilter = {
    label: 'Lead Status',
    paramName: 'lead_status',
    optional: false,
    options: [
        {
            label: "Open Lead",
            value: "openLead"
        },
        {
            label: "Close Lead",
            value: "closeLead"
        }
    ]
}

const UserDashboard: React.FC = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userName = urlParams.get('user_name') || '';
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [dateMonthSet, setDateMonthSet] = useState(false);
    const [dateMonth, setDateMonth] = useState(dayjs().format('MM-YY'));
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [dateRangeFrom, setDateRangeFrom] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [dateRangeFromLead, setDateRangeFromLead] = useState("");
    const [dateRangeTo, setDateRangeTo] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [dateRangeToLead, setDateRangeToLead] = useState("");
    const [dateRangeSet, setDateRangeSet] = useState(false);
    const [dayJSRange, setDayJSRange] = useState<RangeValue<Dayjs>>();
    const [selectedAdditionalTaskFilter, setSelectedAdditionalTaskFilter] = useState('today');
    const [selectedAdditionalLeadFilter, setSelectedAdditionalLeadFilter] = useState('openLead');
    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) }

    useEffect(() => {
        if (location.pathname === PageLinks.USER_DASHBOARD_TODAY) {
            setSelectedTab(0);
        } else {
            setSelectedTab(1);
        }
    }, [location.pathname])

    useEffect(() => {
        if (dateRangeFrom && dateRangeTo) {
            setDayJSRange([dayjs(dateRangeFrom), dayjs(dateRangeTo)])
        }
    }, [dateRangeFrom, dateRangeTo])

    const handelTabs = (e: any, val: React.SetStateAction<number>) => {
        setSelectedTab(val)
    }

    const handleMonthChange = (value: any) => {
        if (value) {
            setDateMonth(dayjs(value).format('MM-YY'));
            setDateMonthSet(true);
            setReloadData(true);
        } else {
            setDateMonth('');
            setDateMonthSet(false);
            setReloadData(true);
        }
    }

    // const handleRangeChange = (value: any) => {
    //     if (value) {
    //         setDateRangeFrom(dayjs(value[0]).format('YYYY-MM-DD'));
    //         setDateRangeFromLead(dayjs(value[0]).format('YYYY-MM-DD'));
    //         setDateRangeTo(dayjs(value[1]).format('YYYY-MM-DD'));
    //         setDateRangeToLead(dayjs(value[1]).format('YYYY-MM-DD'));
    //         setDateRangeSet(true);
    //         setReloadData(true);
    //     } else {
    //         setDateRangeSet(false);
    //         setReloadData(true);
    //     }
    // }

    const handleRangeChange = (value: any) => {
        if (value) {
            // If date range is selected, update the states with the selected dates
            setDateRangeFrom(dayjs(value[0]).format('YYYY-MM-DD'));
            setDateRangeFromLead(dayjs(value[0]).format('YYYY-MM-DD'));
            setDateRangeTo(dayjs(value[1]).format('YYYY-MM-DD'));
            setDateRangeToLead(dayjs(value[1]).format('YYYY-MM-DD'));
            setDateRangeSet(true);
        } else {
            setDateRangeFrom('');
            setDateRangeFromLead('');
            setDateRangeTo('');
            setDateRangeToLead('');
            setDateRangeSet(false);
        }
        setReloadData(true);
    }


    const isUserDashboard = location.pathname === PageLinks.USER_DASHBOARD_TODAY || location.pathname === PageLinks.USER_DASHBOARD_ETVBRL;

    return (
        <>
            <div className="card-body-inner pr-0">
                <div className="card-tabs-width">
                    <h4 className="header-title fs-22 mt-0 mb-0" style={{ paddingLeft: "5px" }}>{location.pathname === PageLinks.USER_DASHBOARD_TODAY || location.pathname === PageLinks.USER_DASHBOARD_ETVBRL ? "Dashboard" : location.pathname === PageLinks.USER_TARGETS ? "Targets" : location.pathname === PageLinks.USER_MY_LEADS ? "My Leads" : location.pathname === PageLinks.USER_ENQUIRY_TRANSFER ? "Enquiry transfer" : location.pathname === PageLinks.USER_TESTDRIVE_DASHBOARD ? "Testdrive" : location.pathname === PageLinks.USER_MY_TASKS && userName ? `${userName} Tasks` : "My Tasks"}</h4>
                    {isUserDashboard && (
                        <Tabs value={selectedTab}
                            onChange={handelTabs}
                            sx={{ backgroundColor: "white", "& button": { width: "35%" }, marginTop: "10px" }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#052160"
                                }
                            }}
                        >
                            {dashboardTabs.map((tab) => {
                                return <Tab label={tab.tabLabel} className="tab-headers" onClick={() => navigate(tab.tabLink)} />
                            })}
                        </Tabs>
                    )}
                </div>
                {location.pathname === PageLinks.USER_DASHBOARD_ETVBRL && (
                    <div className="card-body text-right pt-0 pr-0 mt-0 pb-1" style={{ width: "220px" }}>
                        <RangePicker
                            value={dateRangeSet ? dayJSRange : undefined}
                            onChange={handleRangeChange}
                            allowClear={location.pathname === PageLinks.USER_DASHBOARD_ETVBRL ? false : true}
                            suffixIcon={<RiCalendarLine className="remix-icon hp-text-color-black-100" />}
                            defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                        />
                    </div>
                )}
                {location.pathname === PageLinks.USER_MY_TASKS && (
                    <div className="card-body text-right pt-0 pr-0 pb-1 d-flex align-items-center justify-content-end" style={{ cursor: "pointer", gap: "10px" }}>
                        <div className="additional-filter-1">
                            {AdditionalFilterUserTasks !== undefined && (
                                <>
                                    {/* <select value={selectedAdditionalFilter} className="form-control mt-0 mr-2" onChange={e => setSelectedAdditionalFilter(e.target.value)}>
                                        {AdditionalFilterUserLeads.optional === true && (
                                            <option value="">Select {AdditionalFilterUserLeads.label}</option>
                                        )}
                                        {AdditionalFilterUserLeads.options.map((option, index) => (
                                            <option value={option.value} key={index}>{option.label}</option>
                                        ))}
                                    </select> */}

                                    <div className="ew-section-2">
                                        <div className="text-field-empty-custom-user lead-select-1">
                                            <Select
                                                styles={customStyles}
                                                onChange={(selectedOption) => setSelectedAdditionalTaskFilter(selectedOption?.value || '')}
                                                value={AdditionalFilterUserTasks.options.find(option => option.value === selectedAdditionalTaskFilter) || null}
                                                options={AdditionalFilterUserTasks.options}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div >
                )}
                {location.pathname === PageLinks.USER_MY_LEADS && (
                    <div className="card-body text-right pt-0 pr-0 pb-1 d-flex align-items-center justify-content-end" style={{ cursor: "pointer", gap: "10px" }}>
                        <div className="card-body text-right pt-0 pr-0 mt-0 pb-1" style={{ width: "220px" }}>
                            <RangePicker
                                value={dateRangeSet ? dayJSRange : undefined}
                                onChange={handleRangeChange}
                                allowClear={true}
                                suffixIcon={<RiCalendarLine className="remix-icon hp-text-color-black-100" />}
                            // defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                            />
                        </div>
                        <div className="additional-filter-1">
                            {AdditionalFilterUserLeads !== undefined && (
                                <>
                                    {/* <select value={selectedAdditionalFilter} className="form-control mt-0 mr-2" onChange={e => setSelectedAdditionalFilter(e.target.value)}>
                                        {AdditionalFilterUserLeads.optional === true && (
                                            <option value="">Select {AdditionalFilterUserLeads.label}</option>
                                        )}
                                        {AdditionalFilterUserLeads.options.map((option, index) => (
                                            <option value={option.value} key={index}>{option.label}</option>
                                        ))}
                                    </select> */}

                                    <div className="ew-section-2">
                                        <div className="text-field-empty-custom-user lead-select-1">
                                            <Select
                                                styles={customStyles}
                                                onChange={(selectedOption) => setSelectedAdditionalLeadFilter(selectedOption?.value || '')}
                                                value={AdditionalFilterUserLeads.options.find(option => option.value === selectedAdditionalLeadFilter) || null}
                                                options={AdditionalFilterUserLeads.options}

                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div >
                )}
                {
                    location.pathname === PageLinks.USER_TARGETS && (
                        <div className="card-body text-right pt-0 pr-0 pb-1" >
                            <DatePicker
                                value={dateMonthSet ? dayjs(dateMonth, 'MM-YY') : undefined}
                                onChange={handleMonthChange}
                                allowClear={false}
                                defaultValue={dayjs(dayjs().format('YYYY/MM'), 'YYYY/MM')}
                                format={'YYYY/MM'}
                                picker="month" />
                        </div>
                    )
                }
            </div >
            {
                location.pathname === PageLinks.USER_DASHBOARD_TODAY && (
                    <UserDashboardTodayTable setReloadData={setReloadData} />
                    // dateMonth={dateMonth}
                )
            }
            {
                location.pathname === PageLinks.USER_DASHBOARD_ETVBRL && (
                    <UserDashBoardETVBRLTable startDate={dateRangeFrom} endDate={dateRangeTo} />
                )
            }
            {
                location.pathname === PageLinks.USER_TARGETS && (
                    <UserDashboardTarget dateMonth={dateMonth} />
                )
            }
            {
                location.pathname === PageLinks.USER_ENQUIRY_TRANSFER && (
                    <UserDashboardET />
                )
            }
            {location.pathname === PageLinks.USER_MY_TASKS && (
                <UserTasks additionalFilter={selectedAdditionalTaskFilter} />
            )}
            {location.pathname === PageLinks.USER_MY_LEADS && (
                <UserLeads additionalFilter={selectedAdditionalLeadFilter} startDate={dateRangeFromLead} endDate={dateRangeToLead} />
            )}
            {location.pathname === PageLinks.USER_TESTDRIVE_DASHBOARD && (
                <UserTestdriveDashboard />
            )}
        </>
    )
}

export default UserDashboard;


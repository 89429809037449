import { Drawer, Tabs, Tab, Button } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { useAppStateAPI } from "../common/AppStateAPI";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import toast from "react-hot-toast";
import { ServerAPI } from "../common/ServerAPI";

interface InterestedVehicleEntryDialogProps {
    showDialog: boolean;
    closeDialog: () => void;
    reloadData: () => void;
    interestedUUID?: string
    id?: string;
    loadDetail?: boolean;
    detailView?: boolean;
    resetState: () => void
}

const InterestedVehicleEntryDialog: React.FC<InterestedVehicleEntryDialogProps> = ({ showDialog, closeDialog, reloadData, id, interestedUUID, detailView, resetState, loadDetail }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [initialValue, setInitialValue] = useState<APIData.InterestedVehicle | undefined>(InitialData.InterestedVehicle);
    const formikRef = useRef<FormikProps<APIData.InterestedVehicle>>(null);
    const [editMode, setEditMode] = useState(false);
    const [formDataSet, setFormDataSet] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const InterestedVehicleRef = useRef<HTMLDivElement | null>(null);
    const [selectedModelUUID, setSelectedModelUUID] = useState('');
    const [selectedVariantUUID, setSelectedVariantUUID] = useState('');
    const [selectedExteriorUUID, setSelectedExteriorUUID] = useState('');
    const [availableModelVariants, setAvailablemodelVariants] = useState<APIData.AvailableModelVariant[]>([]);
    const [formReRenderKey, setFormReRenderKey] = useState(Math.random());
    const currentYear = new Date().getFullYear();
    const last3Years = Array.from({ length: 4 }, (_, index) => currentYear - index);


    const modelYearOptions = last3Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));

    useEffect(() => {
        if (id !== undefined && id !== '' && interestedUUID !== undefined && interestedUUID !== "" && loadDetail) {
            addProcessingRequests();
            ServerAPI.getLeadInterestedVehicle(interestedUUID, id).then((response: any) => {
                if (response && response.data.details) {
                    const interestedVehicle = response.data.details;
                    //formikRef.current?.setFieldValue('vehicle_model_uuid', interestedVehicle.lead_model_uuid);
                    setInitialValue({
                        lead_uuid: interestedVehicle.lead_uuid,
                        vehicle_model_uuid: interestedVehicle.lead_model_uuid,
                        vehicle_variant_uuid: interestedVehicle.lead_variant_uuid,
                        vehicle_exterior_color_uuid: interestedVehicle.lead_exterior_uuid,
                        vehicle_interior_color_uuid: interestedVehicle.lead_interior_uuid,
                        vehicle_fuel_uuid: interestedVehicle.lead_fuel_uuid,
                        model_year: interestedVehicle.model_year + "",
                    })

                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id, interestedUUID, loadDetail]);

    // useEffect(() => {
    //     if (editMode) {
    //         setTimeout(() => {
    //             setReload(true)
    //         }, 3000);
    //     }

    // }, [editMode])

    const clearSelections = () => {
        setSelectedModelUUID("");
        setSelectedVariantUUID("");
        setSelectedExteriorUUID("");
        setInteriorColorOptions([]);
        setExteriorColorOptions([]);
        setVariantOptions([]);
        setFuelTypeOptions([]);
    };

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        setFormDataSet(true);

    }, []);

    useEffect(() => {
        if (showDialog === false) {
            closeDialog();
            resetState();
            clearSelections();
            setSelectedModelUUID("")
            setEditMode(false);
            // setReload(false)
            setInitialValue(InitialData.InterestedVehicle)
        }
    }, [showDialog]);

    useEffect(() => {
        setFormReRenderKey(Math.random());
    }, [initialValue]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getModelVariants(selectedModelUUID).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                setAvailablemodelVariants(response.data.list)
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedModelUUID]);

    useEffect(() => {
        if (availableModelVariants) {
            // setTimeout(() => {
            //     setSelectedModelUUID(availableModelVariants[0].model_uuid)
            //     formikRef.current?.setFieldValue('vehicle_mmodel_uuid', selectedModelUUID);
            // }, 3000);

            const modelVariants = availableModelVariants.map((item: any) => ({
                label: item.variant_name,
                value: item.uuid
            }));
            setVariantOptions(modelVariants);

            const selectedVariant = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariant) {
                const uniqueExteriorColors = Array.from(
                    new Map(selectedVariant.colors.map((color: any) => [color.exterior_colors_uuid, {
                        label: color.exterior_color_name,
                        value: color.exterior_colors_uuid
                    }])).values()
                );
                setExteriorColorOptions(uniqueExteriorColors);
            }

            // Filter interior colors based on selected variant and exterior color
            const selectedVariantWithColors = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariantWithColors) {
                const selectedExteriorColors = selectedVariantWithColors.colors.filter((color: any) => color.exterior_colors_uuid === selectedExteriorUUID);
                const uniqueInteriorColors = Array.from(
                    new Map(selectedExteriorColors.map((color: any) => [color.interior_color_uuid, {
                        label: color.interior_color_name,
                        value: color.interior_color_uuid
                    }])).values()
                );
                setInteriorColorOptions(uniqueInteriorColors);
            }

            // Filter fuel types based on selected variant
            const selectedVariantFuelType = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariantFuelType) {
                const fuelType = [{
                    label: selectedVariantFuelType.fuel_name,
                    value: selectedVariantFuelType.fuel_type_uuid
                }];
                setFuelTypeOptions(fuelType);
                formikRef.current?.setFieldValue('vehicle_fuel_uuid', selectedVariantFuelType.fuel_type_uuid);
            }
        }
    }, [selectedModelUUID, selectedExteriorUUID, selectedVariantUUID, availableModelVariants]);


    const yupSchema = Yup.object().shape({
        vehicle_model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        // vehicle_variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        // vehicle_exterior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        // vehicle_interior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        // vehicle_fuel_type: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="book-a-car-section drawer-min">
            {formDataSet && initialValue !== undefined && (
                <Formik
                    validationSchema={yupSchema} enableReinitialize={true} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                        let submitValues: any = { ...values };
                        submitValues['lead_uuid'] = id;
                        if (id != undefined && id != '' && interestedUUID !== "") {
                            submitValues['uuid'] = interestedUUID;
                            addProcessingRequests();
                            ServerAPI.updateLeadInterestedVehicle(submitValues).then(response => {
                                if (response && response['success'] && response['message']) {
                                    toast.success(response['message']);
                                    reloadData();
                                    closeDialog();
                                    resetState();
                                } else if (response && response['message']) {
                                    toast.error(response['message']);
                                }
                            }).finally(() => {
                                setSubmitting(false);
                                reduceProcessingRequests();
                            });
                        } else {
                            addProcessingRequests();
                            ServerAPI.addLeadInterestedVehicle(submitValues).then(response => {
                                if (response && response['success'] && response['message']) {
                                    toast.success(response['message']);
                                    reloadData();
                                    closeDialog();
                                    resetState();
                                } else if (response && response['message']) {
                                    toast.error(response['message']);
                                }
                            }).finally(() => {
                                setSubmitting(false);
                                reduceProcessingRequests();
                            });
                        }

                    }}>
                    {(formikProps) => {
                        if (formikProps.values.vehicle_model_uuid !== selectedModelUUID) {
                            setSelectedModelUUID(formikProps.values.vehicle_model_uuid);
                        }
                        if (formikProps.values.vehicle_exterior_color_uuid !== selectedExteriorUUID) {
                            setSelectedExteriorUUID(formikProps.values.vehicle_exterior_color_uuid);
                        }
                        if (formikProps.values.vehicle_variant_uuid !== selectedVariantUUID) {
                            setSelectedVariantUUID(formikProps.values.vehicle_variant_uuid);
                        }

                        return (
                            <Form key={formReRenderKey}>
                                <div className="tabdetails create-new-lead-section" ref={InterestedVehicleRef} >
                                    <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                        <h4 className="title-text text-center">{editMode ? "Edit" : "Add"} Interested Vehicle</h4>
                                        <div className="custom-border-grey"></div>
                                        <div className="tabpage">
                                            <div className="form-field-container-tabs">
                                                <div className="row  py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "vehicle_model_uuid", placeholder: "Select Model", options: modelOptions, required: true, }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "vehicle_variant_uuid", placeholder: "Select Variant", options: variantOptions, required: true, }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "vehicle_exterior_color_uuid", placeholder: "Select Exterior Color", options: exteriorColorOptions, required: true, }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "vehicle_interior_color_uuid", placeholder: "Select Interior Color", options: interiorColorOptions, required: true, }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Fuel Type", name: "vehicle_fuel_uuid", placeholder: "Select Fuel Type", options: fuelTypeOptions, required: true, }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model Year", name: "model_year", placeholder: "Select Model Year", options: modelYearOptions, required: true, }} />
                                                    </div>
                                                </div>
                                                <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                    <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                                    <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                        formikProps.submitForm();
                                                        if (!formikProps.isValid) {
                                                            toast.error("Please ensure all fields are Filled and Valid!");
                                                        }
                                                    }}>{editMode ? "Save Changes" : "Save"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer >
    )
}

export default InterestedVehicleEntryDialog;

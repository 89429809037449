import { Drawer, InputLabel } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import { API, Endpoints, TestDriveStatusValues, uploadImageType } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { date } from "yup";

const UserTestdriveEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean, closeFollowUp?: any }> = ({ showDialog, closeDialog, reloadData, id, detailView, closeFollowUp }) => {
    console.log(id, "testdrive popup id")
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [testdrivelocationOptions, setTestdrivelocationOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedTestdrivelocationOptions, setSelectedTestdrivelocationOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [demoCarOptions, setDemoCarOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [buttonText, setButtonText] = useState("Start Testdrive");
    const [mobileNumber, setMobileNumber] = useState("");
    const [step, setStep] = useState(0);
    const [homeAddress, setHomeAddress] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [testDriveLocationUUID, setTestDriveLocationUUID] = useState<string>("");
    const [DemoCarUUID, setDemoCarUUID] = useState<string>("");
    const [showroomUUID, setShowroomUUID] = useState<string>("");
    const [startReading, setStartReading] = useState<string>("");
    const [dlNumber, setDlNumber] = useState<string>("");
    const [leadID, setLeadID] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [testdriveStatus, setTestdriveStatus] = useState<number>();
    const [uploading, setUploading] = useState(false);
    const [getCustomerMail, setGetCustomerMail] = useState(false);
    const [getSelectedTestdriveLocation, setGetSelectedTestdriveLocation] = useState("");
    const [previewImage, setPreviewImage] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [receivedCustomerEmail, setReceivedCustomerEmail] = useState('');
    const [gatepassUrl, setGatepassUrl] = useState('');
    const [declarationUrl, setDeclarationUrl] = useState('');
    const [uploadedDlUrl, setUploadedDlUrl] = useState('');
    const [UploadedDlNumber, setUploadedDlNumber] = useState('');
    const [reloadTestdriveDetails, setReloadTestdriveDetails] = useState(false);
    const [changeFile, setChangeFile] = useState(false);
    const [sendGatepassStatus, setSendGatepassStatus] = useState<number>();
    const [cdMailStatus, setCdMailStatus] = useState<number>();
    const [cdSmsStatus, setCdSmsStatus] = useState<number>();
    const [cdWhatsup, setCdWhatsupStatus] = useState<number>();
    const [dlStatus, setDlStatus] = useState<number>();
    const [declarationStatus, setDeclarationStatus] = useState<number>();
    const [fieldPreviewImage, setFieldPreviewImage] = useState('');
    const [selectedDemocarUUID, setSelectedDemoCarUUID] = useState('');
    const [testdriveType, setTestdriveType] = useState<number>();
    const [testdriveDetails, setTestdriveDetails] = useState<APIData.TestDriveDetail>();
    const [imageType, setImageType] = useState<number>();
    const customTableViewRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
                setGetCustomerMail(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const uploadedFile = event.target.files[0];
            const imgType = imageType ? imageType : uploadImageType.DEMO_VEHICLE; // Replace 1 with your default image type if needed
            setUploading(true);

            ServerAPI.uploadImage(uploadedFile, imgType).then(response => {
                if (response.success && response.logo_url) {
                    setPreviewImage(response.full_logo_url);
                    setFieldPreviewImage(response.logo_url);
                    setChangeFile(false);
                } else if (response.message) {
                    toast.error(response.message);
                } else {
                    toast.error('Could not upload image!');
                }
            }).finally(() => {
                setUploading(false);
            });
        }
    };


    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) };
    console.log(sendGatepassStatus, "selectedDate")
    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTestDriveDetail(id ? id : "").then((response: any) => {
                if (response.data.details && response.success && response.message) {
                    // toast.success(response.message);
                    const testdriveDetails = response.data.details;
                    setTestdriveDetails(testdriveDetails);
                    setTestDriveLocationUUID(testdriveDetails.showroom_uuid);
                    setTestdriveStatus(testdriveDetails.status);
                    setSendGatepassStatus(testdriveDetails.gate_pass_status);
                    setDlStatus(testdriveDetails.driving_licence_status);
                    setDeclarationStatus(testdriveDetails.declaration_form_verified);
                    setCustomerEmail(testdriveDetails.email_id);
                    setCustomerNumber(testdriveDetails.mobile_number);
                    setUploadedDlNumber(testdriveDetails.driving_licence_number);
                    setSelectedDemoCarUUID(testdriveDetails.demo_car_uuid);
                    setUploadedDlUrl(testdriveDetails.driving_licence_image);
                    setTestdriveType(testdriveDetails.testdrive_type);
                    setGetSelectedTestdriveLocation(testdriveDetails.test_drive_location_name);
                    if (testdriveDetails.gate_pass_url) {
                        setGatepassUrl(testdriveDetails.gate_pass_url);
                    }
                    if (testdriveDetails.decleration_pdf_url) {
                        setDeclarationUrl(testdriveDetails.decleration_pdf_url);
                    }
                    testdriveDetails.declaration_form_type.forEach((form: { type: string, status: number }) => {
                        if (form.type === "Email") {
                            setCdMailStatus(form.status);
                        } else if (form.type === "SMS") {
                            setCdSmsStatus(form.status);
                        } else if (form.type === "Whatsapp") {
                            setCdWhatsupStatus(form.status);
                        }
                    });
                } else if (response && response.message) {
                    toast.error(response.message);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id, reloadTestdriveDetails]);


    const handleChange = (date: any) => {
        let formattedDateTime = "";
        if (date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const timeDifference = date.getTime() - currentDate.getTime();

            if (timeDifference > 600000) {
                setButtonText("Schedule Testdrive");
            } else {
                setButtonText("Next");
            }
        }
        setSelectedDate(formattedDateTime);
        console.log('Selected Date:', formattedDateTime);
    };


    useEffect(() => {
        if (testdrivelocationOptions) {
            const selectedTestdrive = testdrivelocationOptions.filter((testdrive) => testdrive.value === testDriveLocationUUID)
            setSelectedTestdrivelocationOptions(selectedTestdrive)
            console.log(selectedTestdrive, "selectedtestdrive")
        }
    }, [testDriveLocationUUID])

    const handleGatePassPreview = () => {
        window.open(gatepassUrl, '_blank');
    };
    const handleddeclarationPreview = () => {
        window.open(declarationUrl, '_blank');
    };

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["showroom_uuid"] = showroomUUID;
        addProcessingRequests();
        ServerAPI.executeAPI(Endpoints.UserDemoCarList, ServerAPI.APIMethod.GET, true, null, params).then(response => {
            if (response && response.data && response.data.demo_car) {
                const demoCarList = response.data.demo_car.map((car: any) => ({
                    label: car.model_name,
                    value: car.uuid,
                }));
                setDemoCarOptions(demoCarList);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [showroomUUID]);

    useEffect(() => {
        getDataFromAPI(API.EndPoint.LeadTestDriveLocation, setTestdrivelocationOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'test_drive_location_name', 'data');
        // getDataFromAPI(API.EndPoint.UserDemoCarList, setDemoCarOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'model_name', 'data', 'demo_car');
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'showroom_name', 'data', 'list');
    }, [])

    const handleTestdriveLocationChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setTestDriveLocationUUID(selectedOption?.value || "");
    };

    const handleDemoCarChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setDemoCarUUID(selectedOption?.value || "");
    };
    const handleShowroomChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setShowroomUUID(selectedOption?.value || "");
    };

    const validateForm = () => {
        if (!testDriveLocationUUID) {
            toast.error("Test Drive Location is required");
            return false;
        }
        if (!customerName) {
            toast.error("Customer Name is required");
            return false;
        }
        if (!mobileNumber.match(/^[0-9]{10}$/)) {
            toast.error("Mobile Number is not valid");
            return false;
        }
        if (!leadID) {
            toast.error("Lead ID is required");
            return false;
        }
        if (!showroomUUID) {
            toast.error("Showroom is required");
            return false;
        }
        if (selectedTestdrivelocationOptions[0] && selectedTestdrivelocationOptions[0].label === "Home") {
            if (!homeAddress) {
                toast.error("Address is required");
                return false;
            }
        }
        if (!DemoCarUUID) {
            toast.error("Demo Car is required");
            return false;
        }
        if (!selectedDate) {
            toast.error("Date is required");
            return false;
        }
        return true;
    };

    const scheduleTestdrive = () => {
        if (!validateForm()) return;
        if (buttonText === "Schedule Testdrive") {
            addProcessingRequests();
            ServerAPI.scheduleTestdrive(testDriveLocationUUID, DemoCarUUID, customerName, mobileNumber, parseInt(leadID), homeAddress, showroomUUID, selectedDate ? selectedDate : "").then(response => {
                if (response && response.success && response.message) {
                    toast.success(response.message);
                    reloadData();
                    closeDialog();
                    closeFollowUp();
                } else if (response && response.message) {
                    toast.error(response.message);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    };

    // const proceedToStartTestdrive = () => {
    //     if (!validateForm()) return;
    //     addProcessingRequests();
    //     ServerAPI.proceedToStartTestdrive(testDriveLocationUUID, DemoCarUUID, customerName, mobileNumber, parseInt(leadID), homeAddress, showroomUUID, selectedDate ? selectedDate : "", previewImage, startReading).then((response: any) => {
    //         if (response && response.success && response.message) {
    //             toast.success(response.message);
    //             reloadData();
    //             setStep(2);
    //         } else if (response && response.message) {
    //             toast.error(response.message);
    //         }
    //     }).finally(() => {
    //         reduceProcessingRequests();
    //     });
    // };

    const testdriveStart = () => {
        addProcessingRequests();
        ServerAPI.testdriveStart(id ? id : "", fieldPreviewImage, startReading, selectedDemocarUUID).then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                setPreviewImage("");
                setFieldPreviewImage("")
                closeDialog();
                reloadData();
                closeFollowUp();
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    };

    const testdriveComplete = () => {
        addProcessingRequests();
        ServerAPI.testdriveComplete(id ? id : "", fieldPreviewImage, startReading, selectedDate ? selectedDate : "").then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                closeDialog();
                reloadData();
                closeFollowUp();
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    };

    const uploadDrivingLicence = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        addProcessingRequests();
        ServerAPI.uploadDrivingLicence(id ? id : "", fieldPreviewImage, dlNumber).then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                setReloadTestdriveDetails(!reloadTestdriveDetails);
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const sendGatepass = (id: string, sendType: number) => {
        addProcessingRequests();
        ServerAPI.sendGatepass(id ? id : "", sendType).then((response: any) => {
            if (response && response.success && response.message) {
                console.log("updated gatepass")
                toast.success(response.message);
                setReloadTestdriveDetails(!reloadTestdriveDetails);
            } else if (response && response.message) {
                toast.error(response.message);
                console.log("updated not gatepass")
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const sendDeclarationForm = (id: string, sendType: number, email?: string, mobile?: string) => {
        addProcessingRequests();
        ServerAPI.sentDeclarationForm(id ? id : "", sendType, email, mobile).then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                setReloadTestdriveDetails(!reloadTestdriveDetails);
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const handleSubmitPage1 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (step === 0) {
            if (!validateForm()) return;
        }
        if (selectedTestdrivelocationOptions[0] && selectedTestdrivelocationOptions[0].label === "Showroom" && buttonText !== "Schedule Testdrive") {
            setStep(2);
        } else if (selectedTestdrivelocationOptions[0] && selectedTestdrivelocationOptions[0].label === "Home" && buttonText !== "Schedule Testdrive") {
            setStep(1);
        } else if (buttonText === "Schedule Testdrive") {
            scheduleTestdrive();
        }
    };



    const handleSubmitPage2 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.SCHEDULED) {
            testdriveStart();
        } else if (testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.ON_GOING) {
            testdriveComplete();
        }
    };

    const sendDeclarationEmailOTP = (id: string, email: string) => {
        addProcessingRequests();
        ServerAPI.sentDeclarationEmailOTP(id ? id : "", email).then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const renderStep = () => {
        if (step === 1 || (getSelectedTestdriveLocation === "Home" && testdriveStatus?.toString() === TestDriveStatusValues.SCHEDULED) || (getSelectedTestdriveLocation === "Showroom" && testdriveStatus?.toString() === TestDriveStatusValues.ON_GOING)) {
            return 1;
        }
        if (step === 2 || (getSelectedTestdriveLocation === "Showroom" && testdriveStatus?.toString() === TestDriveStatusValues.SCHEDULED) || (getSelectedTestdriveLocation === "Home" && testdriveStatus?.toString() === TestDriveStatusValues.ON_GOING)) {
            return 2;
        }
        return null;
    };

    const currentStep = renderStep();
    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <div className="tabdetails" style={testdriveStatus?.toString() === TestDriveStatusValues.COMPLETED ? { overflowY: "auto", height: "100vh", overflowX: "hidden" } : { overflowY: "auto", height: "89vh", overflowX: "hidden" }}>
                {step === 0 && testdriveStatus === undefined && <> <h4 className="title-text text-center ml-5">{step === 0 ? "Add New Testdrive" : "Testdrive Details"}</h4>
                    <div className="custom-border-grey"></div>
                    <div className="tabpage">
                        <form onSubmit={handleSubmitPage1}>
                            <div className="tabdetails create-new-lead-section">
                                <div className="tabdetails-1" >
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs">
                                            <div className="row py-3 px-2">
                                                <div className="form-group-1 text-left mb-4 mt-2 col-lg-12 col-md-12 col-xl-12">
                                                    <div className="ew-section-2">
                                                        <div className="text-field-empty-custom-user lead-select">
                                                            <label className='label-custom'>Test Drive Location</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleTestdriveLocationChange}
                                                                value={testdrivelocationOptions.find(option => option.value === testDriveLocationUUID) || null}
                                                                options={testdrivelocationOptions}
                                                                placeholder={"Select Testdrive Location"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-1 text-left mb-4 mt-2 col-lg-12 col-md-12 col-xl-12">
                                                    <div className="ew-section-2">
                                                        <div className="text-field-empty-custom-user lead-select">
                                                            <label className='label-custom'>Showroom Name</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleShowroomChange}
                                                                value={showroomOptions.find(option => option.value === showroomUUID) || null}
                                                                options={showroomOptions}
                                                                placeholder={"Select Showroom"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-1 text-left mb-4 mt-2 col-lg-12 col-md-12 col-xl-12">
                                                    <div className="ew-section-2">
                                                        <div className="text-field-empty-custom-user lead-select">
                                                            <label className='label-custom'>Demo Car</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleDemoCarChange}
                                                                value={demoCarOptions.find(option => option.value === DemoCarUUID) || null}
                                                                options={demoCarOptions}
                                                                placeholder={"Select Demo car"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedTestdrivelocationOptions[0] && selectedTestdrivelocationOptions[0].label === "Home" && <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4 mt-2"><div className="input-group">
                                                    <label className='label-custom'>Home Address</label>
                                                    <input type="text" className="form-control" placeholder="Enter Home Address" value={homeAddress} onChange={(e) => setHomeAddress(e.target.value)} />
                                                </div>
                                                </div>}
                                                <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4 mt-2">
                                                    <div className="input-group">
                                                        <label className='label-custom'>Customer Name</label>
                                                        <input type="text" className="form-control" placeholder="Enter Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4 mt-2">
                                                    <div className="input-group">
                                                        <label className='label-custom'>Mobile Number</label>
                                                        <input type="text" className="form-control" placeholder="Enter Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4 mt-2">
                                                    <div className="input-group">
                                                        <label className='label-custom'>DMS Lead ID</label>
                                                        <input type="text" className="form-control" placeholder="Enter Lead ID" value={leadID} onChange={(e) => setLeadID(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-xl-12 mb-4 mt-2 text-field-empty-custom-user-1 book-final">
                                                    <div className='mb-24'>
                                                        <label className="label-custom">Date & Time <span className="text-danger me-4">*</span></label>
                                                        <DatePicker
                                                            value={selectedDate}
                                                            selected={selectedDate ? new Date(selectedDate) : null}
                                                            onChange={handleChange}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            showTimeSelect
                                                            placeholderText={"Select Date"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                <button type="submit" className="button2">{buttonText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div></>}
                {/* {(step === 1 || (getSelectedTestdriveLocation === "Home" && testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.SCHEDULED) || (getSelectedTestdriveLocation === "Showroom" && testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.ON_GOING)) && <> <h4 className="title-text text-center ml-5">Testdrive Details</h4> */}
                {currentStep === 1 && <> <h4 className="title-text text-center ml-5">Testdrive Details</h4>
                    <div className="custom-border-grey"></div>
                    <div className="tabpage mt-3">
                        <form onSubmit={handleSubmitPage2}>
                            {/* <div className="tabdetails create-new-lead-section">
                                <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                    <div className="tabpage"> */}
                            <div className="form-field-container-tabs">
                                {testdriveDetails && (<>
                                    <div className='table-container pl-2 pr-2'>
                                        <div className='table-scroll'>
                                            <div className='table-responsive'>
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "auto" }}>
                                                        <p className="m-0" style={{ fontSize: "18px", color: "#14408B" }}>Demo Car Details</p>
                                                    </div>
                                                    <div className='row user-leads-second d-flex' style={{ backgroundColor: "#fff" }}>
                                                        <div className="col-4">
                                                            <img src={testdriveDetails.car_image_url} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                                        </div>
                                                        <div className="col-8">
                                                            <p className='mb-0 userlead-numval'>{`${testdriveDetails.model_name} - ${testdriveDetails.variant_name}`}</p>
                                                            <p className='mb-0 userlead-numval'>{`${testdriveDetails.color_name} - ${testdriveDetails.fuel_name}`}</p>
                                                            <p className='mb-0 userlead-numval'>{`${testdriveDetails.car_tc_number} - ${testdriveDetails.car_vin_number}`}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                                <div className="col-lg-12 col-md-12 col-xl-12 mb-4 pl-3">
                                    <div className="mb-24">
                                        <InputLabel className='form-upload-label' style={{ fontSize: "12px", color: "rgba(37, 37, 37, 0.5)" }}>Odometer Image</InputLabel>
                                        {uploading && (
                                            <img src={`${window.location.origin}/assets/images/uploading.gif`} style={{ maxWidth: '50px', maxHeight: '50px', marginRight: '10px' }} alt="Uploading" />
                                        )}
                                        {!uploading && (previewImage || fieldPreviewImage) && !changeFile && (
                                            <>
                                                <img src={previewImage || fieldPreviewImage} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} alt="Preview" />
                                                <a href="#" onClick={(e) => { e.preventDefault(); setChangeFile(true); }}><i className="fa fa-edit text-info font-16" aria-hidden="true"></i></a>
                                            </>
                                        )}
                                        {!uploading && ((!previewImage && !fieldPreviewImage) || changeFile) && (
                                            <input type="file" className="form-file-upload" placeholder="Enter Odometer Image" onChange={handleFileChange} />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4">
                                    <div className="input-group">
                                        <label className='label-custom'>Odometer Reading</label>
                                        <input type="text" className="form-control" placeholder="Enter Odometer Reading" value={startReading} onChange={(e) => setStartReading(e.target.value)} />
                                    </div>
                                </div>
                                {(testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.ON_GOING) && testdriveType === 1 && <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user-1 book-final">
                                    <div className='mb-24'>
                                        <label className="label-custom">Date & Time <span className="text-danger me-4">*</span></label>
                                        <DatePicker
                                            value={selectedDate}
                                            selected={selectedDate ? new Date(selectedDate) : null}
                                            onChange={handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            showTimeSelect
                                            placeholderText={"Select Date"}
                                        />
                                    </div>
                                </div>}
                                {testdriveDetails && (
                                    <div className='table-container pl-2 pr-2'>
                                        <div className='table-scroll'>
                                            <div className='table-responsive'>
                                                <div style={{ height: "auto" }}>
                                                    <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                        <p className="mt-2 mb-0" style={{ fontSize: "18px", color: "#14408B" }}>Customer Details</p>
                                                    </div>
                                                    <div className='row user-leads-second' style={{ backgroundColor: "#fff" }}>
                                                        <div className='row user-lead-details mb-1 flex-wrap flex-row m-1' style={{ gap: "10px" }}>
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Customer Name</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.customer_name}</p>
                                                            </div>
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Mobile Number</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.mobile_number}</p>
                                                            </div>
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Model</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.model_name}</p>
                                                            </div>
                                                            {testdriveDetails.testdrive_type === 1 &&
                                                                <div className='leads-table-content' style={{ width: "48%" }}>
                                                                    <p className='mb-0 userlead-numkey'>Testdrive Home Address</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.test_drive_address}</p>
                                                                </div>
                                                            }
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Showroom Name</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.showroom_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                    <button type="submit" className="button2" style={{ width: "auto" }}>{testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.ON_GOING ? "Finish Testdrive" : "Proceed to Testdrive"}</button>
                                </div>
                            </div>
                        </form>
                    </div ></>}
                {/* {(step === 2 || (getSelectedTestdriveLocation === "Showroom" && testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.SCHEDULED) || (getSelectedTestdriveLocation === "Home" && testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.ON_GOING)) && <> <h4 className="title-text text-center ml-5">Testdrive Details</h4> */}
                {
                    currentStep === 2 && <> <h4 className="title-text text-center ml-5">Testdrive Details</h4>
                        <div className="custom-border-grey"></div>
                        <div className="tabpage mt-3">
                            <form onSubmit={uploadDrivingLicence}>
                                <div className="form-field-container-tabs">
                                    {testdriveDetails && (
                                        <div className='table-container pl-2 pr-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive'>
                                                    <div style={{ height: "auto" }}>
                                                        <div className="d-flex justify-content-between align-items-center p-3" style={{ height: "43px" }}>
                                                            <p className="mt-2" style={{ fontSize: "18px", color: "#14408B" }}>Demo Car & Customer Details</p>
                                                        </div>
                                                        <div className='row user-leads-second pl-2' style={{ gap: "10px" }}>
                                                            <div className='user-lead-details mb-1' style={{ width: "48%" }}>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Customer Name</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.customer_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className='user-lead-details mb-1' style={{ width: "48%" }}>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Mobile Number</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.mobile_number}</p>
                                                                </div>
                                                            </div>
                                                            <div className='user-lead-details' style={{ width: "48%" }}>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Model</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.model_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className='user-lead-details' style={{ width: "48%" }}>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Tc Number</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.car_tc_number}</p>
                                                                </div>
                                                            </div>
                                                            {testdriveDetails.testdrive_type === 1 && <div className='user-lead-details' style={{ width: "48%" }}>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Testdrive Home Address</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.test_drive_address}</p>
                                                                </div>
                                                            </div>}
                                                            <div className='user-lead-details' style={{ width: "48%" }}>
                                                                <div className='leads-table-content'>
                                                                    <p className='mb-0 userlead-numkey'>Showroom Name</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.showroom_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='table-container pl-2 pr-2'>
                                        <div className='table-scroll'>
                                            <div className='table-responsive'>
                                                <div style={{ height: "86px" }}>
                                                    <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                        <p className="mt-2" style={{ fontSize: "18px", color: "#14408B" }}>Gate Pass</p>
                                                        {sendGatepassStatus === 1 ? <button style={{ backgroundColor: "#F88906", padding: "5px", color: "#fff", border: "none", borderRadius: "4px" }} onClick={handleGatePassPreview}>Preview <i className="fa-regular fa-eye" style={{ color: "#fff" }}></i></button> : ""}
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                        <div className='d-flex align-items-center justify-content-center w-10'><p className='user-lead-call'>Sent Gatepass</p></div>
                                                        <div className="d-flex flex-row" style={{ cursor: "pointer" }}>
                                                            <img className="mr-1" src={(sendGatepassStatus === 0 || sendGatepassStatus === undefined) ? window.location.origin + "/assets/svg/testdrive-mail.svg" : window.location.origin + "/assets/svg/testdrive-mail-success.svg"} alt="" onClick={() => sendGatepass(id ? id : "", 1)} />
                                                            {/* <img src={sendGatepassSuccess ? window.location.origin + "/assets/svg/testdrive-whatsapp.svg" : window.location.origin + "/assets/svg/testdrive-whatapp-success.svg"} alt="" onClick={() => sendGatepass(id ? id : "", 2)} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 pl-3">
                                        <div className="mb-24">
                                            <InputLabel className='form-upload-label' style={{ fontSize: "12px", color: "rgba(37, 37, 37, 0.5)" }}>Driving Licence</InputLabel>
                                            {uploading && (
                                                <img src={`${window.location.origin}/assets/images/uploading.gif`} style={{ maxWidth: '50px', maxHeight: '50px', marginRight: '10px' }} alt="Uploading" />
                                            )}
                                            {uploadedDlUrl && (
                                                <>
                                                    <img src={uploadedDlUrl} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} alt="Uploaded" />
                                                    <a href="#" onClick={(e) => { e.preventDefault(); setChangeFile(true); setUploadedDlUrl("") }}><i className="fa fa-edit text-info font-16" aria-hidden="true"></i></a>
                                                </>
                                            )}
                                            {!uploadedDlUrl && !uploading && (previewImage || fieldPreviewImage) && !changeFile && (
                                                <>
                                                    <img src={previewImage || fieldPreviewImage} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} alt="Preview" />
                                                    <a href="#" onClick={(e) => { e.preventDefault(); setChangeFile(true); }}><i className="fa fa-edit text-info font-16" aria-hidden="true"></i></a>
                                                </>
                                            )}
                                            {!uploadedDlUrl && !uploading && ((!previewImage && !fieldPreviewImage) || changeFile) && (
                                                <input type="file" className="form-file-upload" placeholder="Enter Odometer Image" onChange={handleFileChange} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4">
                                        <div className="input-group">
                                            <label className='label-custom'>Driving Licence Number</label>
                                            <input type="text" className="form-control" placeholder="Enter DL Number" value={UploadedDlNumber ? UploadedDlNumber : dlNumber} onChange={(e) => setDlNumber(e.target.value)} />
                                        </div>
                                    </div>

                                    {dlStatus === 1 ? <>
                                        <div className='table-container pl-2 pr-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive'>
                                                    <div style={{ height: "86px" }}>
                                                        <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                            <p className="mt-2" style={{ fontSize: "18px", color: "#14408B" }}>Customer Declaration Form</p>
                                                            {declarationStatus === 1 && <button style={{ backgroundColor: "#F88906", padding: "5px", color: "#fff", border: "none", borderRadius: "4px" }} onClick={handleddeclarationPreview}>Preview <i className="fa-regular fa-eye" style={{ color: "#fff" }}></i></button>}
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                            <div className='d-flex align-items-center justify-content-center w-10'><p className='user-lead-call'>Otp Verification Done</p></div>
                                                            <div className="d-flex flex-row" style={{ cursor: "pointer" }}>
                                                                <img className="mr-1" src={(cdMailStatus === 0 || cdMailStatus === undefined) ? window.location.origin + "/assets/svg/testdrive-mail.svg" : window.location.origin + "/assets/svg/testdrive-mail-success.svg"} alt="" onClick={() => { customerEmail ? sendDeclarationForm(id ? id : "", 1, customerEmail) : setGetCustomerMail(true) }} />
                                                                <img className="mr-1" src={(cdSmsStatus === 0 || cdSmsStatus === undefined) ? window.location.origin + "/assets/svg/testdrive-sms.svg" : window.location.origin + "/assets/svg/testdrive-sms-success.svg"} alt="" onClick={() => sendDeclarationForm(id ? id : "", 2, "", customerNumber)} />
                                                                <img src={(cdWhatsup === 0 || cdWhatsup === undefined) ? window.location.origin + "/assets/svg/testdrive-whatsapp.svg" : window.location.origin + "/assets/svg/testdrive-whatapp-success.svg"} alt="" onClick={() => sendDeclarationForm(id ? id : "", 3, "", customerNumber)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='table-container pl-2 pr-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive' >
                                                    <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                        <p className="mt-2" style={{ fontSize: "18px", color: "#14408B" }}>Testdrive Checklist</p>
                                                        <i className="fa-solid fa-rotate font-16 " style={{ color: "#000", cursor: "pointer" }} onClick={() => setReloadTestdriveDetails(!reloadTestdriveDetails)}></i>
                                                    </div>
                                                    <div style={{ height: "auto" }}>
                                                        <div className="d-flex justify-content-between align-items-center px-2 py-1">
                                                            <p className='mb-0 userlead-numkey'>Sent Gate Pass</p>
                                                            <input type="radio" checked={sendGatepassStatus === 1} readOnly />
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center px-2 py-1">
                                                            <p className='mb-0 userlead-numkey'>Upload Driving Licence</p>
                                                            <input type="radio" checked={dlStatus === 1} readOnly />
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center px-2 py-1">
                                                            <p className='mb-0 userlead-numkey'>Signed Declaration Form</p>
                                                            <input type="radio" checked={declarationStatus === 1} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </> : ""}
                                    {(dlStatus === 0 || dlStatus === undefined) ? <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                        <button type="submit" className="button2">Upload</button>
                                    </div> : <div className="row btn-form-submit mb-2" style={{ position: "absolute" }}>
                                        <button type="button" className="button2" onClick={() => { setStep(1); setPreviewImage(""); setFieldPreviewImage("") }} disabled={declarationStatus === 0 || dlStatus === 0 || sendGatepassStatus === 0}>Next</button>
                                        {/* disabled={declarationStatus === 0 || dlStatus === 0 || sendGatepassStatus === 0} */}
                                    </div>}
                                </div>
                            </form>
                        </div></>
                }
                {
                    testdriveStatus?.toString() === TestDriveStatusValues.COMPLETED && (<><h4 className="title-text text-center ml-5">Testdrive Details</h4>
                        <div className="custom-border-grey"></div>
                        <div className="tabpage mt-3">
                            {testdriveDetails && (
                                <>
                                    <div className='table-container pl-2 pr-2'>
                                        <div className='table-scroll'>
                                            <div className='table-responsive'>
                                                <div style={{ height: "auto" }}>
                                                    <div className="d-flex justify-content-between align-items-center p-3" style={{ height: "43px" }}>
                                                        <p className="mt-2" style={{ fontSize: "18px", color: "#14408B" }}>Demo Car & Customer Details</p>
                                                    </div>
                                                    <div className='row user-leads-second pl-2' style={{ gap: "10px" }}>
                                                        <div className='user-lead-details mb-1' style={{ width: "48%" }}>
                                                            <div className='leads-table-content'>
                                                                <p className='mb-0 userlead-numkey'>Customer Name</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.customer_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className='user-lead-details mb-1' style={{ width: "48%" }}>
                                                            <div className='leads-table-content'>
                                                                <p className='mb-0 userlead-numkey'>Mobile Number</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.mobile_number}</p>
                                                            </div>
                                                        </div>
                                                        <div className='user-lead-details' style={{ width: "48%" }}>
                                                            <div className='leads-table-content'>
                                                                <p className='mb-0 userlead-numkey'>Model</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.model_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className='user-lead-details' style={{ width: "48%" }}>
                                                            <div className='leads-table-content'>
                                                                <p className='mb-0 userlead-numkey'>Tc Number</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.car_tc_number}</p>
                                                            </div>
                                                        </div>
                                                        {testdriveDetails.testdrive_type === 1 && <div className='user-lead-details' style={{ width: "48%" }}>
                                                            <div className='leads-table-content'>
                                                                <p className='mb-0 userlead-numkey'>Testdrive Home Address</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.test_drive_address}</p>
                                                            </div>
                                                        </div>}
                                                        <div className='user-lead-details' style={{ width: "48%" }}>
                                                            <div className='leads-table-content'>
                                                                <p className='mb-0 userlead-numkey'>Showroom Name</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.showroom_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="completed-details w-100">
                                        <div className='table-container pl-2 pr-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive p-2'>
                                                    <div className="d-flex align-items-center justify-content-around">
                                                        <div className="col-6"> <img src={testdriveDetails.starting_image_url} alt="" style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }} /></div>
                                                        <div className="col-6"><img src={testdriveDetails.ending_image_url} alt="" style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ml-2 text-left mb-4 mt-2 lead-select">
                                            <div className="input-group">
                                                <label className='label-custom' style={{ left: "11px" }}>Start Odometer Reading</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="Start Odometer Reading"
                                                    value={testdriveDetails.starting_odometer_reading}
                                                    style={{ height: "47px", borderRadius: "8px", backgroundColor: "#ddd" }}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ml-2 text-left mb-2 mt-2 lead-select">
                                            <div className="input-group">
                                                <label className='label-custom' style={{ left: "11px" }}>End Odometer Reading</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="End Odometer Reading"
                                                    value={testdriveDetails.ending_odometer_reading}
                                                    style={{ height: "47px", borderRadius: "8px", backgroundColor: "#ddd" }}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className='table-container pl-2 pr-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive p-2'>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div className="col-6"> <img src={testdriveDetails.driving_licence_image} alt="" style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ml-2 text-left mb-4 mt-2 lead-select">
                                            <div className="input-group">
                                                <label className='label-custom' style={{ left: "11px" }}>Driving Licence Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="Driving Licence Number"
                                                    value={testdriveDetails.driving_licence_number}
                                                    style={{ height: "47px", borderRadius: "8px", backgroundColor: "#ddd" }}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className='table-container pl-2 pr-2 mt-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive'>
                                                    <div style={{ height: "43px" }}>
                                                        <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                            <p className="mb-0" style={{ fontSize: "18px", color: "#14408B" }}>Gate Pass</p>
                                                            {sendGatepassStatus === 1 ? <button style={{ backgroundColor: "#F88906", padding: "5px", color: "#fff", border: "none", borderRadius: "4px" }} onClick={handleGatePassPreview}>Preview <i className="fa-regular fa-eye" style={{ color: "#fff" }}></i></button> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='table-container pl-2 pr-2'>
                                            <div className='table-scroll'>
                                                <div className='table-responsive'>
                                                    <div style={{ height: "43px" }}>
                                                        <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                            <p className="mb-0" style={{ fontSize: "18px", color: "#14408B" }}>Customer Declaration Form</p>
                                                            {declarationStatus === 1 && <button style={{ backgroundColor: "#F88906", padding: "5px", color: "#fff", border: "none", borderRadius: "4px" }} onClick={handleddeclarationPreview}>Preview <i className="fa-regular fa-eye" style={{ color: "#fff" }}></i></button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='table-container pl-2 pr-2'>
                                        <div className='table-scroll'>
                                            <div className='table-responsive'>
                                                <div style={{ height: "auto" }}>
                                                    <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                        <p className="mt-2 mb-0" style={{ fontSize: "18px", color: "#14408B" }}>Customer Details</p>
                                                    </div>
                                                    <div className='row user-leads-second' style={{ backgroundColor: "#fff" }}>
                                                        <div className='row user-lead-details mb-1 flex-wrap flex-row m-1' style={{ gap: "10px" }}>
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Customer Name</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.customer_name}</p>
                                                            </div>
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Mobile Number</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.mobile_number}</p>
                                                            </div>
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Model</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.model_name}</p>
                                                            </div>
                                                            {testdriveDetails.testdrive_type === 1 &&
                                                                <div className='leads-table-content' style={{ width: "48%" }}>
                                                                    <p className='mb-0 userlead-numkey'>Testdrive Home Address</p>
                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.test_drive_address}</p>
                                                                </div>
                                                            }
                                                            <div className='leads-table-content' style={{ width: "48%" }}>
                                                                <p className='mb-0 userlead-numkey'>Showroom Name</p>
                                                                <p className='mb-0 userlead-numval'>{testdriveDetails.showroom_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                        </div></>)}

                {/* {testdriveStatus?.toString() === TestDriveStatusValues.CANCELLED && <h4 className="text-center">Testdrive is already Cancelled.</h4>} */}
                {
                    testdriveStatus?.toString() === TestDriveStatusValues.CANCELLED && (
                        <div className='custom-tablescroll-1'>
                            <div className="table-container">
                                <div className="table-scroll">
                                    <div className="table-responsive">
                                        <div className="cancel-notice-container">
                                            <div className="cancel-notice">
                                                <h4 className="cancel-notice-title">Test Drive Cancelled</h4>
                                                <p className="cancel-notice-message">The test drive you scheduled has already been cancelled. If you have any questions or need further assistance, please contact our support team.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >

            {getCustomerMail && <div className='custom-tableheight-1' ref={customTableViewRef}>
                <div className="p-3">
                    <p className="mt-2 text-center" style={{ fontSize: "18px", color: "#14408B" }}>Enter Email to Receive OTP</p>
                    <div className="row">
                        <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-3">
                            <div className="input-group">
                                <label className='label-custom'>Email ID *</label>
                                <input type="text" className="form-control" placeholder="Enter Email" value={receivedCustomerEmail} onChange={(e) => setReceivedCustomerEmail(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row btn-form-submit mb-2 w-100" style={{ position: "relative" }}>
                        <button className="button2 w-100" onClick={() => sendDeclarationEmailOTP(id ? id : "", receivedCustomerEmail)}>Send</button>
                    </div>
                </div>
            </div>}
        </Drawer >
    );
};

export default UserTestdriveEntryDialog;

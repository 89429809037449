import React from 'react';
import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const CampaignEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {
    console.log(id, "campaign")
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.DealerCampaign>>(null);
    const [initialValue, setInitialValue] = useState<APIData.DealerCampaign>(InitialData.DealerCampaign);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [LeadSourceOptions, setLeadSourceOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedLeadCategoryUUID, setSelectedLeadCategoryUUID] = useState('');
    const [LeadCatedgoryOptions, setLeadCatedgoryOptions] = useState<FormDataTypes.SelectOption[]>([]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'showroom_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadSourceCategoryList, setLeadCatedgoryOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'lead_source_category_name', 'data');

    }, []);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        if (selectedLeadCategoryUUID) {
            params['lead_source_cat_uuid'] = selectedLeadCategoryUUID;
        }
        getDataFromAPI(API.EndPoint.LeadSourceList, setLeadSourceOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_source_name', 'data', 'list');
    }, [selectedLeadCategoryUUID]);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.dealerCampaignDetail(id).then(response => {
                if (response && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        lead_source_category_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        lead_source_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        showroom_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        campaign_name: Yup.string().required(ProjectStrings.ValidationRequired),
        start_date: Yup.string().required(ProjectStrings.ValidationRequired),
        end_date: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        submitValues["uuid"] = id;
                        addProcessingRequests();
                        ServerAPI.dealerCampaignUpdate(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.dealerCampaignAdd(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.lead_source_category_uuid !== selectedLeadCategoryUUID) {
                            setSelectedLeadCategoryUUID(formikProps.values.lead_source_category_uuid);
                        }
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Campaign</h4>
                                <div className="custom-border-grey my-2"></div>
                                <div className="tabpage">
                                    <Form>
                                        <div className="px-2 form-field-container">
                                            <div className="row px-2 py-3">
                                                <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Showroom", name: "showroom_uuid", placeholder: "Showroom", required: true, options: showroomOptions }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: " Lead Source Category", name: "lead_source_category_uuid", placeholder: "Select Lead Source Category ", options: LeadCatedgoryOptions, required: true, }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: " Lead Source", name: "lead_source_uuid", placeholder: "Select Lead Source", options: LeadSourceOptions, required: true, }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "campaign_name", label: "Campaign Name", placeholder: "Enter Campaign Name", required: true }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", name: "start_date", label: "Start Date", placeholder: "Select Start Date", required: true, allowFutureDate: true }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", name: "end_date", label: "End Date", placeholder: "Select End Date", required: true, allowFutureDate: true }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row btn-form-submit">
                                            <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                formikProps.submitForm();
                                                if (!formikProps.isValid) {
                                                    toast.error("Please ensure all fields are Filled and Valid!");
                                                }
                                            }}>Submit</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
};

export default CampaignEntryDialog;
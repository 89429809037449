import { Drawer, Tabs, Tab, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, EndUser, ProjectStrings, uploadImageType } from "../common/Constants";
import { getDataFromAPI, getLoginUserType } from "../common/Utilities";

const DealerEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean, fromDealerLogin?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView, fromDealerLogin }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [currentTab, setCurrentTab] = React.useState(0)
    const formikRef = useRef<FormikProps<APIData.Dealer>>(null);
    const [initialValue, setInitialValue] = useState<APIData.Dealer>(InitialData.Dealer);
    const [formDataSet, setFormDataSet] = useState(false);
    const [companyOptions, setCompanyOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [menus, setMenus] = useState<APIData.Menu[]>([]);
    const [selectedMenus, setSelectedMenus] = useState<string[]>([]);
    const [providers, setProviders] = useState<APIData.Provider[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
    const [previewCompanyLogo, setPreviewCompanyLogo] = useState('');
    const [previewMobileLogo, setPreviewMobileLogo] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [loginUserType, setLoginUserType] = useState(0);
    const [totalTabs, setTotalTabs] = useState(2);

    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getDealer(id).then(response => {
                if (response && response.data && response.data.details) {
                    let dealerDetail = response.data.details;
                    dealerDetail.company_id = response.data.details.dealer.company_id;
                    setInitialValue(dealerDetail);
                    setFormDataSet(true);
                    if (!fromDealerLogin) {
                        setEditMode(true);
                    }
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.CompanyList, setCompanyOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'company_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.MenuList, setMenus, addProcessingRequests, reduceProcessingRequests, params, false, '', '', 'data', 'list');
        getDataFromAPI(API.EndPoint.ProviderList, setProviders, addProcessingRequests, reduceProcessingRequests, params, false, '', '', 'data', 'list');
    }, [])

    useEffect(() => {
        if (loginUserType === EndUser.SuperAdmin) {
            setTotalTabs(3);
        } else {
            setTotalTabs(2);
        }
    }, [loginUserType])

    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    const handleMenuSelection = (uuid: string, checked: boolean) => {
        if (checked) {
            if (!selectedMenus.includes(uuid)) {
                setSelectedMenus(prevMenus => [...prevMenus, uuid]);
            }
        } else {
            setSelectedMenus(prevMenus => prevMenus.filter(menuUUID => menuUUID !== uuid));
        }
    }

    const handleProviderSelection = (uuid: string, checked: boolean) => {
        if (checked) {
            if (!selectedProviders.includes(uuid)) {
                setSelectedProviders(prevProviders => [...prevProviders, uuid]);
            }
        } else {
            setSelectedProviders(prevProviders => prevProviders.filter(providerUUID => providerUUID !== uuid));
        }
    }

    useEffect(() => {
        setLoginUserType(getLoginUserType());
    }, [showDialog]);

    const yupSchemaDealer = Yup.object().shape({
        company_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        dealer: Yup.object().shape({
            name: Yup.string().required(ProjectStrings.ValidationRequired),
            code: Yup.string().required(ProjectStrings.ValidationRequired),
            contact_phone: Yup.string().required(ProjectStrings.ValidationRequired),
            contact_email: Yup.string().email().required(ProjectStrings.ValidationRequired),
            password: Yup.string().required(ProjectStrings.ValidationRequired),
        }),
        settings: Yup.object().shape({
            email_host: Yup.string().required(ProjectStrings.ValidationRequired),
            email_username: Yup.string().required(ProjectStrings.ValidationRequired),
            email_password: Yup.string().required(ProjectStrings.ValidationRequired),
            email_sender_id: Yup.string().required(ProjectStrings.ValidationRequired),
        })
    });

    const yupSchema = Yup.object().shape({
        company_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        dealer: Yup.object().shape({
            name: Yup.string().required(ProjectStrings.ValidationRequired),
            code: Yup.string().required(ProjectStrings.ValidationRequired),
            contact_phone: Yup.string().required(ProjectStrings.ValidationRequired),
            contact_email: Yup.string().email().required(ProjectStrings.ValidationRequired),
            password: Yup.string().required(ProjectStrings.ValidationRequired),
        }),
        settings: Yup.object().shape({
            db_host: Yup.string().required(ProjectStrings.ValidationRequired),
            db_username: Yup.string().required(ProjectStrings.ValidationRequired),
            db_password: Yup.string().required(ProjectStrings.ValidationRequired),
            db_name: Yup.string().required(ProjectStrings.ValidationRequired),
            email_host: Yup.string().required(ProjectStrings.ValidationRequired),
            email_username: Yup.string().required(ProjectStrings.ValidationRequired),
            email_password: Yup.string().required(ProjectStrings.ValidationRequired),
            email_sender_id: Yup.string().required(ProjectStrings.ValidationRequired),
        })
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={loginUserType === EndUser.SuperAdmin ? yupSchema : yupSchemaDealer} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    setFormIsValid(true);

                    if (id != undefined && id != '') {
                        let editValues = { name: values.dealer.name, contact_phone: values.dealer.contact_phone, dealers_logo: values.dealer.dealers_logo, mobile_logo: values.dealer.mobile_logo, url_context_path: values.dealer.url_context_path };
                        addProcessingRequests();
                        ServerAPI.updateDealer(id, editValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        let submitValues: any = { ...values };
                        if (selectedMenus.length === 0) {
                            toast.error('Please select atleast 1 Menu');
                            setCurrentTab(2);
                            setSubmitting(false);
                            return;
                        }
                        submitValues.menus = [];
                        menus.forEach(menu => {
                            if (selectedMenus.includes(menu.uuid)) {
                                submitValues.menus.push({ id: menu.uuid, name: menu.name, code: menu.code });
                            }
                        });
                        // submitValues.provider = providers.filter(provider => {
                        //     if (selectedProviders.includes(provider.uuid)) {
                        //         return { id: provider.uuid, provider_mode: provider.provider_name, provider_name: provider.provider_name }
                        //     }
                        // })
                        addProcessingRequests();
                        ServerAPI.addDealer(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text ml-5 text-center">{editMode ? 'Edit' : fromDealerLogin ? "" : 'Add New'} Dealer</h4>
                                <div className="tabpage">
                                    <div className="form-field-container-tabs">
                                        <Tabs value={currentTab} onChange={handelTabChange}
                                            sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "auto" }, justifyContent: "space-between" }}
                                            TabIndicatorProps={{
                                                style: { backgroundColor: "#052160" }
                                            }}
                                        >
                                            <Tab label="Dealer Details" className="tab-headers" />
                                            <Tab label="Dealer Settings" className="tab-headers" />
                                            <Tab label="Menus" className="tab-headers" />
                                            {loginUserType === EndUser.SuperAdmin && (
                                                <Tab label="Providers" className="tab-headers" />
                                            )}
                                        </Tabs>
                                        <TabPanel value={currentTab} index={0}>
                                            <div className="tab-details-section">
                                                <div className="row px-3 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Dealer Name", name: "dealer.name", placeholder: "Dealer Name", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Dealer Code", name: "dealer.code", placeholder: "Dealer Code", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-3 py-2">
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Contact Number", name: "dealer.contact_phone", placeholder: "Contact Number", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Contact Email", name: "dealer.contact_email", placeholder: "Contact Email", required: true, readOnly: ((loginUserType === EndUser.SuperAdmin && editMode) || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-3 py-2">
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Password", name: "dealer.password", placeholder: "Password", required: true, readOnly: ((loginUserType === EndUser.SuperAdmin && editMode) || detailView) ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1 select-container">
                                                        {/* <label className="label-custom-user"><p>Company*</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Company Name", name: "company_id", placeholder: "Company Name", options: companyOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-3 py-3">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "dealer.dealers_logo", label: "Dealer Logo", placeholder: "Dealer Logo", accept: "image/*", imageType: uploadImageType.DEMO_VEHICLE, previewImage: previewCompanyLogo, setPreviewImage: setPreviewCompanyLogo, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "dealer.mobile_logo", label: "Mobile Logo", placeholder: "Mobile Logo", accept: "image/*", imageType: uploadImageType.DEMO_VEHICLE, previewImage: previewMobileLogo, setPreviewImage: setPreviewMobileLogo, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={1}>
                                            <div className="tab-details-section">
                                                <h4 className="h4 ml-1">Email Configuration Settings</h4>
                                                <div className="row px-3 pb-3">
                                                    <div className="col-lg-6 col-md-6 col-xl-6">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Host", name: "settings.email_host", placeholder: "Email Host", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Sender ID", name: "settings.email_username", placeholder: "Email Sender ID", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-3 py-3">
                                                    <div className="col-lg-6 col-md-6 col-xl-6">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Username", name: "settings.email_sender_id", placeholder: "Email Username", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Password", name: "settings.email_password", placeholder: "Email Password", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                                {loginUserType === EndUser.SuperAdmin && (
                                                    <>
                                                        <h4>Data Base Details</h4>
                                                        <div className="row px-3 pb-3">
                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "DB Host", name: "settings.db_host", placeholder: "DB Host", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "DB Name", name: "settings.db_name", placeholder: "DB Name", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-3 py-3">
                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "DB Username", name: "settings.db_username", placeholder: "DB Username", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "DB Password", name: "settings.db_password", placeholder: "DB Password", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <h4>Others</h4>
                                                        <div className="row px-3 pb-3">
                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "URL Context Path", name: "dealer.url_context_path", placeholder: "URL Context Path", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={2}>
                                            <div className="tab-details-section">
                                                <div className="row px-3 pb-3">
                                                    {menus.map((menu, index) =>
                                                        <div className="col-lg-6 col-md-6 col-xl-6" key={index}>
                                                            <FormControlLabel control={<Checkbox
                                                                checked={selectedMenus.includes(menu.uuid)}
                                                                disabled={((loginUserType === EndUser.SuperAdmin && editMode) || !menu.status || detailView) ? true : false}
                                                                onChange={(evt) => { handleMenuSelection(menu.uuid, evt.target.checked) }}
                                                            />} label={menu.name} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {loginUserType === EndUser.SuperAdmin && (
                                            <TabPanel value={currentTab} index={3}>
                                                <div className="tab-details-section">
                                                    <div className="row px-3 pb-3">
                                                        {providers.map((provider, index) =>
                                                            <div className="col-lg-6 col-md-6 col-xl-6" key={index}>
                                                                <FormControlLabel control={<Checkbox
                                                                    checked={selectedProviders.includes(provider.uuid)}
                                                                    disabled={(editMode || detailView) ? true : false}
                                                                    onChange={(evt) => { handleProviderSelection(provider.uuid, evt.target.checked) }}
                                                                />} label={provider.provider_name} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        )}
                                    </div>
                                    <div className="row btn-form-submit">
                                        <button className="button1" onClick={closeDialog}>{detailView ? 'Close' : 'Cancel'}</button>
                                        {currentTab < totalTabs && <button className="button2" onClick={() => setCurrentTab(currentTab + 1)}>Next</button>}
                                        {detailView && currentTab == totalTabs && <button className="button2" onClick={() => setCurrentTab(currentTab - 1)}>Previous</button>}
                                        {!detailView && currentTab == totalTabs &&
                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                setFormIsValid(false);
                                                formikProps.submitForm();
                                                setTimeout(() => {
                                                    //SetTimeout will always return old value for a state. So we are fetching the new value using the setter function.
                                                    setFormIsValid(previousIsValid => {
                                                        if (!previousIsValid) {
                                                            toast.error("Please ensure all fields are Filled and Valid!");
                                                        }
                                                        return previousIsValid; // Return the same state to avoid changing it
                                                    });
                                                }, 500);
                                            }}>Submit</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik >
            )}
        </Drawer>
    )
}

export default DealerEntryDialog;
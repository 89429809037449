import { Drawer, Tabs, Tab } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getCompanyCode, getDataFromAPI, isArrayItemsMatches } from "../common/Utilities";

const UserEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean, fromUserLogin?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView, fromUserLogin }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [currentTab, setCurrentTab] = React.useState(0)
    const formikRef = useRef<FormikProps<APIData.User>>(null);
    const [initialValue, setInitialValue] = useState<APIData.User>(InitialData.User);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [designationOptions, setDesignationOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [departmentOptions, setDepartmentOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [roleOptions, setRoleOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [userOptions, setUserOptions] = useState<FormDataTypes.SelectOption[]>([]);
    // const [businessTypeOptions, setBusinessTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedRoleId, setSelectedRoleId] = useState("");
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<string[]>([]);
    const [selectedShowRoomIds, setSelectedShowRoomIds] = useState<string[]>([]);
    const [stateOptions, setStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getUser(id).then(response => {
                if (response && response.data) {
                    const userDetails = response.data;
                    userDetails.department_uuid = response.data.department_uuid.map((dept: any) => dept.uuid);
                    userDetails.reporting_to = response.data.reporting_to.map((reporting: any) => reporting.uuid);
                    userDetails.business_type_uuid = response.data.business_type_uuid.map((business: any) => business.uuid);
                    userDetails.showroom_uuid = response.data.showroom_uuid.map((showroom: any) => showroom.uuid);
                    userDetails.category_uuid = response.data.category_uuid.map((category: any) => category.uuid);
                    userDetails.category_type_uuid = response.data.category_type_uuid.map((cat_type: any) => cat_type.uuid);
                    setInitialValue(userDetails);
                    setFormDataSet(true);
                    if (!fromUserLogin) {
                        setEditMode(true);
                    }
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    useEffect(() => {
        if (selectedRoleId) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["role_uuid"] = selectedRoleId;
            if (selectedDepartmentIds.length > 0) {
                params["department_uuid"] = "[" + selectedDepartmentIds.map(deptID => '"' + deptID + '"').join(",") + "]";
            }
            if (selectedShowRoomIds.length > 0) {
                params["showroom_uuid"] = "[" + selectedShowRoomIds.map(showroomID => '"' + showroomID + '"').join(",") + "]";
            }
            getDataFromAPI(API.EndPoint.Reporting, setUserOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name');
        }
    }, [selectedRoleId, selectedDepartmentIds, selectedShowRoomIds]);


    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.DesignationList, setDesignationOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'designation_name');
        getDataFromAPI(API.EndPoint.DepartmentList, setDepartmentOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'department_name');
        getDataFromAPI(API.EndPoint.RoleList, setRoleOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'role_name');
        // getDataFromAPI(API.EndPoint.UserList, setUserOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name');
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'showroom_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CategoryList, setCategoryOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'category_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CategoryTypeList, setCategoryTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'category_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
    }, [])

    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    const yupSchema = Yup.object().shape({
        name: Yup.string().required(ProjectStrings.ValidationRequired),
        emp_code: Yup.string().required(ProjectStrings.ValidationRequired),
        contact_number: Yup.string().matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
        contact_email: Yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: "Please enter valid Email.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
        department_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        role_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        showroom_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        //reporting_to: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        category_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        category_type_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    setFormIsValid(true);
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateUser(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        submitValues['company_code'] = getCompanyCode();
                        ServerAPI.addUser(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.role_uuid != selectedRoleId) {
                            setSelectedRoleId(formikProps.values.role_uuid);
                        } else if (!isArrayItemsMatches(selectedDepartmentIds, formikProps.values.department_uuid)) {
                            setSelectedDepartmentIds(formikProps.values.department_uuid);
                        } else if (!isArrayItemsMatches(selectedShowRoomIds, formikProps.values.showroom_uuid)) {
                            setSelectedShowRoomIds(formikProps.values.showroom_uuid);
                        }

                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center ml-5">{editMode ? 'Edit' : fromUserLogin ? "" : 'Add New'} Users</h4>
                                <div className="tabpage">
                                    <Form>
                                        <div className="form-field-container-tabs">
                                            <Tabs value={currentTab} onChange={handelTabChange}
                                                sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "100%" }, justifyContent: "space-between" }}
                                                TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: "#14408B",
                                                    },
                                                }}
                                            >
                                                <Tab label="Basic Details" className="tab-headers" />
                                                <Tab label="Other Details" className="tab-headers" />
                                            </Tabs>
                                            <TabPanel value={currentTab} index={0}>
                                                <div className="tab-details-section">
                                                    <div className="row px-3 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Name", name: "name", placeholder: "Name", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Employee Code", name: "emp_code", placeholder: "Employee Code", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Contact Number", name: "contact_number", placeholder: "Contact Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Contact Email", name: "contact_email", placeholder: "Contact Email", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Department", name: "department_uuid", placeholder: "Department", required: true, options: departmentOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Role", name: "role_uuid", placeholder: "Role", required: true, options: roleOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Showrooms", name: "showroom_uuid", placeholder: "Showrooms", required: true, options: showroomOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Reporting To", name: "reporting_to", placeholder: "Reporting To", options: userOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row btn-form-submit">
                                                    <button className="button1" onClick={closeDialog}>{detailView ? 'Close' : 'Cancel'}</button>
                                                    <button className="button2" onClick={() => setCurrentTab(currentTab + 1)}>Next</button>
                                                </div> */}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <div className="tab-details-section">
                                                    <div className="row px-3 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Category", name: "category_uuid", placeholder: "Category", required: true, options: categoryOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user text-field-empty-custom-user-1">

                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Category Type", name: "category_type_uuid", placeholder: "Category Type", required: true, options: categoryTypeOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>

                                                    <div className="row px-3 py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "state_uuid", placeholder: "State", required: true, options: stateOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-xl-6">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status", readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-3">

                                                        <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mt-5 btn-form-submit">
                                                    <button type="button" className="button1" onClick={closeDialog}>{detailView ? 'Close' : 'Cancel'}</button>
                                                    {detailView ?
                                                        <button className="button2" onClick={() => setCurrentTab(currentTab - 1)}>Previous</button>
                                                        :
                                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                            setFormIsValid(false);
                                                            formikProps.submitForm();
                                                            setTimeout(() => {
                                                                //SetTimeout will always return old value for a state. So we are fetching the new value using the setter function.
                                                                setFormIsValid(previousIsValid => {
                                                                    if (!previousIsValid) {
                                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                                    }
                                                                    return previousIsValid; // Return the same state to avoid changing it
                                                                });
                                                            }, 500);
                                                        }}>Submit</button>
                                                    }
                                                </div> */}
                                                </div>
                                            </TabPanel>
                                        </div>
                                        <div className="row btn-form-submit">
                                            <button className="button1" onClick={closeDialog}>{detailView ? 'Close' : 'Cancel'}</button>
                                            {currentTab < 1 && <button className="button2" onClick={() => setCurrentTab(currentTab + 1)}>Next</button>}
                                            {detailView && currentTab == 1 && <button className="button2" onClick={() => setCurrentTab(currentTab - 1)}>Previous</button>}
                                            {!detailView && currentTab == 1 &&
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    setFormIsValid(false);
                                                    formikProps.submitForm();
                                                    setTimeout(() => {
                                                        //SetTimeout will always return old value for a state. So we are fetching the new value using the setter function.
                                                        setFormIsValid(previousIsValid => {
                                                            if (!previousIsValid) {
                                                                toast.error("Please ensure all fields are Filled and Valid!");
                                                            }
                                                            return previousIsValid; // Return the same state to avoid changing it
                                                        });
                                                    }, 500);
                                                }}>Submit</button>
                                            }
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )
                    }}
                </Formik >
            )}
        </Drawer >
    )
}

export default UserEntryDialog;
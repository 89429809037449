import { APIData } from '../../common/DataTypes';
import { useState } from 'react';

const UserLeadsSearchModal: React.FC<{ showModal: boolean, closeModal: () => void, filterLeads: any }> = ({ showModal, closeModal, filterLeads }) => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [leadID, setLeadID] = useState("");

    const handleFilter = () => {
        const filters: APIData.SearchLeads = {
            mobileNumber: mobileNumber,
            leadID: leadID,
            customerName: customerName
        };
        filterLeads(filters);
    };

    const clearFilter = () => {
        setMobileNumber("");
        setCustomerName("");
        setLeadID("")
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        minWidth: '400px',
        minHeight: '380px'
    };

    return (
        <div className='tabdetails' style={{ height: "87vh", overflowY: "auto" }}>
            <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Search</h4>
            <div className="custom-border-grey my-2"></div>
            <div className='px-2 pb-2'>
                <div className="text-center auth-logo-text pb-2">
                    {/* <h4 className="mt-0 mb-3">Search Filter</h4> */}
                </div>
                <div className="form-group-1 text-left">
                    <div className="input-group mb-4 mt-2">
                        <label className='label-custom' >Mobile Number</label>
                        <input type="text" className="form-control" id="phone" placeholder="Enter Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                    </div>
                </div>
                <div className="form-group-1 text-left">
                    <div className="input-group mb-4">
                        <label className='label-custom' >Lead ID</label>
                        <input type="text" className="form-control" id="phone" placeholder="Enter Lead ID" value={leadID} onChange={(e) => setLeadID(e.target.value)} />
                    </div>
                </div>
                <div className="form-group-1 text-left">
                    <div className="input-group">
                        <label className='label-custom' >Customer Name</label>
                        <input type="text" className="form-control" id="phone" placeholder="Enter Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                    </div>
                </div>
                <div className="row btn-form-submit" style={{ position: "absolute" }}>
                    <button type="button" className="button1" onClick={clearFilter}>Clear</button>
                    <button type="button" className="button2" onClick={() => { handleFilter(); closeModal() }}>Search</button>
                </div>
            </div>
        </div>
    )
}

export default UserLeadsSearchModal;
export const PageURLNames = {
    DASHBOARD: "dashboard",
    DEALERS: "dealers",
    MENUS: "menus",
    PROVIDERS: "providers",
    PROVIDER_CATEGORY: "provider-category",
    BUSINESS: "business",
    BUSINESS_MODE: "business-mode",
    CATEGORY_USER: "usercategory",
    CATEGORYTYPEUSER: "usercategorytype",
    VERSION: "version",

    USERS: "users",
    TEAMS: "teams",
    LOCATIONS: "locations",
    MODEL: "model",
    MODELVARIANT: "modelvariant",
    COLOR: "color",
    FUELTYPE: "fueltype",
    DEMOCAR: "democars",
    BODY_TYPE: "body-types",
    TRANSMISSION_TYPE: "transmission-types",
    INTERIOR_COLOR: "interior-color",
    EXTERIOR_COLOR: "exterior-color",
    ROLE: "role",
    TARGET_RULES: "target-rules",
    CATEGORY: "category",
    STATE: "state",
    CITY: "city",
    PINCODE: "pincode",
    LEAD_CATEGORY: "lead_category",
    CUSTOMER_TYPE: "customer_type",
    LEAD_SOURCE: "lead_source",
    LEAD_SOURCE_CATEGORY: "lead_source_category",
    LEAD_STATUS: "lead_status",
    INSURANCE_TYPE: "insurance_type",
    INSURANCE_COMPANIES: "insurance_companies",
    LEAD_TYPE: "lead_type",
    LEAD_TASK: "lead_task",
    LEAD_PROSPECT_TYPE: "lead_prospect_type",
    LEAD_REASON: "lead_reason",
    LEAD_TITLE: "lead_title",
    PAYMENT_MODE: "payment_mode",
    CATEGORY_TYPE: 'category_type',

    TESTDRIVE: "testdrive",
    LEADS_TODAY: "leads-today",
    LEADS_FUTURE: "leads-future",
    LEADS_COMPLETE: "leads-complete",
    SHOWROOM_TRANSFER: "showroomtransfer",
    FUEL_REFILL: "fuelrefill",
    OTHER_TRIP: "othertrips",
    REPORT_TESTDRIVE: "report-testdrive",
    REPORT_STOCKTRANSFER: "report-showroomtransfer",
    REPORT_FUELREFILL: "report-fuelrefill",
    REPORT_OTHERTRIPS: "report-othertrips",

    USER_DASHBOARD_TODAY: "user-dashboard-today",
    USER_DASHBOARD_ETVBRL: "user-dashboard-etvbrl",
    USER_DASHBOARD_LOST_DROP: "user-dashboard-lost",
    TARGETS: "targets",
    ENQUIRY_TRANSFER: "enquiry-transfer",
    USER_MY_LEADS: "my-leads",
    USER_MY_TASKS: "my-tasks",
    USER_INVENTORY: "inventory-list",
    USER_AVAILABLE_INVENTORY: "available-inventory-list",
    USER_INVENTORY_PRICE: "inventory-price-list",
    USER_ALLOTMENT_LIST: "inventory-allotment-list",
    USER_REPORT: "report",
    USER_HELP_SUPPORT: "help-support",
    USER_TESTDRIVE_DASHBOARD: "testdrive-dashboard",
    USER_TESTDRIVE: "testdrive",
    USER_TESTDRIVE_TRANSFER: "testdrive-showroom-transfer",
    USER_TESTDRIVE_REFILL: "testdrive-fuel-refill",
    USER_TESTDRIVE_OTHERS: "testdrive-others",
    MODEL_MAPPING: "model-mapping",
    VARIANT_MAPPING: "variant-mapping",
    LEADSOURCE_MAPPING: "leadsource-mapping",
    CAMPAIGN: "campaign",
    NOTIFICATION: "notification",
}

export const PageLinks = {
    LOGIN: "/login",
    ADMIN_LOGIN: "/admin-login",
    PAGE_SUPER_ADMIN: "/admin",
    PAGE_DEALER: "/dealer",
    PAGE_COMPANY: "/company",
    PAGE_USER: "/user",
    LIST: "/view",
    DETAIL: "/detail",
    FORM: "/form",

    DEALER_DASHBOARD: "/dealer/view/" + PageURLNames.USERS,
    DEALER_USERS: "/dealer/view/" + PageURLNames.USERS,
    DEALER_TEAMS: "/dealer/view/" + PageURLNames.TEAMS,
    DEALER_LOCATIONS: "/dealer/view/" + PageURLNames.LOCATIONS,
    DEALER_MODEL: "/dealer/view/" + PageURLNames.MODEL,
    DEALER_MODEL_VARIANT: "/dealer/view/" + PageURLNames.MODELVARIANT,
    DEALER_COLOR: "/dealer/view/" + PageURLNames.COLOR,
    DEALER_FUEL_TYPE: "/dealer/view/" + PageURLNames.FUELTYPE,
    DEALER_DEMO_CAR: "/dealer/view/" + PageURLNames.DEMOCAR,
    DEALER_BODY_TYPE: "/dealer/view/" + PageURLNames.BODY_TYPE,
    DEALER_TRANSMISSION_TYPE: "/dealer/view/" + PageURLNames.TRANSMISSION_TYPE,
    DEALER_INTERIOR_COLOR: "/dealer/view/" + PageURLNames.INTERIOR_COLOR,
    DEALER_EXTERIOR_COLOR: "/dealer/view/" + PageURLNames.EXTERIOR_COLOR,
    DEALER_ROLE: "/dealer/view/" + PageURLNames.ROLE,
    DEALER_TARGET_RULES: "/dealer/view/" + PageURLNames.TARGET_RULES,
    DEALER_CATEGORY: "/dealer/view/" + PageURLNames.CATEGORY,
    DEALER_STATE: "/dealer/view/" + PageURLNames.STATE,
    DEALER_CITY: "/dealer/view/" + PageURLNames.CITY,
    DEALER_PINCODE: "/dealer/view/" + PageURLNames.PINCODE,
    DEALER_LEAD_CATEGORY: "/dealer/view/" + PageURLNames.LEAD_CATEGORY,
    DEALER_CUSTOMER_TYPE: "/dealer/view/" + PageURLNames.CUSTOMER_TYPE,
    DEALER_LEAD_SOURCE: "/dealer/view/" + PageURLNames.LEAD_SOURCE,
    DEALER_LEAD_SOURCE_CATEGORY: "/dealer/view/" + PageURLNames.LEAD_SOURCE_CATEGORY,
    DEALER_LEAD_STATUS: "/dealer/view/" + PageURLNames.LEAD_STATUS,
    DEALER_INSURANCE_TYPE: "/dealer/view/" + PageURLNames.INSURANCE_TYPE,
    DEALER_INSURANCE_COMPANIES: "/dealer/view/" + PageURLNames.INSURANCE_COMPANIES,
    DEALER_LEAD_TYPE: "/dealer/view/" + PageURLNames.LEAD_TYPE,
    DEALER_LEAD_TASK: "/dealer/view/" + PageURLNames.LEAD_TASK,
    DEALER_LEAD_PROSPECT_TYPE: "/dealer/view/" + PageURLNames.LEAD_PROSPECT_TYPE,
    DEALER_LEAD_REASON: "/dealer/view/" + PageURLNames.LEAD_REASON,
    DEALER_LEAD_TITLE: "/dealer/view/" + PageURLNames.LEAD_TITLE,
    DEALER_PAYMENT_MODE: "/dealer/view/" + PageURLNames.PAYMENT_MODE,
    DEALER_CATEGORY_TYPE: "/dealer/view/" + PageURLNames.CATEGORY_TYPE,

    DEALER_TESTDRIVE: "/dealer/view/" + PageURLNames.TESTDRIVE,
    DEALER_SHOWROOM_TRANSFER: "/dealer/view/" + PageURLNames.SHOWROOM_TRANSFER,
    DEALER_FUEL_REFILL: "/dealer/view/" + PageURLNames.FUEL_REFILL,
    DEALER_OTHER_TRIP: "/dealer/view/" + PageURLNames.OTHER_TRIP,
    DEALER_LEADS_TODAY: "/dealer/view/" + PageURLNames.LEADS_TODAY,
    DEALER_LEADS_FUTURE: "/dealer/view/" + PageURLNames.LEADS_FUTURE,
    DEALER_LEADS_COMPLETE: "/dealer/view/" + PageURLNames.LEADS_COMPLETE,
    DEALER_REPORT_TESTDRIVE: "/dealer/view/" + PageURLNames.REPORT_TESTDRIVE,
    DEALER_REPORT_STOCKTRANSFER: "/dealer/view/" + PageURLNames.REPORT_STOCKTRANSFER,
    DEALER_REPORT_FUELREFILL: "/dealer/view/" + PageURLNames.REPORT_FUELREFILL,
    DEALER_REPORT_OTHERTRIPS: "/dealer/view/" + PageURLNames.REPORT_OTHERTRIPS,
    DEALER_MODEL_MAPPING: "/dealer/view/" + PageURLNames.MODEL_MAPPING,
    DEALER_VARIANT_MAPPING: "/dealer/view/" + PageURLNames.VARIANT_MAPPING,
    DEALER_LEADSOURCE_MAPPING: "/dealer/view/" + PageURLNames.LEADSOURCE_MAPPING,
    DEALER_CAMPAIGN: "/dealer/view/" + PageURLNames.CAMPAIGN,

    COMPANY_USERS: "/company/view/" + PageURLNames.USERS,
    COMPANY_DEALERS: "/company/view/" + PageURLNames.DEALERS,

    ADMIN_COMPANIES: "/admin/view/" + PageURLNames.USERS,
    ADMIN_DEALERS: "/admin/view/" + PageURLNames.DEALERS,
    ADMIN_USERS: "/admin/view/" + PageURLNames.USERS,
    ADMIN_MENUS: "/admin/view/" + PageURLNames.MENUS,
    ADMIN_PROVIDERS: "/admin/view/" + PageURLNames.PROVIDERS,
    ADMIN_PROVIDER_CATEGORY: "/admin/view/" + PageURLNames.PROVIDER_CATEGORY,
    ADMIN_BUSINESS: "/admin/view/" + PageURLNames.BUSINESS,
    ADMIN_BUSINESS_MODE: "/admin/view/" + PageURLNames.BUSINESS_MODE,
    ADMIN_CATEGORY: "/admin/view/" + PageURLNames.CATEGORY_USER,
    ADMIN_CATEGORY_TYPE: "/admin/view/" + PageURLNames.CATEGORYTYPEUSER,
    ADMIN_VERSION: "/admin/view/" + PageURLNames.VERSION,

    USER_DASHBOARD_TODAY: "/user/view/" + PageURLNames.USER_DASHBOARD_TODAY,
    USER_DASHBOARD_ETVBRL: "/user/view/" + PageURLNames.USER_DASHBOARD_ETVBRL,
    USER_DASHBOARD_LOST_DROP: "/user/view/" + PageURLNames.USER_DASHBOARD_LOST_DROP,
    USER_TARGETS: "/user/view/" + PageURLNames.TARGETS,
    USER_ENQUIRY_TRANSFER: "/user/view/" + PageURLNames.ENQUIRY_TRANSFER,
    USER_MY_LEADS: "/user/view/" + PageURLNames.USER_MY_LEADS,
    USER_MY_TASKS: "/user/view/" + PageURLNames.USER_MY_TASKS,
    USER_INVENTORY: "/user/view/" + PageURLNames.USER_INVENTORY,
    USER_AVAILABLE_INVENTORY: "/user/view/" + PageURLNames.USER_AVAILABLE_INVENTORY,
    USER_INVENTORY_PRICE: "/user/view/" + PageURLNames.USER_INVENTORY_PRICE,
    USER_ALLOTMENT_LIST: "/user/view/" + PageURLNames.USER_ALLOTMENT_LIST,
    USER_REPORT: "/user/view/" + PageURLNames.USER_REPORT,
    USER_HELP_SUPPORT: "/user/view/" + PageURLNames.USER_HELP_SUPPORT,
    USER_TESTDRIVE_DASHBOARD: "/user/view/" + PageURLNames.USER_TESTDRIVE_DASHBOARD,
    USER_TESTDRIVE: "/user/view/" + PageURLNames.USER_TESTDRIVE,
    USER_TESTDRIVE_TRANSFER: "/user/view/" + PageURLNames.USER_TESTDRIVE_TRANSFER,
    USER_TESTDRIVE_REFILL: "/user/view/" + PageURLNames.USER_TESTDRIVE_REFILL,
    USER_TESTDRIVE_OTHERS: "/user/view/" + PageURLNames.USER_TESTDRIVE_OTHERS,

    NOTIFICATION: "/user/view/" + PageURLNames.NOTIFICATION,

}

export const ProjectStrings = {
    ValidationRequired: 'Required',
    ValidationSelect: 'Please Select',
    ValidationEmail: 'Not a Valid Email',
    ValidationNumber: 'Not a Valid Numeric',
    ValidationMax: (max: number) => 'Max ' + max + ' Characters',
    ValidationMin: (min: number) => 'Min ' + min + ' Characters',
    ValidationArrayEmpty: 'Please Select Atleast 1',
    ErrorAPIFailed: 'Error Occurred while processing the request'
}

export const APIResult = {
    SUCCESS: true,
    FAILURE: false
}

//Live Servers
// export const APIServers = {
//     Master: 'https://master-api.autorevog.com',
//     TestDrive: 'https://testdrive-api.autorevog.com',
//     User: 'https://user-api.autorevog.com',
//     Leads: 'https://leads-api.autorevog.com',
// }

// Dev Servers
export const APIServers = {
    Master: 'https://dev-master-api.autorevog.com',
    TestDrive: 'https://dev-testdrive-api.autorevog.com',
    User: 'https://dev-user-api.autorevog.com',
    Leads: 'https://dev-leads-api.autorevog.com',
    Inventory: 'https://dev-inventory-api.autorevog.com',
    Report: 'https://dev-report-api.autorevog.com',
}

export const Endpoints = {
    SuperAdminLogin: APIServers.User + '/api/auth/sa/login',
    SuperAdminVerifyOtp: APIServers.User + '/api/auth/sa/verifyotp',

    DealerLogin: APIServers.User + '/api/auth/dealer/login',
    DealerVerifyOtp: APIServers.User + '/api/auth/dealer/verifyotp',

    CompanyLogin: APIServers.User + '/api/auth/company/login',
    CompanyVerifyOtp: APIServers.User + '/api/auth/company/verifyotp',

    UserLogin: APIServers.User + '/api/auth/user/login',
    UserSendOtp: APIServers.User + '/api/auth/user/sendotp',
    UserVerifyOtp: APIServers.User + '/api/auth/user/verifyotp',

    ImageUpload: APIServers.User + '/api/image/upload',

    CompanyList: APIServers.User + '/api/jmplcompany/list',
    CompanyAdd: APIServers.User + '/api/jmplcompany/add',
    CompanyDetail: APIServers.User + '/api/jmplcompany/details',
    CompanyUpdate: APIServers.User + '/api/jmplcompany/update',
    CompanyDelete: APIServers.User + '/api/jmplcompany/delete',
    CompanyStatusUpdate: APIServers.User + '/api/jmplcompany/status/update',

    NotificationList: APIServers.User + '/api/notification/list',
    NotificationAdd: APIServers.User + '/api/notification/add',
    NotificationDetail: APIServers.User + '/api/notification/details',
    NotificationUpdate: APIServers.User + '/api/notification/update',
    NotificationDelete: APIServers.User + '/api/notification/delete',

    DealerList: APIServers.User + '/api/jmpldealer/list',
    DealerAdd: APIServers.User + '/api/jmpldealer/add',
    DealerDetail: APIServers.User + '/api/jmpldealer/details',
    DealerUpdate: APIServers.User + '/api/jmpldealer/dealer/update',
    DealerStatusUpdate: APIServers.User + '/api/jmpldealer/status/update',

    MenuList: APIServers.User + '/api/jmplmenu/list',
    MenuAdd: APIServers.User + '/api/jmplmenu/add',
    MenuDetail: APIServers.User + '/api/jmplmenu/details',
    MenuUpdate: APIServers.User + '/api/jmplmenu/update',
    MenuDelete: APIServers.User + '/api/jmplmenu/delete',

    ProviderList: APIServers.User + '/api/jmplprovider/list',
    ProviderAdd: APIServers.User + '/api/jmplprovider/add',
    ProviderDetail: APIServers.User + '/api/jmplprovider/details',
    ProviderUpdate: APIServers.User + '/api/jmplprovider/update',
    ProviderDelete: APIServers.User + '/api/jmplprovider/delete',
    Reporting: APIServers.User + '/api/reporting/list',

    UserList: APIServers.User + '/api/delearuser/list',
    UserDetail: APIServers.User + '/api/delearuser/details',
    UserAdd: APIServers.User + '/api/delearuser/add',
    UserUpdate: APIServers.User + '/api/delearuser/update',
    UserStatusUpdate: APIServers.User + '/api/delearuser/status/update',

    TeamList: APIServers.User + '/api/team/list',
    TeamDetail: APIServers.User + '/api/team/details',
    TeamAdd: APIServers.User + '/api/team/add',
    TeamUpdate: APIServers.User + '/api/team/update',

    userDealerlist: APIServers.User + '/api/userdealer/list',


    UserOEMAdd: APIServers.User + '/api/oem_crm/add',
    UserOEMDetails: APIServers.User + '/api/oem_crm/details',

    UserProfile: APIServers.User + '/api/v2/userprofile',

    UserDemoCarList: APIServers.User + '/api/userdemocar/list',

    ProviderCategoryList: APIServers.Master + '/api/providercategory/list',
    ProviderCategoryAdd: APIServers.Master + '/api/providercategory/add',
    ProviderCategoryDetail: APIServers.Master + '/api/providercategory/details',
    ProviderCategoryUpdate: APIServers.Master + '/api/providercategory/update',
    ProviderCategoryDelete: APIServers.Master + '/api/providercategory/delete',

    BusinessList: APIServers.Master + '/api/business/list',
    BusinessAdd: APIServers.Master + '/api/business/add',
    BusinessDetail: APIServers.Master + '/api/business/details',
    BusinessUpdate: APIServers.Master + '/api/business/update',
    BusinessDelete: APIServers.Master + '/api/business/delete',

    BusinessModeList: APIServers.Master + '/api/businessmode/list',
    BusinessModeAdd: APIServers.Master + '/api/businessmode/add',
    BusinessModeDetail: APIServers.Master + '/api/businessmode/details',
    BusinessModeUpdate: APIServers.Master + '/api/businessmode/update',
    BusinessModeDelete: APIServers.Master + '/api/businessmode/delete',

    CategoryList: APIServers.Master + '/api/category/list',
    CategoryDetail: APIServers.Master + '/api/category/details',
    CategoryAdd: APIServers.Master + '/api/category/add',
    CategoryUpdate: APIServers.Master + '/api/category/update',
    CategoryDelete: APIServers.Master + '/api/category/delete',

    CategoryTypeList: APIServers.Master + '/api/categorytype/list',
    CategoryTypeDetail: APIServers.Master + '/api/categorytype/details',
    CategoryTypeAdd: APIServers.Master + '/api/categorytype/add',
    CategoryTypeUpdate: APIServers.Master + '/api/categorytype/update',
    CategoryTypeDelete: APIServers.Master + '/api/categorytype/delete',

    DepartmentList: APIServers.Master + '/api/department/list',
    DepartmentAdd: APIServers.Master + '/api/department/add',
    DepartmentUpdate: APIServers.Master + '/api/department/update',
    DepartmentDelete: APIServers.Master + '/api/department/delete',

    DesignationList: APIServers.Master + '/api/designation/list',
    DesignationAdd: APIServers.Master + '/api/designation/add',
    DesignationUpdate: APIServers.Master + '/api/designation/update',
    DesignationDelete: APIServers.Master + '/api/designation/delete',

    RoleList: APIServers.Master + '/api/role/list',
    RoleDetail: APIServers.Master + '/api/role/details',
    RoleAdd: APIServers.Master + '/api/role/add',
    RoleUpdate: APIServers.Master + '/api/role/update',
    RoleDelete: APIServers.Master + '/api/role/delete',

    TargetRulesList: APIServers.Master + "/api/target/rules/list",
    TargetRulesDetail: APIServers.Master + "/api/target/rules/details",
    TargetRulesAdd: APIServers.Master + "/api/target/rules/add",
    TargetRulesUpdate: APIServers.Master + "/api/target/rule/update",
    TargetRulesStatusUpdate: APIServers.Master + "/api/target/rules/status/update",

    TargetStageList: APIServers.Master + "/api/target/list",

    StateList: APIServers.Master + '/api/state/list',
    StateAdd: APIServers.Master + '/api/state/add',
    StateDetail: APIServers.Master + '/api/state/details',
    StateUpdate: APIServers.Master + '/api/state/update',
    StateDelete: APIServers.Master + '/api/state/delete',
    StateStatusUpdate: APIServers.Master + '/api/state/status/update',

    CityList: APIServers.Master + '/api/city/list',
    CityAdd: APIServers.Master + '/api/city/add',
    CityDetail: APIServers.Master + '/api/city/details',
    CityUpdate: APIServers.Master + '/api/city/update',
    CityDelete: APIServers.Master + '/api/city/delete',
    CityStatusUpdate: APIServers.Master + '/api/city/status/update',

    PincodeList: APIServers.Master + '/api/pincode/list',
    PincodeAdd: APIServers.Master + '/api/pincode/add',
    PincodeDetail: APIServers.Master + '/api/pincode/details',
    PincodeUpdate: APIServers.Master + '/api/pincode/update',
    PincodeDelete: APIServers.Master + '/api/pincode/delete',

    LeadCategoryList: APIServers.Master + '/api/lead/category/list',
    LeadCategoryAdd: APIServers.Master + '/api/lead/category/add',
    LeadCategoryDetail: APIServers.Master + '/api/lead/category/details',
    LeadCategoryUpdate: APIServers.Master + '/api/lead/category/update',
    LeadCategoryDelete: APIServers.Master + '/api/lead/category/delete',
    LeadCategoryStatusUpdate: APIServers.Master + '/api/lead/category/status/update',

    CustomerTypeList: APIServers.Master + '/api/customer/type/list',
    CustomerTypeAdd: APIServers.Master + '/api/customer/type/add',
    CustomerTypeDetail: APIServers.Master + '/api/customer/type/details',
    CustomerTypeUpdate: APIServers.Master + '/api/customer/type/update',
    CustomerTypeDelete: APIServers.Master + '/api/customer/type/delete',
    CustomerTypeStatusUpdate: APIServers.Master + '/api/customer/type/status/update',

    LeadSourceList: APIServers.Master + '/api/lead/source/list',
    LeadSourceAdd: APIServers.Master + '/api/lead/source/add',
    LeadSourceDetail: APIServers.Master + '/api/lead/source/details',
    LeadSourceUpdate: APIServers.Master + '/api/lead/source/update',
    LeadSourceDelete: APIServers.Master + '/api/lead/source/delete',
    LeadSourceStatusUpdate: APIServers.Master + '/api/lead/source/status/update',

    LeadSourceCategoryList: APIServers.Master + '/api/leadsource/category/list',
    LeadSourceCategoryAdd: APIServers.Master + '/api/leadsource/category/add',
    LeadSourceCategoryDetail: APIServers.Master + '/api/leadsource/category/details',
    LeadSourceCategoryUpdate: APIServers.Master + '/api/leadsource/category/update',
    LeadSourceCategoryDelete: APIServers.Master + '/api/leadsource/category/delete',

    LeadStatusFilterList: APIServers.Master + '/api/lead/filter/status/list',

    LeadStatusList: APIServers.Master + '/api/lead/status/list',
    LeadStatusAdd: APIServers.Master + '/api/lead/status/add',
    LeadStatusDetail: APIServers.Master + '/api/lead/status/details',
    LeadStatusUpdate: APIServers.Master + '/api/lead/status/update',
    LeadStatusDelete: APIServers.Master + '/api/lead/status/delete',

    InsuranceTypeList: APIServers.Master + '/api/insurance/type/list',
    InsuranceTypeAdd: APIServers.Master + '/api/insurance/type/add',
    InsuranceTypeDetail: APIServers.Master + '/api/insurance/type/details',
    InsuranceTypeUpdate: APIServers.Master + '/api/insurance/type/update',
    InsuranceTypeDelete: APIServers.Master + '/api/insurance/type/delete',

    InsuranceCompanyList: APIServers.Master + '/api/insurance/companies/list',
    InsuranceCompanyAdd: APIServers.Master + '/api/insurance/companies/add',
    InsuranceCompanyDetail: APIServers.Master + '/api/insurance/companies/details',
    InsuranceCompanyUpdate: APIServers.Master + '/api/insurance/companies/update',
    InsuranceCompanyDelete: APIServers.Master + '/api/insurance/companies/delete',

    LeadTypeList: APIServers.Master + '/api/lead/type/list',
    LeadTypeAdd: APIServers.Master + '/api/lead/type/add',
    LeadTypeDetail: APIServers.Master + '/api/lead/type/details',
    LeadTypeUpdate: APIServers.Master + '/api/lead/type/update',
    LeadTypeDelete: APIServers.Master + '/api/lead/type/delete',

    LeadTaskList: APIServers.Master + '/api/lead/task/list',
    LeadTaskAdd: APIServers.Master + '/api/lead/task/add',
    LeadTaskDetail: APIServers.Master + '/api/lead/task/details',
    LeadTaskUpdate: APIServers.Master + '/api/lead/task/update',
    LeadTaskDelete: APIServers.Master + '/api/lead/task/delete',

    LeadProspectTypeList: APIServers.Master + '/api/lead/prospect/type/list',
    LeadProspectTypeAdd: APIServers.Master + '/api/lead/prospect/type/add',
    LeadProspectTypeDetail: APIServers.Master + '/api/lead/prospect/type/details',
    LeadProspectTypeUpdate: APIServers.Master + '/api/lead/prospect/type/update',
    LeadProspectTypeDelete: APIServers.Master + '/api/lead/prospect/type/delete',

    LeadReasonList: APIServers.Master + '/api/lead/reason/list',
    LeadReasonAdd: APIServers.Master + '/api/lead/reason/add',
    LeadReasonDetail: APIServers.Master + '/api/lead/reason/details',
    LeadReasonUpdate: APIServers.Master + '/api/lead/reason/update',
    LeadReasonDelete: APIServers.Master + '/api/lead/reason/delete',

    PaymentModeList: APIServers.Master + '/api/payment/mode/list',
    PaymentModeAdd: APIServers.Master + '/api/payment/mode/add',
    PaymentModeDetail: APIServers.Master + '/api/payment/mode/details',
    PaymentModeUpdate: APIServers.Master + '/api/payment/mode/update',
    PaymentModeDelete: APIServers.Master + '/api/payment/mode/delete',


    ShowroomList: APIServers.Master + '/api/showroom/list',
    ShowroomDetail: APIServers.Master + '/api/showroom/details',
    ShowroomAdd: APIServers.Master + '/api/showroom/add',
    ShowroomUpdate: APIServers.Master + '/api/showroom/update',
    ShowroomDelete: APIServers.Master + '/api/showroom/delete',
    ShowroomStatusUpdate: APIServers.Master + '/api/showroom/status/update',

    ModelList: APIServers.Master + '/api/model/list',
    ModelDetail: APIServers.Master + '/api/model/details',
    ModelAdd: APIServers.Master + '/api/model/add',
    ModelUpdate: APIServers.Master + '/api/model/update',
    ModelDelete: APIServers.Master + '/api/model/delete',
    ModelStatusUpdate: APIServers.Master + '/api/model/status/update',

    CancelBookingReason: APIServers.Master + '/api/cancelbooking/reason/list',

    ModelVariantList: APIServers.Master + '/api/modelvariant/list',
    ModelVariantDetail: APIServers.Master + '/api/modelvariant/details',
    ModelVariantAdd: APIServers.Master + '/api/modelvariant/add',
    ModelVariantUpdate: APIServers.Master + '/api/modelvariant/update',
    ModelVariantDelete: APIServers.Master + '/api/modelvariant/delete',
    ModelVariantFuelList: APIServers.Master + '/api/modelvariant/fuel/list',
    ModelVariantTransmissionList: APIServers.Master + '/api/modelvariant/transmission/list',
    ModelVariantColorList: APIServers.Master + '/api/modelvariant/color/list',
    ModelVariantStatusUpdate: APIServers.Master + '/api/modelvariant/status/update',

    ColorList: APIServers.Master + '/api/color/list',
    ColorDetail: APIServers.Master + '/api/color/details',
    ColorAdd: APIServers.Master + '/api/color/add',
    ColorUpdate: APIServers.Master + '/api/color/update',
    ColorDelete: APIServers.Master + '/api/color/delete',

    FuelTypeList: APIServers.Master + '/api/fuel/list',
    FuelTypeDetail: APIServers.Master + '/api/fuel/details',
    FuelTypeAdd: APIServers.Master + '/api/fuel/add',
    FuelTypeUpdate: APIServers.Master + '/api/fuel/update',
    FuelTypeDelete: APIServers.Master + '/api/fuel/delete',
    FuelTypeStatusUpdate: APIServers.Master + '/api/fuel/status/update',

    BodyTypeList: APIServers.Master + '/api/body_type/list',
    BodyTypeDetail: APIServers.Master + '/api/body_type/details',
    BodyTypeAdd: APIServers.Master + '/api/body_type/add',
    BodyTypeUpdate: APIServers.Master + '/api/body_type/update',
    BodyTypeDelete: APIServers.Master + '/api/body_type/delete',
    BodyTypeStatusUpdate: APIServers.Master + '/api/body_type/status/update',

    TransmissionTypeList: APIServers.Master + '/api/transmission_type/list',
    TransmissionTypeDetail: APIServers.Master + '/api/transmission_type/details',
    TransmissionTypeAdd: APIServers.Master + '/api/transmission_type/add',
    TransmissionTypeUpdate: APIServers.Master + '/api/transmission_type/update',
    TransmissionTypeDelete: APIServers.Master + '/api/transmission_type/delete',
    TransmissionTypeStatusUpdate: APIServers.Master + '/api/transmission_type/status/update',

    CampaignList: APIServers.Master + '/api/campaign/list',
    CampaignDetails: APIServers.Master + '/api/campaign/details',
    CampaignAdd: APIServers.Master + '/api/campaign/add',
    CampaignUpdate: APIServers.Master + '/api/campaign/update',
    CampaignDelete: APIServers.Master + '/api/campaign/delete',

    ModelMappingList: APIServers.Master + '/api/oem/modelmapping/list',
    ModelMappingDetail: APIServers.Master + '/api/oem/modelmapping/details',
    ModelMappingAdd: APIServers.Master + '/api/oem/modelmapping/add',
    ModelMappingUpdate: APIServers.Master + '/api/oem/modelmapping/update',
    ModelMappingStatusUpdate: APIServers.Master + '/api/oem/modelmapping/status/update',

    LeadSourceMappingList: APIServers.Master + '/api/oem/leadsource/list',
    LeadSourceMappingDetail: APIServers.Master + '/api/oem/leadsource/details',
    LeadSourceMappingAdd: APIServers.Master + '/api/oem/leadsource/add',
    LeadSourceMappingUpdate: APIServers.Master + '/api/oem/leadsource/update',
    LeadSourceMappingStatusUpdate: APIServers.Master + '/api/oem/leadsource/status/update',

    VariantMappingList: APIServers.Master + '/api/oem/modelvariantmapping/list',
    VariantMappingDetail: APIServers.Master + '/api/oem/modelvariantmapping/details',
    VariantMappingAdd: APIServers.Master + '/api/oem/modelvariantmapping/add',
    VariantMappingUpdate: APIServers.Master + '/api/oem/modelvariantmapping/update',
    VariantMappingStatusUpdate: APIServers.Master + '/api/oem/modelvariantmapping/status/update',

    InteriorColorList: APIServers.Master + '/api/interior_color/list',
    InteriorColorDetail: APIServers.Master + '/api/interior_color/details',
    InteriorColorAdd: APIServers.Master + '/api/interior_color/add',
    InteriorColorUpdate: APIServers.Master + '/api/interior_color/update',
    InteriorColorDelete: APIServers.Master + '/api/interior_color/delete',
    InteriorColorStatusUpdate: APIServers.Master + '/api/interior_color/status/update',

    ExteriorColorList: APIServers.Master + '/api/exterior_color/list',
    ExteriorColorDetail: APIServers.Master + '/api/exterior_color/details',
    ExteriorColorAdd: APIServers.Master + '/api/exterior_color/add',
    ExteriorColorUpdate: APIServers.Master + '/api/exterior_color/update',
    ExteriorColorDelete: APIServers.Master + '/api/exterior_color/delete',
    ExteriorColorStatusUpdate: APIServers.Master + '/api/exterior_color/status/update',

    VersionList: APIServers.Master + '/api/version/list',
    VersionDetail: APIServers.Master + '/api/version/details',
    VersionAdd: APIServers.Master + '/api/version/add',
    VersionUpdate: APIServers.Master + '/api/version/update',
    VersionDelete: APIServers.Master + '/api/version/delete',

    RoleWiseSalesConsultantList: APIServers.Master + '/api/role/salesconsultant/list',
    RoleWiseTeamLeaderList: APIServers.Master + '/api/role/teamleader/list',
    RoleWiseSalesManagerList: APIServers.Master + '/api/role/salesmanager/list',

    LostDropList: APIServers.Master + '/api/lostdrop/reason/list',

    TestDriveDashboard: APIServers.TestDrive + '/api/testdrive/dashboard',
    TestDriveList: APIServers.TestDrive + '/api/all/testdrive/list',
    TestDriveDetail: APIServers.TestDrive + '/api/testdrive/details',
    TestDriveSchedule: APIServers.TestDrive + '/api/schedule_test_drive',
    TestDriveStart: APIServers.TestDrive + '/api/start_test_drive',
    TestDriveComplete: APIServers.TestDrive + '/api/complete_test_drive',
    TestDriveProceedToStart: APIServers.TestDrive + '/api/proceed_to_start_test_drive',
    TestDriveReassign: APIServers.TestDrive + '/api/reassign/testdrive',
    TestDriveCancel: APIServers.TestDrive + '/api/cancel_test_drive',
    TestDriveVerify: APIServers.TestDrive + '/api/verify_testdrive_declaration_form',
    TestDriveMobileNumberUpdate: APIServers.TestDrive + '/api/testdrive_mobile_number_update',
    TestDriveUploadDrivingLicence: APIServers.TestDrive + '/api/upload_testdrive_driving_licence',
    TestDriveUploadGatePass: APIServers.TestDrive + '/api/send_testdrive_gatepass',
    TestDriveSentDeclarationForm: APIServers.TestDrive + '/api/send_testdrive_declaration_form',
    TestDriveSentDeclarationEmailOTP: APIServers.TestDrive + '/api/send_testdrive_declaration_email_otp',

    ShowroomTransferList: APIServers.TestDrive + '/api/showroom/transfer/list',
    ShowroomTransferSchedule: APIServers.TestDrive + '/api/showroom/transfer/schedule',
    ShowroomTransferProceedToStart: APIServers.TestDrive + '/api/showroom/transfer/proceed/start',
    ShowroomTransferStart: APIServers.TestDrive + '/api/showroom/transfer/start',
    ShowroomTransferFinish: APIServers.TestDrive + '/api/showroom/transfer/finish',

    FuelRefillList: APIServers.TestDrive + '/api/fuel/refill/list',
    FuelRefillStart: APIServers.TestDrive + '/api/fuel/refill/start',
    FuelBillImageAdd: APIServers.TestDrive + '/api/fuel/refill/addbillimage',
    FuelRefillFinish: APIServers.TestDrive + '/api/fuel/refill/finish',
    FuelRefillAmountFinish: APIServers.TestDrive + '/api/fuel/refill/amount/finish',

    OtherTripList: APIServers.TestDrive + '/api/other/trip/list',
    OtherTripStart: APIServers.TestDrive + '/api/other/trip/start',
    OtherTripFinish: APIServers.TestDrive + '/api/other/trip/finish',

    ReportDemoCarMovement: APIServers.TestDrive + '/api/democar/movement/list',

    DemoCarList: APIServers.TestDrive + '/api/democar/list',
    DemoCarDetail: APIServers.TestDrive + '/api/democar/details',
    DemoCarAdd: APIServers.TestDrive + '/api/democar/add',
    DemoCarUpdate: APIServers.TestDrive + '/api/democar/update',
    DemoCarDelete: APIServers.TestDrive + '/api/democar/delete',
    DemoCarStatusUpdate: APIServers.TestDrive + '/api/democar/status/update',

    LeadListV3: APIServers.Leads + '/api/lead_list/v3',

    deliverySchedule: APIServers.Leads + '/api/update_actual_delivery_date_v2',

    LeadList: APIServers.Leads + '/api/lead_list/v2',
    LeadDetail: APIServers.Leads + '/api/lead_details',
    LeadUpdate: APIServers.Leads + '/api/update_lead',

    LeadDetailV2: APIServers.Leads + '/api/lead_details_v2',
    LeadFollowupTasksV2: APIServers.Leads + '/api/lead_followup_task_details_v2',
    LeadIntVehicleV2: APIServers.Leads + '/api/lead_Intersted_vehicle_details_v2',

    LeadTitleList: APIServers.Leads + '/api/lead/title/list',
    LeadTitleDetail: APIServers.Leads + '/api/lead/title/details',
    LeadTitleAdd: APIServers.Leads + '/api/lead/title/add',
    LeadTitleUpdate: APIServers.Leads + '/api/lead/title/update',
    LeadTitleDelete: APIServers.Leads + '/api/lead/title/delete',

    LeadInterestedVehicleDetail: APIServers.Leads + '/api/interested_vehicle_details',
    LeadInterestedVehicleAdd: APIServers.Leads + '/api/add_interested_vehicle',
    LeadInterestedVehicleUpdate: APIServers.Leads + '/api/update_interested_vehicle',
    LeadInterestedVehicleDelete: APIServers.Leads + '/api/delete_interested_vehicle',

    LeadBookACarList: APIServers.Leads + '/api/lead_book_a_car_list',
    LeadBookACarDetail: APIServers.Leads + '/api/lead_book_a_car_details',
    LeadBookACarAdd: APIServers.Leads + '/api/book_a_car',
    LeadBookACarCancel: APIServers.Leads + '/api/cancel_booking',

    LeadAssignToList: APIServers.Leads + '/api/lead_assign_to_list',

    LeadFilters: APIServers.Leads + '/api/lead_list_filter',

    LeadUpdateSaleFollowUp: APIServers.Leads + '/api/updating_sale_lead_followup',

    LeadUpdateSaleTestdrive: APIServers.Leads + '/api/updating_sale_lead_testdrive',
    LeadRescheduleSaleTestdrive: APIServers.Leads + '/api/reschedule_sale_lead_testdrive',

    LeadUpdateSaleVisit: APIServers.Leads + '/api/updating_sale_lead_visit',

    LeadPlanNextTask: APIServers.Leads + '/api/plan_next_task_v2',

    UnallocatedLeadList: APIServers.Leads + '/api/lead/unallocatedlead_list',

    CreateLead: APIServers.Leads + '/api/create_lead',
    UpdateLead: APIServers.Leads + '/api/update_lead',

    LostDrop: APIServers.Leads + '/api/lost_drop_lead',

    InvoiceDetailsAdd: APIServers.Leads + '/api/final_invoicing_details_v2',

    InvoiceDetails: APIServers.Leads + '/api/lead_final_invoice_car_details',

    UpdateDeliveryDate: APIServers.Leads + '/api/update_actual_delivery_date',


    LeadTestDriveLocation: APIServers.Master + '/api/testdrivelocation/list',

    UserDashboardToday: APIServers.Leads + '/api/today_task_v2',
    UserReopenlead: APIServers.Leads + '/api/lead_reopen',
    // UserDashboardToday: APIServers.Leads + '/api/today_task',
    UserDashboardETVBRL: APIServers.Leads + '/api/etvbrl_v2',
    // UserDashboardETVBRL: APIServers.Leads + '/api/etvbrl',
    UserTargetList: APIServers.Leads + '/api/target_list',
    UserTargetAdd: APIServers.Leads + '/api/target_add',
    UserTargetUpdate: APIServers.Leads + '/api/target_update',
    UserTargetFreeze: APIServers.Leads + '/api/targetfreeze',
    UserETTeamList: APIServers.Leads + '/api/team_list',
    UserETToTeamList: APIServers.Leads + '/api/to_team_list',
    UserETLeadTransfer: APIServers.Leads + '/api/lead_transfer_v2',
    //DashboardLostDrop: APIServers.Leads + '/api/today_task',

    UserInventory: APIServers.Inventory + '/api/inventory/list',
    UserAvailableInventory: APIServers.Inventory + '/api/inventory/list/v2',
    UserInventoryAdd: APIServers.Inventory + '/api/inventory/add',
    UserInventoryUpdate: APIServers.Inventory + '/api/inventory/update',
    UserInventoryDetail: APIServers.Inventory + '/api/inventory/details',
    UserInventoryPriceDetail: APIServers.Inventory + '/api/inventory/pricedetails',
    UserInventoryPriceList: APIServers.Inventory + '/api/inventory/pricelist',
    UserInventoryPriceAdd: APIServers.Inventory + '/api/inventory/priceadd',
    UserInventoryPriceUpdate: APIServers.Inventory + '/api/inventory/priceupdate',
    UserInventoryOperationalCostUpdate: APIServers.Inventory + '/api/inventory/updateoperationalcostdata',
    UserInventoryOperationalCostAdd: APIServers.Inventory + '/api/inventory/addoperationalcostdata',
    UserInventoryImports: APIServers.Inventory + '/api/inventory/imports',
    UserInventoryAllotmentList: APIServers.Inventory + '/api/inventory/inventory_approve_allotement_list',
    UserInventoryApprovalAllotment: APIServers.Inventory + '/api/inventory/update/approveallotment',
    UserPriceApproval: APIServers.Inventory + '/api/inventory/allotmentprice',
    UserPriceQuotes: APIServers.Inventory + '/api/inventory/send_price_quotes',

    UserInventoryDetailsV2: APIServers.Inventory + '/api/inventory/detailsv2',

    UserInventoryVinLog: APIServers.Inventory + '/api/inventory/getinventoryvinlog',

    UserInventoryVehicleStatus: APIServers.Master + '/api/inventory/vehiclestatus/list',
    UserInventoryBankPartner: APIServers.Master + '/api/inventory/bankpartner/list',
    UserInventoryBankInterest: APIServers.Master + '/api/inventory/bankinterest/list',
    UserInventoryFundType: APIServers.Master + '/api/inventory/fundtype/list',

    UserReport: APIServers.Report + '/api/get_report_delivery',
    UserHelpSupport: APIServers.Report + '/api/help_support/list',
    UserHelpSupportStatusUpdate: APIServers.Report + '/api/help_support/status/update',
    UserHelpSupportDetail: APIServers.Report + '/api/help_support/details',
    UserHelpSupportAdd: APIServers.Report + '/api/help_support/add',
    UserGenrateReport: APIServers.Report + '/api/allreports'
}

export const StatusValues = {
    ACTIVE: 1,
    INACTIVE: 0,
    DELETED: 3
}
export const InventoryStatusValues = {
    ALLOCATED: "Allocated",
    UNALLOCATED: "Unallocated",
    UNTRANSIT: "InTransit",
    ORDERED: "Ordered",
    INSTOCK: "InStock",
    INSTOCKUA: "InStock (UA)",
    SOLD: "Sold"
}

export const InventorySortValues = {
    AGE: "age",
    MODEL: "model",
    YEAR: "year",
    WHSCODE: "WHScode",
}

export const TestDriveStatusValues = {
    SCHEDULED: "1",
    ON_GOING: "2",
    COMPLETED: "3",
    CANCELLED: "4"
}

export const uploadImageType = {
    DEMO_VEHICLE: 6,
    LEAD_UPLOAD: 10
}


export const API = {
    //BaseUrl: "https://app.jubilantmotorworks.in",   //Old
    //BaseUrl: "https://api-autorevog.jubilantmotorworks.in",   //Dev
    BaseUrl: "https://api.autorevog.com",
    EndPoint: Endpoints,
    Result: APIResult
}

export const EndUser = {
    SuperAdmin: 0,
    Dealer: 1,
    Company: 2,
    User: 3,
}

export const DateFilterType = {
    RangePicker: 1,
    MonthPicker: 2
}

export const ProjectConfig = {
    EntriesPerPage: 10,
    tableCellTruncateLength: 100
}

export const LeadTaskTypeNames = {
    FollowupCallScheduled: "Followup Call Scheduled",
    VisitScheduled: "Visit Scheduled",
    TestDriveScheduled: "Test Drive Scheduled",
    TestDriveRescheduled: "Test Drive Re-Scheduled",
    Deliveryscheduled: "Delivery Scheduled"
}

export const LeadLocationNames = {
    Showroom: "Showroom",
    Home: "Home",
}
import React from "react";

const CellInventoryAgling: React.FC<{ data: any, data_key: string }> = ({ data, data_key }) => {
    const currentDate = new Date();
    const purchaseDate = new Date(data[data_key]);
    const timeDiff = currentDate.getTime() - purchaseDate.getTime();
    const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    return (
        <span>
            {dayDiff >= 0 ? dayDiff : 0}
        </span>
    );
};

export default CellInventoryAgling;

import { Drawer } from "@mui/material";
import { useEffect, useRef, useState, } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { ProjectStrings } from "../common/Constants";


const PriceDetailsViewEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean, interestedUUID: string, stateID: string, resetInterestedState?: () => void, }> = ({ showDialog, closeDialog, reloadData, id, detailView, interestedUUID, stateID, resetInterestedState }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.PriceDetails>>(null);
    const [initialValue, setInitialValue] = useState<APIData.PriceDetails>(InitialData.PriceDetails);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [formReRenderKey, setFormReRenderKey] = useState(Math.random());

    useEffect(() => {
        if (id !== undefined && id !== '' && interestedUUID !== undefined && interestedUUID !== "" && stateID !== undefined && stateID !== '') {
            addProcessingRequests();
            ServerAPI.getLeadInterestedVehicle(interestedUUID, id).then((response: any) => {
                if (response && response.data.details) {
                    const interestedVehicle = response.data.details;
                    addProcessingRequests();
                    ServerAPI.getInventoryDetailsV2(interestedVehicle.lead_model_uuid, interestedVehicle.lead_variant_uuid, interestedVehicle.lead_exterior_uuid, interestedVehicle.lead_interior_uuid, interestedVehicle.lead_fuel_uuid, stateID, interestedVehicle.model_year).then(response => {
                        if (response && response.data.details) {
                            const inventoryPrice = response.data.details;
                            formikRef.current?.setFieldValue("ex_showroom_price", inventoryPrice.ex_showroom_price);
                            formikRef.current?.setFieldValue("insurance", inventoryPrice.insurance);
                            formikRef.current?.setFieldValue("tcs", inventoryPrice.tcs);
                            formikRef.current?.setFieldValue("registration_charges", inventoryPrice.registration_charges);
                            formikRef.current?.setFieldValue("logistic_handing", inventoryPrice.logistic_handing);
                            formikRef.current?.setFieldValue("vas_charge", inventoryPrice.vas_charge);
                            formikRef.current?.setFieldValue("extend_warrenty", inventoryPrice.extend_warrenty);
                            formikRef.current?.setFieldValue("service_plan", inventoryPrice.service_plan);
                            formikRef.current?.setFieldValue("others", inventoryPrice.others);
                            formikRef.current?.setFieldValue("exchange_loyalty_bonus", inventoryPrice.exchange_loyalty_bonus);
                            formikRef.current?.setFieldValue("corporate_discount", inventoryPrice.corporate_discount);
                            formikRef.current?.setFieldValue("delear_discount", inventoryPrice.delear_discount);
                            formikRef.current?.setFieldValue("referral_bonus", inventoryPrice.referral_bonus);
                            formikRef.current?.setFieldValue("oem_scheme", inventoryPrice.oem_scheme);
                            formikRef.current?.setFieldValue("discount_others", inventoryPrice.discount_others);
                        }
                    }).finally(() => {
                        reduceProcessingRequests();
                    });
                    setFormReRenderKey(Math.random());
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id, interestedUUID]);

    useEffect(() => {
        if (showDialog === false) {
            if (resetInterestedState && interestedUUID !== "") {
                resetInterestedState();
            }
            setInitialValue(InitialData.PriceDetails);
        }

    }, [showDialog]);

    const yupSchema = Yup.object().shape({
        ex_showroom_price: Yup.string().required(ProjectStrings.ValidationRequired),
        tcs: Yup.string().required(ProjectStrings.ValidationRequired),
        insurance: Yup.string().required(ProjectStrings.ValidationRequired),
        registration_charges: Yup.string().required(ProjectStrings.ValidationRequired),
        logistic_handing: Yup.string().required(ProjectStrings.ValidationRequired),
        vas_charge: Yup.string().required(ProjectStrings.ValidationRequired),
        extend_warrenty: Yup.string().required(ProjectStrings.ValidationRequired),
        service_plan: Yup.string().required(ProjectStrings.ValidationRequired),
        others: Yup.string().required(ProjectStrings.ValidationRequired),
        exchange_loyalty_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
        corporate_discount: Yup.string().required(ProjectStrings.ValidationRequired),
        delear_discount: Yup.string().required(ProjectStrings.ValidationRequired),
        oem_scheme: Yup.string().required(ProjectStrings.ValidationRequired),
        discount_others: Yup.string().required(ProjectStrings.ValidationRequired),
        referral_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
    });



    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };

                }} >
                    {(formikProps) => {

                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode && !detailView ? "Edit" : detailView ? "View" : "Add"} Price Details</h4>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section">
                                        <Form key={formReRenderKey}>
                                            <div className="px-2 form-field-container">
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "ex_showroom_price", label: "Ex Showroom Price", placeholder: " Enter Price", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "tcs", label: "Tcs", placeholder: "Enter Tcs", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " add-insurance" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "insurance", required: true, label: "Insurance", placeholder: "Enter Insurance", readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "registration_charges", required: true, label: "Registration Charges", placeholder: "Enter Registration Charges", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "discount_others", label: "Discount Others", placeholder: "Enter Discount Others", readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "logistic_handing", label: "Logistic Handling", placeholder: "Enter Logistic Handling", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "vas_charge", label: "VAS Charges", placeholder: "Enter VAS Charges", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "extend_warrenty", label: "Extend Warranty", placeholder: "Enter Extend Warranty", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "service_plan", label: "Service Plan", placeholder: "Enter Service Plan", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "others", label: "Others", placeholder: "Enter Others", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "exchange_loyalty_bonus", label: "Exchange Loyalty Bonus", placeholder: "Enter Exchange Loyalty Bonus", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "corporate_discount", label: "Corporate Discount", placeholder: "Enter Corporate Discount", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "delear_discount", label: "Dealer Discount", placeholder: "Enter Dealer Discount", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "referral_bonus", label: "Referral Bonus", placeholder: "Enter Referral Bonus", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "oem_scheme", label: "Oem Scheme", placeholder: "Enter Oem Scheme", required: true, readOnly: detailView ? true : false }} />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog} style={detailView ? { textAlign: "center" } : {}}>Cancel</button>
                                                {!detailView && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>{editMode ? "Save Changes" : "Submit"}</button>}
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default PriceDetailsViewEntryDialog;
import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getCompanyCode, getDataFromAPI, isArrayItemsMatches } from "../common/Utilities";

const TeamEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.Team>>(null);
    const [initialValue, setInitialValue] = useState<APIData.Team>(InitialData.Team);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [userOptions, setUserOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [teamLeadOptions, setTeamLeadOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [managerOptions, setManagerOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedShowroomUUID, setSelectedShowroomUUID] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<number>();
    const [selectedManagerUUIDs, setSelectedManagerUUIDs] = useState<string[]>([]);
    const [selectedTeamLeaderUUIDs, setSelectedTeamLeaderUUIDs] = useState<string[]>([]);
    console.log(selectedStatus, " STATUS")
    console.log(formikRef.current?.values.manager_uuid, " STATUS")

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'showroom_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (selectedShowroomUUID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params['showroom_uuid'] = selectedShowroomUUID;
            getDataFromAPI(API.EndPoint.RoleWiseSalesManagerList, setManagerOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name', 'data', 'list');
        } else {
            setManagerOptions([]);
        }
    }, [selectedShowroomUUID]);

    useEffect(() => {
        if (selectedManagerUUIDs.length > 0) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params['showroom_uuid'] = selectedShowroomUUID;
            params['manager_uuid'] = selectedManagerUUIDs.join(',');
            getDataFromAPI(API.EndPoint.RoleWiseTeamLeaderList, setTeamLeadOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name', 'data', 'list');
        } else {
            setTeamLeadOptions([]);
        }
    }, [selectedManagerUUIDs]);

    useEffect(() => {
        if (selectedTeamLeaderUUIDs.length > 0) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params['showroom_uuid'] = selectedShowroomUUID;
            params['teamleader_uuid'] = selectedTeamLeaderUUIDs.join(',');
            getDataFromAPI(API.EndPoint.RoleWiseSalesConsultantList, setUserOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name', 'data', 'list');
        } else {
            setUserOptions([]);
        }
    }, [selectedTeamLeaderUUIDs]);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTeam(id).then(response => {
                if (response && response.data) {
                    const teamData = response.data;

                    const userData = [...teamData.user_details];
                    delete teamData.user_uuid;
                    teamData.user_uuid = userData.map(user => user.user_uuid);

                    const leadData = [...teamData.team_lead_details];
                    delete teamData.team_lead_uuid;
                    teamData.team_lead_uuid = leadData.map(lead => lead.user_uuid);

                    const managerData = [...teamData.sales_manager_details];
                    delete teamData.manager_uuid;
                    teamData.manager_uuid = managerData.map(manager => manager.user_uuid);

                    setInitialValue(teamData);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        team_name: Yup.string().required(ProjectStrings.ValidationRequired),
        showroom_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        // user_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        // team_lead_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
        // manager_uuid: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    //submitValues['showroom_uuid'] = [submitValues['showroom_uuid']];
                    if (selectedStatus) {
                        if (selectedStatus === 1) {
                            if (submitValues["manager_uuid"].length === 0) {
                                toast.error("Manager field is required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedStatus === 1) {
                            if (submitValues["team_lead_uuid"].length === 0) {
                                toast.error("Team lead field is required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedStatus === 1) {
                            if (submitValues["user_uuid"].length === 0) {
                                toast.error("User Field is required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateTeam(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        //submitValues['company_code'] = getCompanyCode();
                        addProcessingRequests();
                        ServerAPI.addTeam(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.showroom_uuid !== selectedShowroomUUID) {
                            setSelectedShowroomUUID(formikProps.values.showroom_uuid);
                        }
                        if (formikProps.values.status !== selectedStatus) {
                            setSelectedStatus(formikProps.values.status);
                        }
                        if (!isArrayItemsMatches(formikProps.values.manager_uuid, selectedManagerUUIDs)) {
                            setSelectedManagerUUIDs(formikProps.values.manager_uuid);
                        }
                        if (!isArrayItemsMatches(formikProps.values.team_lead_uuid, selectedTeamLeaderUUIDs)) {
                            setSelectedTeamLeaderUUIDs(formikProps.values.team_lead_uuid);
                        }
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Team</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Team Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section px-1">
                                        <Form>
                                            <div className="form-field-container">
                                                <div className="row px-2">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mt-3 mb-3 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Showroom</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Showroom", name: "showroom_uuid", placeholder: "Showroom", required: true, options: showroomOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mb-4 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "team_name", label: "Team Name", placeholder: "Team Name", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Manager</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Managers", name: "manager_uuid", placeholder: "Managers", required: false, options: managerOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Team Leaders</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Team Leaders", name: "team_lead_uuid", placeholder: "Team Leaders", required: false, options: teamLeadOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Users</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Sales Consultant", name: "user_uuid", placeholder: "Users", required: false, options: userOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mb-4 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik >
            )
            }
        </Drawer >
    )
}

export default TeamEntryDialog;
import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { InitialData } from "./InitialData";
import { getDataFromAPI } from "../common/Utilities";

const CancelBookingEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, value: any, }> = ({ showDialog, closeDialog, reloadData, value }) => {
    console.log(value, "vlue")
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.CancelBooking>>(null);
    const [initialValue, setInitialValue] = useState<APIData.CancelBooking>(InitialData.CancelBooking);
    const [reasonOptions, setReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);

    const yupSchema = Yup.object().shape({
        cancel_booking_reason_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
    });

    const isValidBooking = (booking: APIData.CancelBooking): boolean => {
        for (const key in booking) {
            if (key !== "cancel_booking_reason_uuid" && booking[key as keyof APIData.CancelBooking] === undefined) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.CancelBookingReason, setReasonOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'cancel_booking_reason_name', "data", "list");
    }, [])

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                let submitValues: any = { ...values };
                const isValid = isValidBooking(value[0]);
                if (!!value[0] && isValid) {
                    submitValues["lead_uuid"] = value[0].lead_uuid;
                    submitValues["lead_booking_car_uuid"] = value[0].lead_booking_car_uuid;
                    submitValues["interested_car_uuid"] = value[0].interested_car_uuid;
                    addProcessingRequests();
                    ServerAPI.cancelBooking(submitValues).then(response => {
                        if (response && response['success'] && response['message']) {
                            toast.success(response['message']);
                            console.log("cancel called")
                            reloadData();
                            closeDialog();
                        } else if (response && response['message']) {
                            toast.error(response['message']);
                        } else {
                            toast.error('Unknown Error Occurred');
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        reduceProcessingRequests();
                    });
                }

            }} >
                {(formikProps) => {
                    return (
                        <div className="tabdetails">
                            <h4 className="title-text text-center">Cancel Booking</h4>
                            <div className="custom-border-grey my-2"></div>
                            <Form>
                                <div className="px-2 form-field-container">
                                    <div className="row px-2 py-3">
                                        <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Reason", name: "cancel_booking_reason_uuid", placeholder: "Select Reason", required: true, options: reasonOptions }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row btn-form-submit">
                                    <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                    <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                        formikProps.submitForm();
                                        if (!formikProps.isValid) {
                                            toast.error("Please ensure all fields are Filled and Valid!");
                                        }
                                    }}>Submit</button>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </Drawer>
    )
}

export default CancelBookingEntryDialog;
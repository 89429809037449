import React, { useEffect, useRef, useState } from 'react';
import { APIData, FormDataTypes } from '../../common/DataTypes';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ServerAPI } from '../../common/ServerAPI';
import { useAppStateAPI } from '../../common/AppStateAPI';
import UserLeadDetailDialog from '../../detailview/UserLeadDetailDialog';
import { getFormattedLocalDateTime } from '../../common/Utilities';
import UserLeadsSearchModal from '../ui_components/UserLeadsSearchModal';
import UserLeadsFilterModal from '../ui_components/UserLeadsFilterModal';
import TuneIcon from '@mui/icons-material/Tune';
import UserLeadFilter from '../ui_components/UserLeadFilter';
import Pagination from '@mui/material/Pagination';


interface AdditionalFilter {
    label: string;
    paramName: string;
    optional: boolean;
    options: FormDataTypes.SelectOption[];
}

const LeadsTab: AdditionalFilter = {
    label: 'Task Type',
    paramName: 'task_type',
    optional: false,
    options: [
        {
            label: "Unqualified",
            value: "Unqualified"
        },
        {
            label: "Qualified",
            value: "Qualified"
        },
        {
            label: "Booked",
            value: "Booked"
        },
        {
            label: "Invoiced",
            value: "Invoiced"
        },
        {
            label: "Delivered",
            value: "Delivered"
        },
        {
            label: "Lost/ Drop",
            value: "Lost/Drop"
        },
    ]
};

const UserLeads: React.FC<{ additionalFilter: string, startDate: string, endDate: string }> = ({ additionalFilter, startDate, endDate }) => {
    console.log(startDate, endDate, "reeloadData")
    const [showLeadPopup, setShowLeadPopup] = useState(false);
    const [showSearchPopup, setShowSearchPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [availableOptions, setAvailableOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadlists, setLeadLists] = useState<APIData.LeadLists | undefined>();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const customTableViewRef = useRef<HTMLDivElement | null>(null);
    const [detailLeadUUID, setDetailLeadUUID] = useState('');
    const [showCreateNewLead, setShowCreateNewLead] = useState(false);
    const [searchParams, setSearchParams] = useState<APIData.SearchLeads | undefined>();
    const [filterParams, setFilterParams] = useState<APIData.FilterLeads | undefined>();
    const [searchApplied, setSearchApplied] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const SearchLeads = (filters: APIData.SearchLeads) => {
        setSearchParams(filters);
        setSearchApplied(true);
        setCurrentPage(1);
    };
    const filterLeads = (filters: APIData.FilterLeads) => {
        setFilterParams(filters);
        setFilterApplied(true);
        setCurrentPage(1);
    };

    const clearSearch = () => {
        setSearchParams(undefined);
        setSearchApplied(false);
        setCurrentPage(1);
    };
    const clearFilter = () => {
        setFilterParams(undefined);
        setFilterApplied(false);
        setCurrentPage(1);
    };

    const showLeadDetail = (leadUUID: string) => {
        setShowLeadPopup(true);
        setDetailLeadUUID(leadUUID);
    }

    const handleCreateNewLead = () => {
        setShowCreateNewLead(true);
    }
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
                setShowLeadPopup(false);
                setShowSearchPopup(false);
                setShowFilterPopup(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setShowLeadPopup(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            setShowLeadPopup(false)
        };
    }, []);

    const fetchLeadlists = () => {
        if (selectedFilter && currentPage) {
            addProcessingRequests()
            ServerAPI.getLeadLists(currentPage, selectedFilter, startDate, endDate, searchParams?.mobileNumber, searchParams?.leadID, searchParams?.customerName, filterParams?.modelUUID, filterParams?.leadSourceCategoryUUID, filterParams?.task)
                .then((response: any) => {
                    setLeadLists({ data: response.data });
                    if (response['totalpage'] !== undefined) {
                        setTotalPages(response['totalpage']);
                    }
                }).finally(() => {
                    reduceProcessingRequests()
                });
        }
    };

    useEffect(() => {
        fetchLeadlists();
    }, [selectedFilter, searchParams, startDate, endDate, filterParams, currentPage]);

    useEffect(() => {
        updateAvailableOptions();
        setCurrentPage(1);
    }, [additionalFilter]);

    const updateAvailableOptions = () => {
        switch (additionalFilter) {
            case 'openLead':
                setAvailableOptions(LeadsTab.options.filter(option => ['Unqualified', 'Qualified', 'Booked'].includes(option.value)));
                setSelectedFilter("Unqualified");
                break;
            case 'closeLead':
                setAvailableOptions(LeadsTab.options.filter(option => !['Unqualified', 'Qualified', 'Booked'].includes(option.value)));
                setSelectedFilter("Invoiced");
                break;
            default:
                setAvailableOptions(LeadsTab.options);
                break;
        }
    };



    const handleFilterSelection = (index: string) => {
        setSelectedFilter(index);
        setCurrentPage(1);
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mt-1'>
                <div className="showroomlist-btncon">
                    {availableOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`showroomlist-btn ${selectedFilter === option.value ? 'active' : ''}`}
                            onClick={() => handleFilterSelection(option.value)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
                {/* <div className='showroomlist-btncon create-new-lead' onClick={handleCreateNewLead}>
                    <button type="button" className="showroomlist-btn px-2 py-2">
                        <div><img src="/assets/images/icons/add.svg" alt="" /></div>
                        <div> Create New Lead </div>
                    </button>
                </div> */}

                <div className='d-flex align-items-center'>
                    <div className=' mr-2' onClick={() => { filterApplied ? clearFilter() : setShowFilterPopup(true) }} >
                        {/* style={{ position: "absolute", right: "15rem", top: "22px", cursor: "pointer" }} */}
                        {filterApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                            <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Clear Filter
                        </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                            {/* <i className="fas fa-filter fs-15" aria-hidden="true" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Filter */}
                            <TuneIcon style={{ fontSize: '14px', fontWeight: "500" }} />&nbsp;Filter
                        </button>}
                    </div >
                    <div onClick={() => { searchApplied ? clearSearch() : setShowSearchPopup(true) }} >
                        {/* style={{ position: "absolute", right: "15rem", top: "22px", cursor: "pointer" }} */}
                        {searchApplied ? <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                            <i className="fa fa-times" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Clear Search Filter
                        </button> : <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "35px", fontSize: "14px" }}>
                            <i className="fas fa-search fs-15" aria-hidden="true" style={{ color: "white", marginRight: "5px", fontSize: "13px" }}></i>Search
                        </button>}
                    </div >
                    <div className="addnew mr-1 mt-2">
                        <button type="button" className="btn btn-sm btn-add-custom mb-3" onClick={handleCreateNewLead}>
                            <i className="fa-solid fa-plus mr-2"></i>Create New Lead
                        </button>
                    </div>
                </div>
            </div>

            {
                leadlists !== undefined && leadlists.data !== undefined && leadlists.data.list.length > 0 ? (
                    <>
                        <div className='user-lead-details-container'>
                            <div className='custom-tablescroll-2' style={{ paddingLeft: "5px" }}>
                                <div className='table-container'>
                                    <div className='table-scroll pb-2'>
                                        {leadlists.data.list.map((lead, leadIdx) => (
                                            <div className={`table-responsive ${lead.current_lead_status === "Warm" ? "warm" : lead.current_lead_status === "Cold" ? "cold" : lead.current_lead_status === "Hot" ? "hot" : ""}`} key={leadIdx}>
                                                <div className='d-flex justify-content-between p-2'>
                                                    <p className='mb-0 user-leadname'><strong>{lead.lead_cust_first_name} {lead.lead_cust_last_name}</strong> <span>(Lead ID : {lead.lead_id})</span></p>
                                                    <div className='d-flex align-items-center'>
                                                        {/* <p className='mb-0 user-lead-date'>28/08 <span>(5.00pm)</span></p> */}
                                                        <p className='mb-0 user-lead-date' >{getFormattedLocalDateTime(lead.lead_create)}</p>
                                                        <MoreVertIcon style={{ cursor: "pointer" }} onClick={() => showLeadDetail(lead.lead_uuid)} />
                                                    </div>
                                                </div>
                                                <div className='row user-leads-second'>
                                                    <div className='col-8 user-lead-details'>
                                                        <div className='leads-table-content'>
                                                            <p className='mb-0 userlead-numkey'>Mobile Number</p>
                                                            <p className='mb-0 userlead-numval'>{lead.lead_cust_mobile_number}</p>
                                                        </div>
                                                        <div className='leads-table-content'>
                                                            <p className='mb-0 userlead-numkey'>Lead Source</p>
                                                            <p className='mb-0 userlead-numval'>{lead.lead_source_name}</p>
                                                        </div>
                                                        <div className='leads-table-content'>
                                                            <p className='mb-0 userlead-numkey'>Model</p>
                                                            <p className='mb-0 userlead-numval'>{lead.model_name}</p>
                                                        </div>
                                                        <div className='leads-table-content'>
                                                            <p className='mb-0 userlead-numkey'>City</p>
                                                            <p className='mb-0 userlead-numval'>{lead.city_name}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-4 d-flex align-items-center justify-content-end'>
                                                        <div className='d-flex align-items-center justify-content-center w-10'><p className='user-lead-call'>{lead.current_status}</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ float: "right", marginBottom: '10px', height: "40px" }}>
                                    <Pagination count={totalPages} onChange={handlePagination} page={currentPage} variant="outlined" color="primary" shape="rounded" />
                                </div>
                            </div>
                        </div>
                    </>) : <div className="table-responsive">
                    <table className="table table-1 custom-border">
                        <tbody>
                            <tr>
                                <td className="text-align-l">No Data Available</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            }

            <UserLeadDetailDialog showDialog={showLeadPopup} closeDialog={() => setShowLeadPopup(false)} reloadData={() => { fetchLeadlists() }} id={detailLeadUUID} seletedAdditionalFilter={additionalFilter} />
            <UserLeadFilter showModal={showCreateNewLead} closeModal={() => setShowCreateNewLead(false)} />
            {showSearchPopup && <div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview' style={{ padding: "10px 15px 0rem 25px" }}>
                <div className='custom-tableview-scroll'> <UserLeadsSearchModal showModal={showSearchPopup} closeModal={() => setShowSearchPopup(false)} filterLeads={SearchLeads} /></div></div></div>}
            {showFilterPopup && <div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview' style={{ padding: "10px 15px 0rem 25px" }}>
                <div className='custom-tableview-scroll'><UserLeadsFilterModal showModal={showFilterPopup} closeModal={() => setShowFilterPopup(false)} filterLeads={filterLeads} /></div></div></div>}

        </>
    );
};

export default UserLeads;